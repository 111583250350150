@import '../../../animate';
@import '../../../vars';

.card-related-content {
  @include card();

  .description {
  }

  .image-animated {
    @media screen and (max-width: 768px) {
      width: 90px;
      display: inline-block;
    }
  }
}
