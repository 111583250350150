@import '../../../animate';
@import '../../../vars';

.object-hierarchy {
  ul li {
    margin-bottom: 0 !important;
    list-style-type: none !important;
  }

  .object-level1 {
    margin-bottom: 0;
  }

  .object-level2 {
    margin-left: 20px;
  }

  .level-lists > li {
    margin-left: -1px;
  }

  .level-lists > li.last {
    border-left: none;
    margin-left: 0px;
  }

  .object-level1 li {
    padding: 3px 0 !important;
  }

  .object-level2 li:before {
    content: ' ';
    background: url('./arrow7.png') 0 0 no-repeat;
    top: -3px;
    position: relative;
    font-size: 10px;
    padding: 0 10px 0 0 !important;
    vertical-align: middle;
    float: left;
    width: 20px;
    height: 20px;
    left: -1px;
  }

  .level-lists > li:before {
    left: -2px;
  }

  .object-level1 .tagged:hover,
  .object-level1 .tagged:focus,
  .object-level2 .tagged:hover,
  .object-level2 .tagged:focus {
    color: $link-color-hover;
  }

  .object-level2 li a,
  .object-level1 li a {
    vertical-align: middle;
    overflow: hidden;
    display: block;
  }

  .object-level1 > li > a,
  .object-level2 > li > a {
    color: $paragraph-body-color;
  }

  .object-level1 > li > a:hover,
  .object-level2 > li > a:hover {
    color: $link-color;
  }

  .object-level1 > li > a:last-child,
  .object-level2 > li > a:last-child {
    color: $link-color;
    &:hover {
      color: $link-color-hover;
    }
  }
}
