@mixin padding() {
  padding: 19px;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 19px;
  }
  @media only screen and (max-width: 767px) {
    padding: 16px;
  }
}

//card group-listing
@mixin card() {
  .white-card {
    margin-bottom: $margin * 1.5;
    padding: 16px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  .description {
    max-height: 85px;
    overflow: hidden;
    width: 100%;

    @media only screen and (max-width: 767px) {
      max-height: 80px;
    }
  }

  .tag {
    font-size: 15px;
    line-height: 19px;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    padding: 0 0 10px;
    background: #fff;
    margin-bottom: 10px;
    border-bottom: 1px solid $hr-border;
    .img-animated-link {
      width: 90px;
      height: 90px;
      min-height: 90px;
      padding-bottom: 0;
      float: left;
      display: block;
      border-radius: 0;
    }
    .white-card {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      width: calc(100% - 90px);
      padding: 0 0 0 10px !important;
      float: right;
      min-height: 0;
    }
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: ' ';
      clear: both;
      height: 0;
    }
    .tag {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@mixin button-padding() {
  padding: $button-padding-desktop;
  @include set-text($button-size);
  margin-bottom: 0;
  @media only screen and (max-width: 767px) {
    padding: $button-padding-mobile;
    @include set-text($button-size-mobile);
    margin-bottom: 0;
  }
}

@mixin set-heading-text-sizes($large, $medium, $small) {
  font-size: $large;
  margin-bottom: 12px;
  @media only screen and (min-width: $medium-font-breakpoint-min) and (max-width: $medium-font-breakpoint-max) {
    font-size: $medium;
    margin-bottom: 12px;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    font-size: $small;
    margin-bottom: 12px;
  }
}

@mixin icomoon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin set-heading-line-height($large, $medium, $small) {
  line-height: $large;
  @media only screen and (min-width: $medium-font-breakpoint-min) and (max-width: $medium-font-breakpoint-max) {
    line-height: $medium;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    line-height: $small;
  }
}

@mixin set-text($font-size) {
  font-size: $font-size;
  margin-bottom: 12px;
  small {
    font-family: $paragraph-font;
    color: $paragraph-subtitle-color;
  }
}

@mixin transform($transforms) {
  transform: $transforms;
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin animate($speed) {
  transition: all $speed + s ease;
}

@mixin animateDelay($speed, $delay) {
  transition: all $speed + s $delay + s linear;
}
