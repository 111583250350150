@import '../../../animate';
@import '../../../vars';

.search-pagination {
  position: relative;
  margin: $margin * 1.5 auto;
  @media (min-width: 768px) {
    font-size: 17px;
  }
  @media (max-width: 767px) {
    margin: $margin * 0.8 auto;
  }

  ul > li {
    display: inline-block;
    padding: 0 12px;
    font-size: 17px;
    line-height: 22px;
  }

  .pager {
    position: relative;
    width: 800px;
    text-align: center;
    display: inline-block;
    clear: both;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;

    li.pager-item a {
      display: inline-block;
      line-height: 40px;
      border-radius: 50%;
      height: 42px;
      width: 42px;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      &:hover {
        color: #fff;
        background-color: $link-color-hover;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .pager-previous,
  .pager-next {
    display: inline-block;
    text-transform: uppercase;
    background: $link-color;
    transition: all 0.3s ease-out;
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    @include button-padding();

    a {
      color: #fff;
    }

    &:hover,
    &:focus {
      background: $link-color-hover;
      color: #fff;
    }
    &:active {
      background: darken($link-color, 1%);
    }

    .icon-RCT-arrow {
      position: relative;
      font-size: 12px;
      margin: 0 0 0 10px;
    }

    .icon-RCT-arrow-left {
      position: relative;
      font-size: 12px;
      margin: 0 10px 0 0;
    }
  }

  .pager-current {
    display: inline-block;
    line-height: 40px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #8a2a2b;
    border-radius: 50px;
    height: 42px;
    width: 42px;

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  .pager-item,
  .pager-current,
  .pager-ellipsis {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .item-list {
    text-align: center;
  }

  .pager-previous {
    float: left;
  }
  .pager-next {
    float: right;
  }

  .pager-item,
  .pager-current {
    margin-top: 5px;
  }

  .pager-ellipsis {
    color: $link-color;
  }

  &__jump-to-page {
    position: relative;
    perspective: 1000;
    perspective-origin: center bottom;
    @media screen and (max-width: 989px) {
      display: none !important;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__jump-to-page-text {
    text-transform: uppercase;
    color: $link-color;
    padding: 15px 5px;
  }

  &__jump-to-page-container {
    position: absolute;
    top: -95px;
    left: 0;
    width: 280px;
    height: 78px;
    background: #f8f8f8;
    display: table;
    padding: 12px;
    opacity: 0;
    transform: rotate3d(1, 0, 0, 88deg);
    transform-origin: bottom;
    transition: 200ms linear all;
    form {
      display: table;
    }
  }

  &__jump-to-page-container:after {
    border-color: #f8f8f8 transparent transparent;
    border-style: solid;
    border-width: 15px 12px 0;
    bottom: -15px;
    content: '';
    height: 0;
    left: 30px;
    position: absolute;
    width: 0;
  }

  &__jump-to-page:hover > &__jump-to-page-container {
    opacity: 1;
    transform: rotate3d(1, 0, 0, 0deg);
  }

  &__button-container {
    display: table-cell;
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }
  }

  input {
    display: table-cell;
    font-size: 17px;
    outline: none;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
