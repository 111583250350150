@import '../../../animate';
@import '../../../vars';

.collection-object-info-area-people {
  &__tooltip {
    position: relative;
    display: inline;
  }

  &__tooltip &__tooltip-text {
    visibility: hidden;
    min-width: 170px;
    background-color: $link-color;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &__tooltip &__tooltip-text a {
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 100;
  }

  .icon-RCT-search:before,
  .icon-RCT-user:before {
    margin-right: 10px;
    color: #fff;
  }

  &__tooltip &__tooltip-text:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 15px;
    border-width: 5px;
    border-style: solid;
    border-color: $link-color transparent transparent transparent;
  }

  &__tooltip:hover &__tooltip-text {
    visibility: visible;
    opacity: 1;
  }
}
