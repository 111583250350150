@import '../../../animate';
@import '../../../vars';

.footer {
  padding: 30px 0;
  background: #fff;
  box-shadow: 0px 0px 3px #ddd;
  h5 {
    font-family: $titles-font;
    margin-bottom: 16px;
  }
  .gray-box {
    background: #f8f8f8;
    border: 1px solid #e9e9e9;
    padding: $margin * 2;
    width: 100%;
    border-radius: 3px;
    ul {
      margin-top: $margin * 2;
      li {
        margin-right: $margin/2;
        &:last-child {
          margin-left: $margin;
        }
      }
    }
  }

  form {
    position: relative;
    label {
      display: none;
    }
    input {
      width: calc(100% - 128px);
    }
    button {
      position: absolute;
      right: 0;
      top: 0;
      width: 140px;
      margin-bottom: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 2;
      font-family: $paragraph-font;
    }
  }
  hr {
    margin-top: $margin * 1.5;
    margin-bottom: $margin;
  }
  ul {
    margin: $margin 0 0 0;
    a {
      font-size: 17px;
      color: $paragraph-body-color;
      @media only screen and (max-width: 767px) {
        @include set-text(16px);
        margin-bottom: 0;
      }
      &:hover {
        color: $link-color;
      }
      span {
        position: relative;
        top: -10px;
      }
    }
  }
  .container > p.subtitle {
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    @media only screen and (max-width: 767px) {
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }
  }
  @media only screen and (min-width: $menu-break) {
    .mobile-accordion {
      h5 {
        margin-top: 40px;
      }
    }
  }

  &.is-microsite {
    background-color: $brand-red;
    color: $white;

    hr {
      border-top: 1px solid $white;
      opacity: 0.2;
      margin-bottom: 16px;

      &.first {
        @media screen and (min-width: $menu-break) {
          display: none;
        }
      }

      @media screen and (min-width: 710px) {
        margin-top: 0;
      }
    }

    h5 {
      font-family: $titles-font;
      font-size: 20px;
      line-height: 1.35;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.2px;
      color: $white;
      margin-bottom: 0;
    }

    .social-media-wrapper {
      .social-media {
        margin-top: 16px;
        margin-bottom: 16px;

        li {
          margin-right: 13px;

          a {
            color: $white;
          }
        }

        .last {
          display: block;
          margin-top: 13px;

          a {
            span {
              font-family: $footer-label-font;
              font-size: 16px;
              line-height: 1.31;

              @media screen and (min-width: 710px) {
                font-size: 17px;
              }
            }
          }

          @media screen and (min-width: 710px) {
            display: inline-block;
            margin-top: auto;
            margin-left: 15px;
          }
        }

        @media only screen and (min-width: $menu-break) {
          margin-top: 20px;
        }
      }
    }

    .policy-links-wrapper {
      margin-bottom: 10px;

      .policy-links {
        margin: 8px 0 0 0;

        &__item {
          a {
            font-family: $footer-label-font;
            font-size: 16px;
            line-height: 2.13;
            letter-spacing: 0.1px;
            text-transform: capitalize;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            color: $white;

            &:hover {
              border-bottom: 1px dotted $white;
            }
          }
        }
      }

      @media screen and (min-width: 445px) {
        .policy-links {
          &__item {
            display: inline-block;
          }
        }
      }

      @media screen and (min-width: 710px) {
        .policy-links {
          &__item {
            a {
              font-size: 16px;
            }
          }
        }
      }

      @media screen and (min-width: 445px) {
        .policy-links {
          &__item {
            margin-right: 20px;
          }
        }
      }

      @media screen and (min-width: $menu-break) {
        .policy-links {
          &__item {
            a {
              font-size: 17px;
              line-height: 1.79;
            }
          }
        }
      }
    }

    @media screen and (min-width: 710px) and (max-width: 1024px) {
      .row {
        .social-media-wrapper .social-media .last {
          display: inline;
          margin-left: 15px;

          a span {
            font-size: 16px;
          }
        }
      }
    }

    .row.no-sponsor-footer-logos {
      margin-bottom: 16px;

      @media screen and (min-width: $menu-break) {
        padding-bottom: 25px;
      }
    }

    .partnership-logo-wrapper.first {
      @media screen and (min-width: $menu-break) {
        padding-top: 25px;
      }

      @media screen and (min-width: 1200px) {
        padding-top: 15px;
      }
    }

    .in-partnership-with-wrapper {
      span {
        text-align: center;
        font-family: $footer-label-font;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.92;
        letter-spacing: 0.1px;
        position: relative;
        top: 40%;
      }

      @media screen and (min-width: 710px) {
        span {
          font-size: 15px;
          letter-spacing: 0.2px;
        }
      }

      @media screen and (min-width: 600px) and (max-width: 767px) {
        span {
          top: 44% !important;
        }
      }
    }

    .partnership-logo-wrapper.second {
      margin: 14px 0;
      @media screen and (min-width: 768px) {
        margin-top: 0;
      }
      ul.partnership-logo {
        margin: 0 -8px;

        li {
          vertical-align: middle;
          margin-right: initial;
        }
      }

      ul.partnership-logo {
        display: flex;
        align-items: center;
      }
    }

    .partnership-logo-four {
      display: none;
    }

    /*  ipadpro Landscape */
    @media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
      .partnership-logo-wrapper.first {
        .row__inner {
          .in-partnership-with-wrapper {
            span {
              top: 34% !important;
            }
          }
        }
      }
    }

    @media screen and (min-width: $menu-break) {
      .policy-links-wrapper .policy-links {
        margin: 20px 0 0 0;
      }

      .partnership-logo-four {
        display: inline-block;
      }

      .partnership-logo-wrapper.first {
        .row__inner {
          .in-partnership-with-wrapper {
            span {
              top: 29%;
            }
          }
        }
      }
    }

    @media screen and (min-width: 1160px) {
      .partnership-logo-wrapper.first {
        .row__inner {
          .in-partnership-with-wrapper {
            span {
              top: 34%;
            }
          }
        }
      }
    }

    .in-partnership-with-wrapper {
      text-align: center;
    }

    .partnership-logo {
      &.additional-row {
        @media screen and (max-width: 767px) {
          flex-wrap: wrap;

          li {
            flex: 1 0 50%;

            &:nth-child(3),
            &:nth-child(4) {
              margin-top: 20px;
            }
          }
        }
      }

      li {
        text-align: center;

        img {
          max-height: 68px;

          @media screen and (min-width: 568px) and (max-width: 767px) {
            max-height: 93px;
          }

          @media screen and (min-width: 768px) {
            max-height: 68px;
          }

          @media screen and (min-width: $menu-break) {
            max-height: 93px;
          }
        }
      }
    }

    @media screen and (min-width: $menu-break) {
      .in-partnership-with-wrapper {
        text-align: right;
      }
    }

    @media screen and (min-width: 568px) and (max-width: 1024px) {
      .social-media-wrapper .social-media .last {
        display: inline;
      }
    }

    @media screen and (min-width: 710px) and (max-width: 991px) {
      .social-media-wrapper .social-media {
        margin-top: 8px;

        .last {
          display: inline;
        }
      }
    }

    @media screen and (min-width: 845px) and (max-width: 889px) {
      .partnership-logo-wrapper.second,
      .in-partnership-with-wrapper {
        display: inline-block;
      }
    }

    @media screen and (min-width: 1100px) {
      .in-partnership-with-wrapper {
        span {
          top: 14px;
        }
      }
    }

    @media screen and (min-width: 1200px) {
      .in-partnership-with-wrapper {
        span {
          top: 18px;
        }
      }
    }

    .container > p.subtitle {
      color: $white;
      font-family: $footer-label-font;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 15px;
      letter-spacing: 0;

      @media screen and (min-width: 710px) {
        margin-top: 20px;
      }

      @media only screen and (min-width: $menu-break) {
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.1px;
      }
    }

    @media only screen and (min-width: $menu-break) {
      padding: 30px 0;

      h5 {
        font-size: 24px;
        line-height: 1.13;
      }
    }
  }
  .sign-up-form {
    ul.list-inline {
      li {
        a {
          img {
            height: 30px;
          }
        }
        &:last-child {
          a {
            img {
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: $menu-break) {
  .footer {
    padding: 30px 0 !important;
  }
}

@media only screen and (max-width: $menu-break) {
  .footer {
    padding: 16px 0;
    .gray-box {
      padding: 16px;
      margin-bottom: 16px;
      ul {
        margin-top: 0;
      }
      ul li:last-child {
        margin-top: 16px;
        margin-left: 0;
      }
    }
    form {
      input {
        width: 100%;
        margin-bottom: 0;
      }
      button {
        position: relative;
        width: 100%;
        margin-top: 4px;
        border-radius: 3px;
        margin-bottom: 16px;
      }
    }
    hr {
      margin-top: 0;
      margin-bottom: $margin;
    }
    .mobile-accordion {
      position: relative;
      border-top: 1px solid $hr-border;
      padding-top: 16px;
      cursor: pointer;
      h5 {
        color: $link-color;
      }
      i {
        position: absolute;
        right: 8px;
        top: 21px;
        font-size: 12px;
        @include animate(0.3);
        color: $link-color;
      }
    }
  }
  .mobile-accordion-open {
    i {
      @include transform(rotate(180deg));
    }
  }
  .mobile-accordion-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    ul {
      margin-top: 0;
    }
  }
  .mobile-accordion-open ~ .mobile-accordion-content {
    max-height: 3000px;
    height: auto;
    transition: max-height 0.4s ease-in;
  }
}
