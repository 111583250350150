@import 'vars';
@import 'animate';

body {
  @include set-text($paragraph-size);
  font-family: $paragraph-font;
  font-size: $paragraph-size;
  color: $paragraph-body-color;
  margin-bottom: 0;
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  @media only screen and (max-width: $mobile-breakpoint) {
    @include set-text($paragraph-size-mobile);
    line-height: 21px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $titles-font;
  color: $titles-color;
  font-weight: lighter;
  strong {
    font-family: $titles-font;
  }
}

h1 {
  text-transform: uppercase;
  @include set-heading-text-sizes($h1-size, $h1-size-tablet, $h1-size-mobile);
  @include set-heading-line-height($h1-line-height, $h1-line-height-tablet, $h1-line-height-mobile);
}

h2 {
  @include set-heading-text-sizes($h2-size, $h2-size-tablet, $h2-size-mobile);
  @include set-heading-line-height($h2-line-height, $h2-line-height-tablet, $h2-line-height-mobile);
}

h3 {
  @include set-heading-text-sizes($h3-size, $h3-size-tablet, $h3-size-mobile);
  @include set-heading-line-height($h3-line-height, $h3-line-height-tablet, $h3-line-height-mobile);
  text-align: center;
}

h4 {
  @include set-heading-text-sizes($h4-size, $h4-size-tablet, $h4-size-mobile);
  @include set-heading-line-height($h4-line-height, $h4-line-height-tablet, $h4-line-height-mobile);
}

h5 {
  @include set-heading-text-sizes($h5-size, $h5-size-tablet, $h5-size-mobile);
  @include set-heading-line-height($h5-line-height, $h5-line-height-tablet, $h5-line-height-mobile);
}

h6 {
  @include set-heading-text-sizes($h6-size, $h6-size-tablet, $h6-size-mobile);
  @include set-heading-line-height($h6-line-height, $h6-line-height-tablet, $h6-line-height-mobile);
}

p {
  margin: 0;
  @include set-text($paragraph-size);
  line-height: 24px;
  @media only screen and (max-width: $mobile-breakpoint) {
    @include set-text($paragraph-size-mobile);
    line-height: 21px;
  }
  a {
    border-bottom: 1px dotted #8a2a2b;
  }
}

.subtitle {
  margin: 0;
  color: $paragraph-subtitle-color;
  @include set-text($paragraph-size);
  line-height: 24px;
  @media only screen and (max-width: $mobile-breakpoint) {
    @include set-text($paragraph-size-mobile);
    line-height: 21px;
  }
}

small.subtitle {
  line-height: $h5-size / 3;
}

.tag {
  display: block;
  text-transform: uppercase;
  color: $paragraph-subtitle-color;
  @include set-text($tag-size);
  line-height: 21px;
  margin-bottom: 5px;
  @media only screen and (max-width: $mobile-breakpoint) {
    @include set-text($tag-size-mobile);
    line-height: 20px;
    margin-bottom: 5px;
  }
  &.subtitle {
    color: $paragraph-subtitle-color;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  i:before {
    color: $link-color;
  }
  &:hover {
    color: $link-color-hover;
    i:before {
      color: $link-color-hover;
    }
  }
}

small,
.small {
  @include set-text($paragraph-small);
  line-height: $paragraph-small-line-height;
  @media only screen and (max-width: $mobile-breakpoint) {
    @include set-text($paragraph-small-mobile);
    line-height: $paragraph-small-mobile-line-height;
  }
}

.semi-bold {
  font-family: $paragraph-font-bold;
}

.uppercase {
  text-transform: uppercase !important;
}
