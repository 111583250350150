@import '../../../animate';
@import '../../../vars';

.global-message {
  background: $link-color;
  color: $body-background;
  a {
    color: $body-background;
    border-bottom: 1px dotted white;
  }
  &__container {
    text-align: center;
    padding: $margin * 1.5 0;
    @media (max-width: 768px) {
      padding: $margin * 0.8;
    }
  }
  &__icon {
    display: block;
    font-size: 30px;
    padding-bottom: 12px;
  }
}
