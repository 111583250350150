@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-full {
  // Pagination ---------------------------------------------------------------------
  .pagination {
    display: inline-block;
    padding-left: 0;
    > li {
      display: inline; // Remove list-style and block-level defaults
      > a,
      > span {
        position: relative;
        float: left; // Collapse white-space
        text-decoration: none;
        margin-left: -1px;
      }
    }

    > .active > a,
    > .active > span {
      &,
      &:hover {
        z-index: 2;
        color: #fff;
        background: $link-color;
        color: #fff !important;
        cursor: default;
      }
    }

    > .disabled {
      > span,
      > span:hover,
      > a,
      > a:hover {
        color: #fff;
        cursor: not-allowed;
      }
    }
  }

  .pagination-wrapper {
    position: relative;
    background: #fff;
    text-align: center;
    padding: 12px 20px 2px 20px;
  }

  .pagination {
    margin: 0;
    > li {
      float: left;
    }
  }

  .pagination > li > a,
  .pagination > li > span {
    padding: 5px 10px;
    margin: 0 2px;
  }

  .pagination > li:first-child > a,
  .pagination > li:first-child > span,
  .pagination > li:last-child > a,
  .pagination > li:last-child > span {
    border-radius: 14px;
  }

  .pagination > li > a:hover {
    color: #fff;
    background: $link-color;
  }

  .pagination > li.next > a,
  .pagination > li.next > a:hover {
    border-radius: 0;
  }

  @media (max-width: 320px) {
    .pagination > li > a,
    .pagination > li > span {
      margin: 0;
    }

    .pagination > li > a,
    .pagination > li > span {
      padding: 4px 10px 6px 10px;
      border-radius: 14px;
      margin: 0 2px;
    }
  }

  .pagination-container {
    position: fixed;
    bottom: 0;
    z-index: 999;
    border-top: 1px solid #f7f7f7;
    text-transform: uppercase;
    .share-page {
      position: absolute;
      right: 20px;
      top: 6px;
      padding: 10px;
      border-radius: 50%;
      width: 42px;
      height: 42px;
      small {
        display: none;
      }
      i {
        top: 1px;
        position: relative;
      }
    }
    @media (max-width: $screen-xs-max) {
      left: 0;
      right: 0;
      .pagination-wrapper {
        display: block;
        height: 51px;
      }
      .pagination {
        float: left;
      }
      .share-page {
        right: 16px;
      }
    }
    @media only screen and (min-width: $screen-max-container-max) {
      width: 1220px;
    }

    @media only screen and (min-width: $screen-md) and (max-width: $screen-max-container-max) {
      width: calc(100% - 60px);
    }

    @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
      width: calc(100% - 52px);
    }

    .pagination > .disabled > span,
    .pagination > .disabled > span:hover,
    .pagination > .disabled > a,
    .pagination > .disabled > a:hover {
      background: transparent;
      opacity: 0.5;
    }
    .disabled {
      display: none !important;
    }

    .mobile-jump-button {
      position: absolute;
      right: 60px;
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        right: 75px;
        margin-top: -1px;
      }
      @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        right: 75px;
        margin-top: -1px;
      }
    }
  }

  .touch {
    #jump-to-page {
      display: none !important;
    }
    .mobile-jump-button {
      display: block !important;
      visibility: visible !important;
    }
    .icon-large-screen {
      display: none !important;
    }
  }

  .pagination > a:focus {
    background-color: green !important;
  }

  .pagination-container .mobile-jump-button {
    position: absolute;
    right: 20px;
  }
}

@include legacy-pages {
  .pagination > li {
    border: 0;
  }
  .pagination > li > a,
  .pagination > li > span {
    color: $link-color;
    border-radius: 22px;
    border: 0;
    padding: 6px 12px;
    @include set-text($tag-size);
    line-height: 21px;
    margin-bottom: 0;
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
      margin-bottom: 0;
    }
    &:hover {
      color: #fff;
    }
  }
  @media (max-width: $screen-xs-max) {
    .pagination-container {
      width: 100% !important;
    }
  }
}

.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes {
  .node-type-location .region-content,
  .node-type-people .region-content {
    .pagination-wrapper {
      background: #fff;
      text-align: center;
      padding: 12px 20px 2px 20px;
    }
    .pagination {
      margin: 0;
      > li {
        float: left;
        > a {
          color: #8a2a2b;
          border-radius: 22px;
          border: 0;
          padding: 6px 12px;
          font-size: 17px;
          line-height: 21px;
          margin-bottom: 0;
        }
      }
      .active > a,
      .active > a:hover,
      .active > span,
      .active > span:hover {
        z-index: 2;
        background: #8a2a2b;
        color: #fff !important;
        cursor: default;
      }
    }
  }
}

.pagination .disabled {
  display: none !important;
}
