@import '../../../animate';
@import '../../../vars';

$location-info-below-desktop: 1300px;
$location-info-mobile: 768px;

.school-session-location-tabs {
  @media screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  ul.tabs {
    padding: 0;
    display: table;
    list-style: none;
    width: 100%;
    margin-bottom: $margin * 1.5;
  }

  ul.tabs li {
    display: table-cell;
    margin: 0;
    cursor: pointer;
    border-left: 1px solid #fff;
    background-color: $link-color;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    vertical-align: middle;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 21px;
    @media only screen and (min-width: 768px) {
      padding: 13px 16px;
      font-size: 17px;
      line-height: 22px;
    }
  }

  .tab_last {
    border-right: 1px solid $link-color;
  }

  ul.tabs li:hover {
    background-color: $link-color-hover;
    color: #fff;
  }

  ul.tabs li.active {
    background-color: transparent;
    color: $paragraph-body-color;
  }

  ul.tabs li:first-child {
    border-left: 1px solid $link-color;
  }

  .tab_container {
    border-top: none;
    width: 100%;
    background: #fff;
  }

  .tab_container.last {
    @media screen and (max-width: 767px) {
      .school-session-location-tabs__description {
        border-bottom: 1px $hr-border solid;
        padding-bottom: 10px;
      }
    }
  }

  &__description {
    @media screen and (min-width: 768px) {
      border-bottom: 1px $hr-border solid;
      padding-bottom: 20px;
    }
  }

  .tab_content {
    display: none;
  }

  .tab_drawer_heading {
    display: none;
  }

  &__description {
    margin-bottom: $margin * 1.5;
    @media screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }

    ol {
      padding-left: 20px;
    }
    ul {
      padding-left: 20px;
      margin: 1em 0;
      li {
        list-style-type: disc;
      }
    }
  }

  .icon-RCT-down {
    display: none;
  }

  &__content-title {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    ul.tabs {
      display: none;
    }
    .tab_drawer_heading {
      border-top: 1px solid $hr-border;
      margin: 0;
      padding: 16px 20px 16px 0;
      display: block;
      cursor: pointer;
      color: $link-color;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        color: $link-color-hover;
      }
    }
    .tab_drawer_heading_last {
      border-bottom: 1px solid $hr-border;
    }
    .tab_drawer_heading_last.d_active {
      border-bottom: none;
    }

    .tab_container {
      margin: 0 $margin * 0.8;
      width: auto;
    }

    .container,
    .full-container {
      padding: 0;
    }

    .icon-RCT-down {
      color: $link-color;
      display: block;
      font-size: 12px;
      position: absolute;
      top: 22px;
      right: 0;
      transition: all 0.3s ease;
    }

    .d_active .icon-RCT-down {
      transform: rotate(180deg);
    }
    .mobile-white.p-t-b-lg {
      padding: 0;
    }
    &__description-container {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .accordion-title-block {
    position: relative;
  }

  &__top-row {
    position: relative;
    display: block;
    text-align: center;
    padding: $margin * 2 0;
    @media (max-width: $location-info-mobile) {
      padding: $margin * 0.8 0;
    }
  }

  &__tagline {
    text-transform: none;
  }

  iframe {
    width: 100%;
    height: 350px;
    border: none;
  }

  &__map {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__map-button {
    position: absolute;
    bottom: 20px;
    right: 100px;
    background: $link-color !important;
    color: #fff !important;
    @media (max-width: $location-info-mobile) {
      position: relative;
      bottom: auto;
      right: auto;
      text-align: center;
      margin: $margin * 0.8 $margin * 0.8 0 $margin * 0.8;
    }
  }

  .icon-directions:before {
    color: #fff !important;
  }

  .icon-RCT-bus,
  .icon-RCT-car {
    padding-left: 5px;
  }

  &__map-direction-info {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: auto;
    padding: $margin * 1.5 0;
    > h6 {
      display: inline;
    }
    @media (max-width: $location-info-mobile) {
      padding: $margin * 0.8;
    }
  }

  &__map-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__map-direction-info-container {
    display: flex;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      margin-bottom: 4px;
    }
    &:last-child {
      margin: 0;
      p {
        margin: 0;
      }
    }
  }

  &__column-one {
    padding-right: 15px;
  }

  @media (max-width: 767px) {
    &__content-title {
      display: none;
    }
  }
}
