@import '../../../animate';
@import '../../../vars';

.locations-map {
  #map {
    width: 100%;
    height: 500px;
  }

  &__info-hidden {
    display: none;
  }

  &__close-icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
    width: 10px;
    background: #fff;
    color: #8a2a2b;
    font-size: 12px;
    padding: 14px;
    z-index: 99999;
    margin-right: -5px;
    margin-top: -8px;
    @media screen and (max-width: 767px) {
      margin-right: -1px;
    }
  }

  &__close-icon:hover {
    cursor: pointer;
  }
  .gm-style-iw {
    overflow: visible;
  }

  .card-visit {
    border-bottom: none;
    box-shadow: none !important;
    @media screen and (max-width: 767px) {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  .gm-style {
    font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif !important;
  }
}
