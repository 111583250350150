@import '../../../animate';
@import '../../../vars';

.event-detail-block {
  background: $lighter-gray;
  padding: $margin $margin 32px $margin;

  .close {
    display: none;
  }
  .btn.button-outline {
    i {
      position: relative;
      top: 1px;
    }
  }
  .access-icons {
    i {
      color: $link-color;
    }
  }
  .list-icons {
    li {
      margin-bottom: 16px;
      padding-left: 30px;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 2px;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
  }
  .info {
    background: #f4ebeb;
    margin: 16px 0;
    > * {
      margin-bottom: 0;
    }
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 16px;
    .info {
      padding: 16px 16px 32px 16px;
    }
    .btn.button-outline {
      text-align: center;
      display: block;
      width: 100%;
    }
  }

  .tooltip.in {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $link-color;
    border-radius: 0;
  }

  .tooltip-arrow {
    border-top-color: $link-color !important;
  }
}

.icon-autism,
.icon-autisim {
  background: url('./autism.svg');
  width: 78px;
  height: 56px;
  display: block;
  background-size: 100%;
  position: relative;
  top: 4px;
  @media only screen and (max-width: 767px) {
    width: 68px;
    height: 48px;
  }
}
