@import '../../../animate';
@import '../../../vars';

.paragraph-text {
  a {
    border-bottom: 1px dotted $link-color;
  }

  ul,
  ol {
    padding-left: 20px;
  }
  ul {
    margin: 1em 0;
    li {
      list-style-type: disc;
    }
  }

  &.small-margin {
    margin-bottom: 10px;
  }
}
