@import '../../../animate';
@import '../../../vars';

.locations-block {
  margin-bottom: $margin * 1.5;

  &__outer {
    background: $lighter-gray;
    padding: $margin;

    li {
      color: $paragraph-subtitle-color;
      margin-bottom: 5px;
      text-align: center;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;

    &__outer {
      padding: $margin * 0.8;
    }
  }

  .icon-RCT-location {
    margin-right: 0;
    top: -3px;
    position: relative;
  }
}
