@import '../../../animate';
@import '../../../vars';

.collection-object-info-area {
  margin-bottom: $margin * 1.5;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: $margin * 0.8;
    padding: 0 $margin * 0.8;
  }
  &__accordion {
    border-top: 1px solid $hr-border;
    > li {
      border-bottom: 1px solid $hr-border;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  &__accordion-tab {
    a {
      display: block;
      padding: $margin 0;
      position: relative;
      .icon-RCT-down {
        position: absolute;
        right: 0;
        top: 32px;
        font-size: $chevron-font-size;
        @include animate(0.3);
      }
      @media only screen and (max-width: $mobile-breakpoint) {
        padding: $margin * 0.8 $margin * 0.8 $margin * 0.8 0;
        .icon-RCT-down {
          top: 22px;
        }
      }

      &.active {
        .icon-RCT-down {
          @include transform(rotate(180deg));
        }
      }
    }
  }

  &__accordion-inside {
    display: none;
    padding-bottom: $margin;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 0 $mobile-global-margin 0;
      flex-direction: column;
    }
  }

  &__search-link,
  &__list,
  &__materials,
  &__techniques,
  &__body {
    margin-bottom: 12px;
  }

  //Tab One
  &__tab-one {
    display: flex;
    flex-direction: column;
  }

  //Tab Two
  &__column-one,
  &__column-two {
    flex-direction: column;
    flex: 1 0 auto;
    width: calc(50% - 25px);

    @media only screen and (max-width: $mobile-breakpoint) {
      width: 100%;
    }
  }

  &__column-one {
    margin-right: 50px;
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-right: 0;
    }
  }

  &__list > a {
    margin-bottom: 12px;
  }

  //Tab Three
  &__tab-three {
    flex-direction: column;
  }

  &__materials {
    display: flex;
    flex-wrap: wrap;
    > a {
      padding-right: 5px !important;
    }
  }

  &__techniques {
    display: flex;
    flex-wrap: wrap;
    > a {
      padding-right: 5px !important;
    }
  }

  //Tab Four
  &__tab-four li {
    margin-bottom: 0px !important;
  }

  &__groupings {
    margin-bottom: 12px;
  }

  @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
    padding-left: 30px;
    padding-right: 30px;
  }
}
