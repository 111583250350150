@import '../../../animate';
@import '../../../vars';

$paragraph-two-images-with-text-full-tablet: 991px;
$paragraph-two-images-with-text-full-min-tablet: 992px;
$paragraph-two-images-with-text-full-max-mobile: 767px;
$paragraph-two-images-with-text-full-min-mobile: 768px;

.paragraph-two-images-with-text-full {
  $root: &;
  padding-top: $margin * 1.5;
  margin-bottom: $margin * 1.5;

  @media screen and (max-width: 767px) {
    padding-top: $margin * 0.8;
    margin-bottom: $margin * 0.8;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__row {
    position: relative;
    display: flex;
    margin-bottom: $margin * 1.5;

    @media screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
      flex-direction: column;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 25%;
    }

    @media screen and (min-width: 992px) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
      border-radius: 0 3px 3px 0;
    }

    &:nth-of-type(even) {
      flex-direction: row-reverse;

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;

    @media screen and (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &__image-wrapper {
    display: block;
    overflow: hidden;
  }

  &__image {
    img {
      width: 100%;
      display: block;
      @include animate(0.3);
      &:hover {
        @include transform(scale(1.1));
      }
    }

    @media screen and (min-width: 992px) {
      padding-right: 10px;
      background: white;
    }
  }

  .image-animated,
  .image-not-animated {
    margin-top: 0px;
  }

  &__row:nth-child(even) {
    .paragraph-two-images-with-text-full__image {
      a {
        border-radius: 0 3px 3px 0;
      }
      @media screen and (min-width: 992px) {
        padding: 0 0 0 10px;
        background: white;
      }
    }
    .paragraph-two-images-with-text-full__content {
      @media screen and (min-width: 992px) {
        padding: 30px 20px 30px 30px;
        border-radius: 3px 0 0 3px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $margin * 1.5;
    text-align: center;
    background-color: #fff;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;

    @media only screen and (min-width: 992px) {
      padding: 30px 30px 30px 20px;
      border-radius: 0 3px 3px 0;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: calc(100% - 60px);
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: 767px) {
      padding: $margin * 0.8 0;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  &__title {
    margin-bottom: 5px;
  }

  &__description-one {
    color: $paragraph-subtitle-color;
  }

  &__description-two {
    color: $paragraph-subtitle-color;
  }

  &__secondary-link {
    i {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .btn {
    align-self: flex-start;
    margin: 0 auto;
  }

  &__row {
    &,
    #{$root}__image,
    #{$root}__image-wrapper {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:nth-of-type(even) {
      &,
      #{$root}__image,
      #{$root}__image-wrapper {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}
