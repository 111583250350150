@import '../../../animate';
@import '../../../vars';

.paragraph-hubspot-form {
  margin-bottom: 20px;

  @media only screen and (min-width: $screen-sm) {
    margin-bottom: 50px;
  }
}
