@import '../../../animate';
@import '../../../vars';

$paragraph-promo-text-tablet: 991px;
$paragraph-two-images-with-text-full-min-tablet: 992px;
$paragraph-promo-text-mobile: 767px;
$paragraph-two-images-with-text-full-min-mobile: 768px;

.paragraph-promo-text {
  margin-bottom: $margin * 1.5;

  h3 {
    @media only screen and (max-width: 767px) {
      font-size: 20px;
      line-height: 23px;
    }
  }

  @media screen and (max-width: $paragraph-promo-text-mobile) {
    margin-bottom: $margin * 0.8;
  }

  &__container {
    display: flex;
    padding-bottom: $margin * 1.5;
    @media screen and (max-width: $paragraph-promo-text-mobile) {
      padding-bottom: 0;
    }

    @media screen and (max-width: $paragraph-promo-text-tablet) {
      flex-direction: column;
    }
  }

  &__item {
    display: flex;
    width: calc(50% - 10px);
    @media screen and (min-width: 992px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:first-child {
      padding-right: 10px;

      @media screen and (max-width: $paragraph-promo-text-tablet) {
        padding-right: 0;
      }
    }

    &:last-child {
      padding-left: 10px;

      @media screen and (max-width: $paragraph-promo-text-tablet) {
        padding-left: 0;
      }
    }

    @media screen and (max-width: $paragraph-promo-text-tablet) {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $margin * 1.5;
    text-align: center;
    background-color: #fff;
    border-radius: 3px;
    @media screen and (min-width: 992px) {
      width: 100%;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: $paragraph-promo-text-tablet) {
      text-align: left;
      background-color: $lighter-gray;
      padding: 0 0 30px 0;
      h3 {
        text-align: left;
      }
    }

    @media screen and (max-width: $paragraph-promo-text-mobile) {
      padding: 0 0 $margin * 0.8;
      background-color: #fff;
    }
  }

  &__title {
    margin-bottom: 5px;
    > a {
      @media screen and (max-width: $paragraph-promo-text-tablet) {
        color: #222;
      }
    }
  }

  p {
    color: $paragraph-subtitle-color;
  }

  &__secondary-link {
    i {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .btn {
    align-self: flex-start;
    @media screen and (min-width: $paragraph-two-images-with-text-full-min-tablet) {
      margin: 0 auto;
    }
  }
}
