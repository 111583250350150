@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@include legacy-full {
  // tabs

  .tab-pane {
    position: relative;
  }

  .loacation-description img {
    margin-bottom: 11px;
  }

  .object-tab-listing {
    position: relative;
    background: $link-color;
    padding: 20px 20px 40px;
    .tab-pane .select-dropdown {
      margin-bottom: 10px;
    }
    .tab-pagination {
      text-align: center;
      background: $link-color;
      padding: 5px 15px 0;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .select-dropdown .btn-success,
    .search-view ul li a {
      background: #fff;
    }

    .search-view li.active a,
    .search-view li a:hover,
    .search-view li a:focus {
      background: $link-color;
    }
    .search-view {
      margin-bottom: 10px;
    }
  }

  .hide-tab,
  .hide-accordion {
    border: none;
    background: none;
    color: $link-color;
    padding: 0;
    .caret {
      margin-left: 5px;
    }
  }

  .hide-accordion,
  .refine-link a {
    margin-bottom: 10px;
  }

  .node-type-location {
    #accordion {
      .panel-body {
        padding: 0;
        h3 {
          display: none;
        }
      }
      .panel-collapse {
        background: transparent;
        .search-result {
          @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            margin-left: -34px;
            margin-right: -34px;
          }
        }
      }
    }
  }

  .collection-near-you .cny-help-icon,
  .page-collection-near-you .cny-help-icon {
    position: absolute;
    top: -1px;
    right: 10px;
    text-align: center;
    padding-top: 0px;
    margin-top: 0;
    cursor: pointer;
  }

  @media (max-width: $screen-xs-max) {
    .refine-link a {
      display: block;
    }

    .search-result .list-description {
      min-height: 190px !important;
    }

    .node-type-location .location-wrapper h1 {
      max-width: 70%;
    }
    .node-type-location .location-wrapper .share-page {
      right: 15px;
    }

    .node-type-people .location-wrapper .share-page {
      position: relative;
      display: inline-block;
      margin-bottom: 10px;
      right: 0;
    }
  }

  /*
  * Mobiles - Maximum width of 768 */
  @media (max-width: 480px) {
    .search-result .list-description {
      min-height: 100px !important;
      height: auto !important;
    }
  }

  @media (min-width: $screen-sm-min) {
    .page-collection-near-you {
      .section-title,
      .section-title-strapline {
        max-width: 720px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    .refine-link {
      padding-right: 28px !important;
      margin-left: -7px;
    }

    .hide-tab {
      position: absolute;
      top: 27px;
      right: 20px;
      font-size: 85%;
    }

    .object-tab-listing {
      margin: 0px;
      .search-tile .list-image {
        width: 344px;
        height: 344px;
      }
    }
    .modal.fixed-on-map {
      width: 1170px !important;
      left: 50% !important;
      margin-left: -360px !important;
    }
  }

  @media (min-width: $screen-md-min) {
    .page-collection-near-you {
      .section-title,
      .section-title-strapline {
        max-width: 940px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    .hide-tab {
      right: 75px;
    }

    .refine-link a {
      position: relative;
      top: -5px;
    }

    .object-tab-listing {
      .search-tile .list-image {
        width: 188px;
        height: 188px;
      }
    }
    .modal.fixed-on-map {
      width: 1170px !important;
      left: 50% !important;
      margin-left: -550px !important;
    }
  }

  /*
  * Tablets - between 768 and 992 */
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .node-type-location .tab-controls {
      margin-left: -19px;
      margin-right: -19px;
      margin-bottom: 10px;
    }
  }

  @media (min-width: $screen-lg-min) {
    .page-collection-near-you {
      .section-title,
      .section-title-strapline {
        max-width: 1138px;
        display: block;
        margin: 0 auto;
        margin-bottom: 10px;
      }
    }

    .object-tab-listing {
      margin: 0 -30px;
      padding: 30px 30px 43px 30px;
    }

    .object-tab-listing {
      .search-tile .list-image {
        width: 225px;
        height: 225px;
      }
    }

    .location-page-search.object-tab-listing {
      .search-tile .list-image {
        width: 218px;
        height: 218px;
      }
    }

    .modal.fixed-on-map {
      width: 1170px !important;
      left: 50% !important;
      margin-left: -615px !important;
    }
  }

  @media (min-width: 768px) {
    #cny-tabs {
      .col-sm-2.sm-22 {
        width: 20%;
      }
    }
  }
}

.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes,
.page-whatson .region-content,
.page-schools .region-content,
.page-collection-search .region-content,
.page-collection-near-you .region-content,
.node-type-collection-landing-page .region-content,
.node-type-location .region-content,
.node-type-people .region-content {
  // who, what where etc tabs

  #who {
    form {
      position: relative;
    }
    .dropdown-menu > li > a {
      padding: 5px 15px;
      border-bottom: none;
    }
    // .loader-cont {
    //   margin-top: -15px;
    // }
  }

  #when {
    .scroll-to-onfocus {
      width: calc(100% - 78px);
    }
    .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
      width: auto;
    }

    .form-group,
    .btn-default {
      margin-bottom: $mobile-global-margin;
    }

    .btn-default {
      float: right;
    }

    label {
      margin-bottom: 12px;
      display: inline-block;
      max-width: 100%;
      font-family: $paragraph-font-bold;
      @include set-text($tag-size);
      line-height: 21px;
      @media only screen and (max-width: $mobile-breakpoint) {
        @include set-text($tag-size-mobile);
        line-height: 20px;
      }
    }

    @media only screen and (min-width: 768px) {
      .form-control {
        display: inline-block;
        width: 132px;
        vertical-align: middle;
      }

      .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
      }

      .scroll-to-onfocus {
        width: auto;
      }

      .select-dropdown {
        margin-right: 20px;
      }

      .form-group,
      .btn-default {
        margin-bottom: $margin;
      }
    }

    @media only screen and (min-width: 992px) {
      .form-control {
        width: auto;
      }
    }
  }

  #more {
    form {
      position: relative;
    }
    .select-dropdown {
      margin-bottom: $mobile-global-margin;
    }

    @media only screen and (min-width: 768px) {
      .select-dropdown {
        margin-bottom: $margin;
      }
    }

    &.tab-pane div p {
      font-family: $paragraph-font-bold;
      @include set-text($tag-size);
      line-height: 21px;
      margin-bottom: 5px;
      @media only screen and (max-width: $mobile-breakpoint) {
        @include set-text($tag-size-mobile);
        line-height: 20px;
      }
    }
    .loader-cont {
      top: calc(50% + 15px);
      @media only screen and (min-width: 767px) {
        top: calc(50% + 1px);
      }
    }
  }
}

.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes {
  /* locationa and people tabs */

  .node-type-people .region-content {
    .grey-background {
      padding: 30px;
      @media only screen and (max-width: 767px) {
        padding: 16px;
      }
    }

    .select-dropdown {
      position: relative;
    }

    .people-list .btn {
      margin-bottom: 0 !important;
    }

    .tab-controls .bootstrap-select.btn-group .dropdown-toggle .caret {
      right: 27px;
    }
  }
}

// accordion & tabs -------------------------------------------------------------

// accordion

.location-page-search .tab-controls {
  .col-xs-6 {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.object-accordion {
  margin-bottom: 30px;
  li {
    position: relative;
    .accordion-triangle {
      position: absolute;
      top: 22px;
      right: 15px;
    }
  }
}

.object-accordion > ul > li > a {
  display: block;
  padding: 20px 40px 20px 15px;
  background: ®;
  color: #eee;
  border-bottom: 1px solid #afcbe0;
}

.object-accordion ul > li > a.collapsed {
  background: $link-color;
}

.object-accordion .panel-collapse {
  background: #fff;
  margin-bottom: 0;
}

.level-lists > li {
  border-left: 1px solid #7a7a7a;
  margin-left: -1px;
}

.level-lists > li.last {
  border-left: none;
  margin-left: 0px;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

.level-lists > li:before {
  left: -2px;
}

.accordion-triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #eee;
  border-top: none;
  float: right;
  margin-top: 6px;
}

.collapsed .accordion-triangle,
.object-accordion .collapsed .accordion-triangle {
  border-top: 10px solid #eee;
  border-bottom: none;
}
