@import '../../../animate';
@import '../../../vars';

.search-filters {
  margin-bottom: $margin * 1.5;

  @media only screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;

    .desktopTitle {
      display: none;
    }
  }

  &__title {
    margin: $margin * 2 0;
    @media only screen and (max-width: 767px) {
      margin: $margin * 0.8;
    }
    p {
      color: $paragraph-subtitle-color;
      span {
        font-family: $paragraph-font-bold;
      }
    }
  }

  .desktopTitle {
    color: #737373;
    margin: 0 0 12px 0;
    font-size: 19px;
    line-height: 24px;
  }

  .mobileTitle {
    position: relative;
    text-align: left;
    display: none;
    margin: 0;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    span {
      padding-bottom: 16px;
      color: $link-color;
      width: 100%;
      display: block;
      font-size: 20px;
      font-family: $titles-font;

      &:before {
        border-top: 1px solid $hr-border;
        content: '';
        display: block;
        padding-top: 16px;
        width: 100%;
      }
    }

    .icon-RCT-down {
      color: $link-color;
      display: none;
      position: absolute;
      right: 20px;
      top: 26px;
      font-size: $chevron-font-size;
      @include animate(0.3);

      @media only screen and (max-width: 767px) {
        top: 22px;
        display: block;
      }
    }

    &.active {
      .icon-RCT-down {
        @include transform(rotate(180deg));
      }
    }
  }

  &__the-filters {
    ul {
      display: block;
      overflow: hidden;
      width: 100%;

      @media only screen and (max-width: 767px) {
        display: none;
      }

      .border {
        &:before {
          border-bottom: 1px solid $lighter-gray;
          content: '';
          display: block;
          height: 1px;
          width: 100%;
        }

        @media only screen and (max-width: 767px) {
          display: none;
        }
      }

      .filter {
        cursor: pointer;
        margin-bottom: $margin;
        @media only screen and (max-width: 767px) {
          margin-bottom: $margin * 0.8;
        }

        .inner {
          background: $lighter-gray;
          color: $link-color;
          font-size: 17px;
          font-family: $paragraph-font;
          padding: $margin * 0.8;
          text-transform: inherit;
          text-align: left;
          line-height: 21px;
          width: 100%;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
          }

          .tick {
            border: 1px solid $link-color;
            border-radius: 3px;
            display: block;
            float: left;
            height: 20px;
            margin-right: 10px;
            width: 20px;

            &:before {
              display: none;
            }

            &.active {
              &:before {
                display: block;
                font-size: 13px;
                padding: 3px 1px;
              }
            }
          }

          .amount {
            float: right;
          }
        }
      }
    }
  }

  .borderMobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: block;
      &:before {
        border-bottom: 1px solid $hr-border;
        content: '';
        display: block;
        width: 100%;
      }
    }
  }
}
