@import '../../../animate';
@import '../../../vars';

.card-collection-object-near-you-container {
  &.open-wrapper .mobile-toggle i {
    @include animateDelay(0.2, 0.2);
    @include transform(rotate(0deg) translate(0, -2px));
  }

  .card-collection-object .list-icons {
    position: absolute;
    bottom: 16px;
    width: 90%;
    font-size: 15px;
    line-height: 19px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.card-collection-object-container .location-pop-up {
    position: relative;
    width: 100%;
    overflow: visible;
  }

  .location-pop-up {
    cursor: pointer;
    width: 100%;
    .icon-RCT-location:before {
      color: $link-color !important;
    }
    &__link {
      height: 100%;
    }
    &__text {
      display: none;
      left: -16px;
      right: -16px;
      position: absolute;
      top: -6px;
      transform: translateY(-100%);
      background: $link-color;
      padding: 16px;
      font-size: 15px;
      &:after {
        content: ' ';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 9px 8px 0 8px;
        border-color: $link-color transparent transparent transparent;
        position: absolute;
        bottom: -8px;
        left: 13px;
      }
      @media only screen and (max-width: 767px) {
        padding: 8px 16px;
        left: -3px;
        right: 0px;
        &:after {
          left: 0px;
        }
      }
      span.ellipsis {
        float: left;
        max-width: 95%;
        @media only screen and (max-width: 767px) {
          max-height: 19px;
        }
      }

      &.open {
        display: block;
        z-index: 1000;
      }
      a {
        color: white;
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        @media only screen and (max-width: 767px) {
          font-family: $paragraph-font;
        }
        &:last-child {
          margin-bottom: 0;
        }
        i {
          &:before {
            color: white;
            font-size: 12px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
