@import '../../../animate';
@import '../../../vars';

.paragraph-four-images-with-text-slim-half {
  .items {
    display: flex;
    justify-content: center;
  }

  padding-top: $margin * 1.5;
  margin-bottom: $margin * 1.5;
  .text-center {
    margin-bottom: $margin * 1.5;
  }
  .image-wrapper + .white-card {
    margin-top: unset;
  }
  .white-card {
    text-align: center;
    padding: 12px 16px 7px;
    margin-bottom: 30px;
    .tag {
      .ellipsis-h5-2-line {
        min-height: 42px;
        max-height: 42px;
        color: $link-color;
      }
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 16px;
      padding: 10px 0 0;
      line-height: 18px;
      min-height: 46px;
      .tag {
        line-height: 18px;
        margin-bottom: 0px !important;
        .ellipsis-h5-2-line {
          min-height: 36px;
          max-height: 36px;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }
  @media only screen and (max-width: 767px) {
    .col-xs-6.col-sm-3:nth-child(3) {
      clear: both;
    }
    .text-center {
      margin-bottom: $mobile-global-margin;
    }
  }

  .image-animated {
    img {
      width: 100%;
      display: block;
      border-radius: 3px 3px 0 0;
      @include animate(0.3);
      &:hover {
        @include transform(scale(1.1));
      }
    }
  }
}
