@import 'vars';
@import 'animate';

a {
  transition: all 0.3s ease-out;
}

strong,
b {
  font-family: $paragraph-font-bold;
  font-weight: normal;
}

.hidden {
  display: none !important;
}

.bg-white {
  background: #fff;
}

.grey-background {
  background: $lighter-gray;
}

@media only screen and (max-width: 767px) {
  .grey-background,
  .mobile-white {
    background: white;
    &.p-t-b-lg {
      padding: 16px 0 0 0;
    }
  }
}

.edinburgh-color {
  color: $edinburgh-color !important;
  i:before {
    color: $edinburgh-color !important;
  }
}

.windsor-color {
  color: $windsor-color !important;
  i:before {
    color: $windsor-color !important;
  }
}

.london-color {
  color: $link-color !important;
  i:before {
    color: $link-color !important;
  }
}

/*
* Padding Helpers
*/

.pad {
  padding: 30px;
  @media only screen and (max-width: 767px) {
    padding: $mobile-global-margin;
  }
}

.pad-small {
  padding: 20px !important;
  @media only screen and (max-width: 767px) {
    padding: $mobile-global-margin !important;
  }
}

.pad-extra-small {
  padding: $mobile-global-margin !important;
}

.tablet-margin-0 {
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    margin: 0 !important;
  }
}

.mobile-margin-0 {
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
  }
}

/*
* Margin Top Helpers
*/

.xs-top {
  position: relative;
  top: 2px;
}

.r-xs-top {
  position: relative;
  top: 3px;
}

.m-t-0 {
  margin-top: 0;
}

.m-t-xs {
  margin-top: $margin / 2;
}

.m-t-sm {
  margin-top: 10px;
}

.m-t-md {
  margin-top: $margin;
  @media only screen and (max-width: 767px) {
    margin-top: $mobile-global-margin;
  }
}

.m-t-lg {
  margin-top: $margin * 1.5;
  @media only screen and (max-width: 767px) {
    margin-top: $mobile-global-margin;
  }
}

.m-t-xl {
  margin-top: $margin * 2;
  @media only screen and (max-width: 767px) {
    margin-top: $mobile-global-margin;
  }
}

/*
* Margin Bottom Helpers
*/

.m-b-0 {
  margin-bottom: 0;
}

.m-b-xs {
  margin-bottom: $margin / 2;

  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.m-b-sm {
  margin-bottom: 10px;

  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.m-b-md {
  margin-bottom: $margin;

  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.m-b-lg {
  margin-bottom: $margin * 1.5;
  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.m-b-xl {
  margin-bottom: $margin * 2;
  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.p-t-b-lg {
  padding: $margin * 1.5 0;
  @media only screen and (max-width: 767px) {
    padding: $mobile-global-margin 0;
  }
}

.p-t-lg {
  padding-top: $margin * 1.5;
  @media only screen and (max-width: 767px) {
    padding-top: $mobile-global-margin;
  }
}

.radius {
  border-radius: 50%;
}

.hidden-xl {
  @media (min-width: $xl-breakpoint) {
    display: none !important;
  }
}

.visible-xl {
  display: none;
  @media (min-width: $xl-breakpoint) {
    display: block;
  }
}

.color-brand-red {
  color: $link-color;
}

ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
  &.list-inline {
    li {
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.white-card-wrapper {
  @media only screen and (max-width: 767px) {
    margin-bottom: $mobile-global-margin;
  }
}

.white-card,
.grey-card {
  padding: $margin * 1.5;
  position: relative;
  z-index: 1;
  .description {
    @include set-text($description-size);
    line-height: 21px;
    color: $paragraph-subtitle-color;
  }
  @media only screen and (max-width: 767px) {
    padding: $mobile-global-margin;
    .description {
      @include set-text($description-size-mobile);
      line-height: 20px;
    }
  }
}

.white-card {
  background: #fff;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
  *:last-child,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 5px !important;
  }
  .tag {
    margin-bottom: 5px !important;
  }
  &.fixed-height {
    min-height: 230px;
  }
}

.card-box-shadow {
  @media only screen and (min-width: 768px) {
    box-shadow: $card-box-shadow;
  }
}

.grey-card {
  background: $lighter-gray;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 5px !important;
  }
}

.image-animated {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: block;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
  }
}

.col-20-width {
  margin: auto;
}

@media only screen and (min-width: $screen-max-container-max) {
  .container,
  .col-20-width {
    width: $container-max;
    padding: 0px 145px;
  }
  .full-container {
    width: $container-max;
    padding: 0 $margin * 2;
  }
}

// tablet
@media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
  .container,
  .col-20-width {
    width: 100%;
    padding: 0px 30px;
  }
  .full-container {
    width: 100%;
    padding: 0px 30px;
  }
}

// dekstop
@media only screen and (min-width: 1120px) and (max-width: $screen-max-container-max) {
  .container,
  .col-20-width {
    padding: 0px 30px;
    padding: 0px calc(30px + 7%);
  }
  .full-container {
    width: 100%;
    padding: 0px 30px;
  }
}

// mobile
@media only screen and (max-width: 767px) {
  .container,
  .col-20-width,
  .col-16-width,
  .full-container {
    width: 100%;
    padding: 0px 16px;
  }
}

.block {
  display: block;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.center-vertical {
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}

button.link {
  outline: 0;
  border: 0;
  background: none;
  padding: 0;
}

button,
.button,
.btn,
.btn-default {
  font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  background: $link-color;
  color: #fff;
  outline: 0;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  line-height: 21px;
  transition: all 0.3s ease-out;
  font-size: 16px;
  padding: 12px 15px;

  @media screen and (min-width: 767px) {
    line-height: 22px;
    font-size: 17px;
    padding: 13px 16px;
  }

  i,
  i:before {
    color: #fff;
  }
  &:hover,
  &:focus {
    background: $link-color-hover;
    color: #fff;
    i,
    i:before {
      color: #fff;
    }
  }
  &:active {
    background: darken($link-color, 1%);
  }
  &.with-arrow {
    position: relative;
    padding-right: 56px;
    > i.icon-RCT-arrow {
      @include animate(0.3);
      position: absolute;
      right: 24px;
      top: 16px;
      font-size: 12px;
      @include translate(0px, 0);
    }
    &:hover,
    &:focus {
      > i.icon-RCT-arrow {
        @include translate(5px, 0);
      }
    }
  }
  &.with-arrow-left {
    position: relative;
    padding-left: 56px;
    > i.icon-RCT-arrow-left {
      @include animate(0.3);
      position: absolute;
      left: 24px;
      top: 16px;
      font-size: 12px;
      @include translate(0px, 0);
    }
    &:hover,
    &:focus {
      > i.icon-RCT-arrow-left {
        @include translate(-5px, 0);
      }
    }
  }
  &.button-outline {
    border: 1px solid $link-color;
    background: transparent;
    color: $link-color;
    font-size: 17px;
    line-height: 22px;
    padding: 12px 15px;
    i,
    i:before {
      color: $link-color;
    }
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
      padding: 11px 14px;
    }
    &:hover,
    &:focus {
      border: 1px solid $link-color;
      background: $link-color;
      color: white;
      i:before {
        color: white;
      }
    }
    &.button-disabled {
      border: 1px solid #d0d0d0;
      color: #d0d0d0;
      
      &:hover,
      &:focus {
        border: 1px solid #d0d0d0;
        background: #d0d0d0;
        color: white;
        cursor: not-allowed;
        i:before {
          color: white;
        }
      }
    }
  }
  &.button-sm,
  &.btn-sm,
  &.dropdown-toggle {
    font-family: $paragraph-font;
  }
  // Outline 2 button
  &.btn-sm {
    font-size: 15px;
    line-height: 19px;
    padding: 10px 14px;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      padding: 9px 13px;
    }
  }
  &.button-sm {
    font-size: 15px;
    line-height: 19px;
    padding: 11px 15px;
    border: 1px solid $link-color;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
    }
  }
  small {
    color: #fff;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $hr-border;
}

input {
  border: 1px solid $form-fields;
  font-family: $paragraph-font;
  color: $paragraph-body-color;
  width: 100%;
  border-radius: 3px;
  font-size: 17px;
  line-height: 22px;
  padding: 12px 15px;
  height: 48px;
  @media only screen and (max-width: 767px) {
    @include set-text($paragraph-size / $mobile-ratio);
    line-height: 21px;
    font-size: 16px;
    padding: 11px 14px;
    height: 45px;
  }
  ::-webkit-input-placeholder {
    color: $paragraph-subtitle-color;
  }
  :-moz-placeholder {
    color: $paragraph-subtitle-color;
  }
  ::-moz-placeholder {
    color: $paragraph-subtitle-color;
  }
  :-ms-input-placeholder {
    color: $paragraph-subtitle-color;
  }
}

.white-card,
.image-animated > div {
  @include animate(0.3);
}

.img-animated-link {
  display: block;
  overflow: hidden;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  .image-animated {
    display: block;
    max-width: 100%;
    -moz-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  &:hover .image-animated {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-moz-document url-prefix(), _:-ms-lang(x) {
  .ellipses-fix {
    overflow: hidden;
    position: relative;
    line-height: 1.2em;
    max-height: 3.6em;
  }

  .ellipses-fix:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .ellipses-fix:after {
    content: '...';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1.2em;
    background: white;
    bottom: 0;
  }
}

.ellipsis {
  max-height: 21px;
  display: block;
  overflow: hidden;
  max-width: 100%;
}

.ellipsis-2-line {
  max-height: 50px;
  display: block;
  overflow: hidden;
}

.ellipsis-3-line {
  max-height: 72px;
  display: block;
  overflow: hidden;
}

.ellipsis-4-line {
  max-height: 97px;
  display: block;
  overflow: hidden;
}

.ellipsis-5-line {
  max-height: 125px;
  display: block;
  overflow: hidden;
  @media only screen and (max-width: 767px) {
    max-height: 115px;
  }
}

.ellipsis-h5-2-line {
  max-height: 68px;
  display: block;
  overflow: hidden;
  width: 100%;
}

.ellipsis-h6-3-line {
  max-height: 75px;
  display: block;
  overflow: hidden;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    max-height: 69px;
  }
}

blockquote {
  position: relative;
  color: $paragraph-subtitle-color;
  margin: $margin $margin * 2.5;
  p {
    @include set-text($blockquote-size);
    line-height: $blockquote-line-height;
  }
  h4:before,
  h4:after {
    font-size: 13px;
    color: $link-color;
    font-family: 'icomoon' !important;
    top: -1px;
  }
  h4:before {
    position: absolute;
    content: '\e920';
    left: -15px;
  }
  h4:after {
    content: '\e921';
    margin-left: 10px;
    line-height: 0px;
    position: relative;
  }
  @media only screen and (max-width: 767px) {
    margin: $mobile-global-margin $margin * 1.5;
    p {
      @include set-text($blockquote-size-mobile);
      line-height: $blockquote-line-height-mobile;
    }
    :first-child:before,
    :first-child:after {
      top: 1px;
    }
  }
}

.circle {
  border-radius: 50% !important;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.max-container-width,
.col-16-width {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  float: none;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}

.mobile-margin {
  @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
    margin: 0 $margin * 1.5 $margin * 1.5;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 $mobile-global-margin $mobile-global-margin;
  }
}

.mobile-margin .col-20-width {
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
}

.access-icons {
  margin: 16px 0;
  i {
    font-size: 50px;
  }
  @media only screen and (max-width: 767px) {
    i {
      font-size: 50px / $mobile-ratio;
    }
  }
}

.blocker {
  position: relative;
  .heading {
    background: $link-color;
    color: #fff;
    text-transform: uppercase;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 21px;
    &.collapseable {
      position: relative;
      cursor: pointer;
      &:active {
        background: $link-color-hover;
      }
      .icon-RCT-down {
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -6px;
        display: inline-block;
        font-size: 12px;
        @include animate(0.3);
      }
    }
    @media only screen and (min-width: 768px) {
      padding: 13px 16px;
      font-size: 17px;
      line-height: 22px;
    }
  }
  .inner-content {
    overflow: hidden;
    .icon-RCT-search {
      font-size: 22px;
      position: relative;
      top: 4px;
      margin-right: 16px;
      display: inline-block;
      float: left;
    }
    a {
      .icon-RCT-arrow {
        font-size: 12px;
      }
    }
    min-height: 101px;
    background: $lighter-gray;
    @include button-padding();
    > *:first-child {
      margin-top: 0;
    }
    > *:last-child {
      margin-bottom: 0;
    }
    p {
      max-height: 72px;
      text-overflow: ellipsis;
      overflow: hidden;
      @media only screen and (max-width: 767px) {
        max-height: 63px;
      }
    }
  }
  &.open {
    p {
      max-height: none;
      text-overflow: none;
      display: block;
    }
    .icon-RCT-down {
      @include transform(rotate(180deg));
    }
  }
}

// Chosen overrides This should be reviewed once the legacy files have been reviewed / removed.

.filter-options > a {
  @media screen and (max-width: 767px) {
    padding: 11px 0;
  }
}

.input-cross > a {
  @media screen and (max-width: 767px) {
    min-height: 45px;
  }
}

.chosen-container .chosen-container-single a.chosen-single {
  @media screen and (max-width: 767px) {
    line-height: 21px !important;
  }
}

.share-direct-link {
  position: absolute;
  right: 20px;
  top: 6px;
  padding: 10px;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  line-height: normal;

  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    width: 100%;
  }

}
