@import '../../../animate';
@import '../../../vars';

.half-height-hero-image {
  text-align: center;
  background: $lighter-gray;
  h5,
  .tag {
    margin-bottom: 5px;
  }
  img {
    display: block;
    width: 100%;
  }
  .text {
    .subtitle {
      margin-bottom: 0;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }
}
