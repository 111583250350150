@import '../../../animate';
@import '../../../vars';

.on-this-page {
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }
  &__container {
    text-align: center;
    a {
      display: inline-block;
      text-transform: uppercase;
      font-size: 17px;
      line-height: 21px;
      margin: 0 10px 5px 10px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}
