.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes,
.page-whatson .region-content,
.page-schools .region-content,
.page-collection-search .region-content,
.page-collection-near-you .region-content,
.node-type-collection-landing-page .region-content,
.node-type-location .region-content,
.node-type-people .region-content {
  /*Whats on filters ----------------------------------------------------------- */

  .left-side-bar-wrapper {
    background: #fff;
    padding: 16px;
    margin-bottom: 16px;
    h3 {
      font-family: $paragraph-font;
      font-size: 16px;
      line-height: 21px;
      text-transform: uppercase;
      a {
        display: block;
      }
    }
    @media only screen and (max-width: 991px) {
      h3 {
        margin-bottom: 0;
      }
    }
    @media only screen and (min-width: 992px) {
      margin-bottom: 30px;
      h3 {
        font-size: 17px;
        line-height: 21px;
      }
      .collapse {
        display: block;
      }
      .list-unstyled.collapse {
        display: none;
        &.in {
          display: block !important;
          border-bottom: 1px solid #fff;
          padding-bottom: 10px;
          background: #f8f8f8;
        }
      }
    }
  }

  .left-sb-facet,
  .left-sb-dropdown {
    > li {
      position: relative;
      overflow: hidden;
      a {
        display: block;
        padding: 14px 14px 14px 0;
        font-size: 17px;
        line-height: 21px;
        @media only screen and (max-width: 767px) {
          font-size: 16px;
        }
        .caret {
          @include transform(rotate(0deg));
          transition: all 0.3s ease-out;
          height: 10px;
          width: 15px;
          right: 15px;
          top: 19px;
        }
        .cross-ani-wrapper {
          position: absolute;
          background: $link-color;
          width: 46px;
          height: 49px;
          top: 0;
          left: -46px;
          font-size: 12px;
          transition: all 0.3s ease-out;
          .cross-ani-icon {
            position: absolute;
            display: block;
            padding: 3px 2px;
            top: 11px;
            left: 12px;
            font-size: 20px;
            @include icomoon;
            transition: all 0.3s 0.1s ease-in-out;
            &:before {
              content: '\e929';
              color: #fff;
            }
          }
        }
        &.active,
        &:hover {
          background: #f4f4f4;
          padding: 14px 14px 14px 60px;
          .cross-ani-wrapper {
            top: 0;
            left: 0;
          }
        }
        &.active {
          border-bottom: 1px solid white;
          color: #222;
          .caret {
            @include transform(rotate(180deg));
          }
        }
        &.cross-ani-draw.active .cross-ani-icon {
          @include transform(rotate(45deg));
        }
      }
    }
    li ul {
      li {
        position: relative;
        a,
        a:hover,
        a:focus,
        a.active {
          background: #f8f8f8;
          padding: 14px 14px 7px 45px;
          font-size: 15px;
          line-height: 19px;
          @media only screen and (max-width: 767px) {
            font-size: 13px;
            line-height: 17px;
          }
          border-bottom: none;
          color: $link-color;
          .cross-ani-wrapper {
            width: 20px;
            height: 20px;
            border: 1px solid $link-color;
            border-radius: 3px;
            background: transparent;
            top: 13px;
            left: 14px;
            .cross-ani-icon {
              top: 0;
              left: 0;
              font-size: 13px;
              &:before {
                content: ' ';
              }
            }
          }
        }
      }
    }
    li ul li a.active {
      .cross-ani-wrapper {
        .cross-ani-icon {
          &:before {
            content: '\e92d';
            color: $link-color;
          }
        }
      }
      &.cross-ani-draw.active .cross-ani-icon {
        @include transform(rotate(0deg));
      }
    }
  }

  .left-sb-facet {
    .facet-draw {
      .facet-icon {
        background: $link-color;
        width: 46px;
        height: 49px;
        @include icomoon;
        position: absolute;
        top: 0;
        left: -46px;
        transition: all 0.3s 0.1s ease-in-out;
        &:before {
          content: '\e929';
          color: #fff;
          position: absolute;
          top: 16px;
          left: 14px;
          font-size: 19px;
        }
        svg {
          display: none;
        }
      }
      &.active,
      &:hover,
      &:focus {
        .facet-icon {
          left: 0;
        }
      }
      &.active {
        .facet-icon {
          &:before {
            @include transform(rotate(45deg));
          }
        }
      }
    }
  }

  #sb-when .acc-open a {
    background: #f4f4f4;
    padding: 14px 14px 14px 60px;
    border-bottom: 1px solid white;
    color: #222;
    .cross-ani-wrapper {
      left: 0;
    }
  }

  .left-sb-dropdown {
    .collapse {
      display: none;
      &.in {
        display: block;
      }
    }
    .acc-open a {
      background: #f8f8f8;
    }
  }

  .side-inner {
    .caret {
      @include icomoon;
      border: none;
      font-size: 12px;
      position: absolute;
      right: 27px;
      top: 21px;
      text-align: center;
      &:before {
        content: '\e913';
        color: $link-color;
      }
    }
  }

  /* Mobile filters */

  @media only screen and (max-width: 991px) {
    .refine-wrapper {
      position: relative;
      z-index: 2;
      .refine-btn {
        font-family: $paragraph-font;
        height: auto;
        font-size: 17px;
        text-transform: uppercase;
        margin: 0 -30px 0 0;
        padding: 12px 35px 12px 16px;
        float: right;
        border: none;
        line-height: 22px;
        border-radius: 3px 0px 0px 3px;
        br {
          display: none;
        }
        span {
          background: $link-color;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin: 0 auto;
          color: #fff;
        }
        &:hover,
        &:focus {
          span {
            background: $link-color-hover;
          }
        }
      }
      .media-body {
        overflow: visible;
        float: left;
        display: table;
        width: 100%;
        margin: 16px 0;
        ul {
          display: table-row;
          text-transform: uppercase;
          font-size: 17px;
          text-align: center;
          li {
            &:first-child {
              border-left: 1px solid #8a2a2b;
            }
            display: table-cell;
            border-right: 1px solid #8a2a2b;
            .badge {
              display: block;
              background: $link-color;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin: 0 auto;
              color: #fff;
              line-height: 21px;
            }
          }
        }
      }
    }

    .collapsing {
      position: relative;
      overflow: hidden;
      -webkit-transition: height 0.35s ease;
      -o-transition: height 0.35s ease;
      transition: height 0.35s ease;
    }

    .side-collapse {
      position: fixed;
      background: #f4f4f4;
      box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.2);
      padding: 5px 16px 16px 16px;
      top: 0px;
      bottom: 0;
      right: 0;
      width: 50%;
      overflow: hidden;
      transition: width 0.4s;
      overflow-y: scroll;
      z-index: 10000;
      &.in {
        box-shadow: none;
      }
      .close-btn {
        position: absolute;
        right: 16px;
        color: #333;
        .icon-cross {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          font-size: 15px;
          &:before {
            content: '\e92c';
            color: #fff;
            top: 10px;
            position: absolute;
          }
        }
      }
      h3 {
        position: relative;
        margin-bottom: 0;
        .caret {
          padding: 0;
          background: transparent;
          min-height: auto;
          top: 5px;
          right: 12px;
          &:before {
            color: $link-color;
          }
        }
      }
      .panel-collapse {
        margin-top: 16px;
      }
      .side-inner {
        margin-top: 48px;
        .btn {
          width: 100%;
        }
        .btn.icon-search {
          margin: 0;
        }
      }
      &.in {
        width: 0;
        padding: 0;
        border-right: none;
      }
      .collapse {
        display: none;
        &.in {
          display: block;
        }
      }
      .collection-divider {
        margin: 0 0 16px;
      }
      .breadcrumb {
        background: #fff;
      }
      .search-controls {
        background: transparent;
        margin: 0 16px;
        width: 100%;
        .col-xs-6 {
          width: 100%;
        }
      }
      .chosen-container-single.chosen-container-active .chosen-drop,
      .dropdown-menu {
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15) !important;
      }
      .dropdown-menu.inner {
        box-shadow: none !important;
      }
      .checkbox-tick-list {
        padding: 0 5px;
        background: transparent;
        margin: 0;
        .facet-name {
          padding: 4px 0 0 30px;
        }
        .active-facet {
          a {
            min-height: auto;
          }
        }
      }
    }

    .caret {
      min-height: 51px;
    }
  }

  @media only screen and (max-width: 767px) {
    .side-collapse {
      width: calc(100% - 50px);
    }
    .refine-wrapper {
      .media-body {
        ul {
          font-size: 15px;
        }
      }
      .refine-btn {
        margin: 0 -16px 0 0;
        padding: 12px 35px 12px 16px;
        line-height: 20px;
        font-size: 16px;
      }
    }
  }
}
