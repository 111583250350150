@import '../../../animate';
@import '../../../vars';

.publication-details-section {
  border-bottom: 1px solid $hr-border;

  .contributors {
    display: block;
    > span {
      font-family: $paragraph-font-bold;
    }
    @include set-text(19px);
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text(16px);
    }
  }

  .pages {
    color: $paragraph-subtitle-color;
    i {
      position: relative;
      top: 2px;
      &:before {
        color: $paragraph-subtitle-color;
      }
    }
  }
}
