@import '../../../animate';
@import '../../../vars';

$paragraph-essential-information-modals-desktop: 1300px;
$paragraph-essential-information-modals-mobile: 768px;

.paragraph-essential-information-modals {
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
    @media screen and (max-width: 767px) {
      margin-left: -8px;
      margin-right: -8px;
    }
  }

  &__button {
    width: 100%;
    height: 100%;
    i {
      display: block;
      margin-bottom: 10px;
      font-size: 24px;
    }
  }

  &__item {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 10px;
    @media screen and (max-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 8px;
    }
    &:nth-of-type(1) {
      order: 1;
      @media screen and (max-width: 767px) {
        padding-bottom: 16px;
      }
    }
    &:nth-of-type(2) {
      order: 2;
      @media screen and (max-width: 767px) {
        padding-bottom: 16px;
      }
    }
    &:nth-of-type(3) {
      order: 4;
    }
    &:nth-of-type(4) {
      order: 3;
    }
  }

  &__modal-window {
    display: none;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__modal {
    position: absolute;
    overflow: auto;
    width: 1010px;
    max-width: 100%;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    @media (max-width: $paragraph-essential-information-modals-desktop) {
      width: 90%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__container {
    padding-top: $margin * 1.5;
    margin-bottom: $margin * 1.5;
    @media screen and (max-width: 767px) {
      padding-top: $margin * 0.8;
      margin-bottom: $margin * 0.8;
    }

    ol {
      padding-left: 20px;
    }
    ul {
      padding-left: 20px;
      margin: 1em 0;
      li {
        list-style-type: disc;
      }
    }
  }

  &__title-container {
    padding-top: 10px;
    margin-bottom: $margin * 2;
    @media screen and (max-width: 767px) {
      padding-top: 0;
      margin-bottom: $margin * 0.8;
    }
  }

  &__title,
  &__tagline {
    text-align: center;
    text-transform: none;
  }

  &__button-close {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background: $link-color;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    &:hover {
      cursor: pointer;
      background: $link-color-hover;
    }
  }

  .location-info {
    @media screen and (max-width: 1299px) {
      margin: 0 -30px;
    }
    @media screen and (max-width: 767px) {
      margin: 0 -15px;
      &__block {
        padding: 0 16px;
      }
    }
    &__map-direction-info {
      @media screen and (min-width: 768px) {
        padding: 30px 0 0 0;
      }
    }
  }

  &__lower-container {
    background-color: $lighter-gray;
    padding: 30px 0 18px 0;
    @media screen and (max-width: 1300px) {
      .mobile-margin {
        margin-bottom: 0;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 16px 0 4px 0;
    }
  }

  &__lower-text {
    text-align: center;
  }

  &__logo,
  &__gift-aid-logo {
    text-align: center;
  }

  &__logo {
    margin-bottom: 12px;
  }
}

