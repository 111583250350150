@import '../../../animate';
@import '../../../vars';

.exhibition-detail-block-wrapper {
  .blocker .inner-content p {
    span {
      max-height: 34px;
    }
  }
  .blocker.open p {
    max-height: none;
    text-overflow: none;
    display: block;
  }
  .blocker {
    .inner-content {
      min-height: 0;
      padding: 16px;
      background: #f4f4f4;
      @media only screen and (min-width: 768px) {
        padding: $margin;
        .ellipsis {
          width: calc(100% - 200px);
        }
      }
      i {
        margin-right: 16px;
      }
    }

    .other-venues {
      display: none;
      background: #ececec;
      padding: 20px 20px 20px 50px;
    }
  }
}

.accordion {
  &__button {
    background: #ececec;
    padding: 22px 45px 21px 20px;
    display: block;
    cursor: pointer;
    @include set-text($tag-size);
    line-height: 21px;
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
      margin-bottom: 0;
      padding: 16px 45px 16px 16px;
    }
    .icon-RCT-down {
      position: absolute;
      right: 16px;
      top: 27px;
      margin-top: -6px;
      display: inline-block;
      font-size: 12px;
      transition: all 0.3s ease;
    }
    &.active {
      .icon-RCT-down {
        @include transform(rotate(180deg));
      }
    }
    @media only screen and (min-width: 768px) {
      position: absolute;
      right: 0;
      top: 0;
      padding: 22px 45px 21px 20px;
      border-left: 2px solid #fff;
      .icon-RCT-down {
        top: 50%;
      }
    }
  }
  &__content {
    a {
      display: block;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    &__inner {
      position: relative;
      &.selected {
        .other-venues {
          padding: 0 16px 16px 46px;
        }
      }
    }
  }
}

.exhibition-detail-block {
  background: $lighter-gray;
  padding: $margin $margin 32px $margin;
  border-top: 2px solid #fff;
  @media only screen and (max-width: 767px) {
  }
  .close {
    display: none;
  }
  .btn.button-outline {
    i {
      position: relative;
      top: 1px;
    }
  }
  .access-icons {
    i {
      color: $link-color;
    }
  }
  .list-icons {
    li {
      margin-bottom: 16px;
      padding-left: 30px;
      position: relative;
      i {
        position: absolute;
        left: 0;
        top: 2px;
      }
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
  }
  .info {
    background: #f4ebeb;
    margin: 16px 0;
    > * {
      margin-bottom: 0;
    }
  }
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 16px;
    .info {
      padding: 16px 16px 32px 16px;
    }
    .btn.button-outline {
      text-align: center;
      display: block;
      width: 100%;
    }
  }

  .tooltip.in {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $link-color;
    border-radius: 0;
  }

  .tooltip-arrow {
    border-top-color: $link-color !important;
  }
}
.accordion-inside {
  display: none;
}
