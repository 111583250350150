@import '../../../animate';
@import '../../../vars';

.menu-block {
  .navigation {
    background: #f4f4f4;
    > .mobile-accordion > label {
      text-transform: uppercase;
    }
  }
  a {
    color: $titles-color;
  }
  form {
    margin: 0px;
    position: relative;
    background: #f4f4f4;
    padding: 10px;
    margin-top: -2px;
    border-top: 1px solid #fff;
    z-index: 60;
    label {
      display: none;
    }
    input {
      width: calc(100% - 47px);
      margin-bottom: 0;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      height: 54px;
      @media (max-width: 767px) {
        width: calc(100% - 45px);
        height: 48px;
      }
    }
    button {
      position: absolute;
      right: 10px;
      top: 10px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      z-index: 2;
      height: 54px;
      @media (max-width: 767px) {
        line-height: 16.8px;
        height: 48px;
      }
      i {
        font-size: 19px;
        position: relative;
        top: 2px;
        @media (max-width: 767px) {
          top: 1px;
        }
      }
    }
  }
  .mobile-accordion {
    width: 100%;
    position: relative;
    cursor: pointer;
    background: $link-color;
    line-height: 22px;
    @include button-padding();
    label,
    i {
      color: #fff;
      margin: 0;
    }
    i.header-icon {
      margin-top: -13px;
    }
    > label {
      position: relative;
      cursor: pointer;
    }
    .icon-RCT-down {
      position: absolute;
      right: 0px;
      top: 25%;
      padding: 20px;
      margin-top: -7px;
      font-size: 12px;
      @include animate(0.3);
    }
    &-nested .icon-RCT-down {
      margin-top: 0;
      top: 0;
      padding: 20px 19px;
    }
    .icon-RCT-down.open {
      @include transform(rotate(180deg));
    }
  }
  .mobile-accordion-content {
    overflow: hidden;
    max-height: 0;
    width: 100%;
    transition: max-height 0.4s ease-out;
  }
  @media (min-width: 768px) {
    .navigation {
      position: relative;
      > .mobile-accordion-content {
        background: #f4f4f4;
        position: absolute;
        z-index: 5;
      }
    }
  }
  .mobile-accordion-open {
    a {
      font-family: $paragraph-font-bold;
    }
  }
  .mobile-accordion-open ~ .mobile-accordion-content {
    max-height: 3000px;
    height: auto;
    transition: max-height 0.4s ease-in;
  }
  a.active {
    font-family: $paragraph-font-bold;
  }
}

.accordion-secondary {
  .mobile-accordion {
    position: relative;
    cursor: pointer;
    background: #f4f4f4;
    cursor: pointer;
    label {
      color: $titles-color;
      width: calc(100% - 45px);
      display: inline-block;
      cursor: pointer;
    }
    &:before {
      content: ' ';
      display: block;
      background: $secondary-menu-bg;
      width: 50px;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      z-index: 0;
    }
    i {
      color: $titles-color;
    }
  }
  .mobile-accordion-content {
    height: auto;
  }
}
