@import '../../../animate';
@import '../../../vars';

.group-search-header {
  margin-bottom: $margin * 1.5;

  @media only screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  &__title {
    margin: 40px 0;
    @media only screen and (max-width: 767px) {
      margin: 16px;
    }
    .searchTitle {
      font-size: $margin * 1.5;
      margin-bottom: 0px;
      line-height: 36px;
    }
    p {
      color: $paragraph-subtitle-color;
      span {
        font-family: $paragraph-font-bold;
      }
    }
  }

  &__search-box {
    .rct-theme-search-in-publication-search-form {
      .form-type-textfield {
        display: table-cell;
        vertical-align: top;
        width: 100%;

        .form-search-field {
          display: block;
          width: 100%;
          -webkit-border-top-left-radius: 3px;
          -webkit-border-bottom-left-radius: 3px;
          -moz-border-radius-topleft: 3px;
          -moz-border-radius-bottomleft: 3px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          font-size: 17px;
          line-height: 22px;
          padding: 12px 15px;
          @media only screen and (min-width: 768px) {
            height: 50px;
          }
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 21px;
            padding: 11px 14px;
          }
        }
      }

      .form-search-submit {
        vertical-align: top;

        .form-submit {
          font-size: 19px;
          -webkit-border-top-left-radius: 0px;
          -webkit-border-bottom-left-radius: 0px;
          -moz-border-radius-topleft: 0px;
          -moz-border-radius-bottomleft: 0px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          @media only screen and (max-width: 767px) {
            padding: 11px 14px;
          }
        }
      }
    }
  }

  &__the-filters {
    margin-top: $margin * 1.5;
    @media only screen and (max-width: 767px) {
      margin-top: $margin * 0.8;
    }

    ul {
      display: block;
      overflow: hidden;

      .filters {
        cursor: pointer;

        .inner {
          background: $lighter-gray;
          color: $link-color;
          font-size: 17px;
          font-family: $paragraph-font;
          padding: $margin * 0.8;
          text-transform: inherit;
          text-align: left;
          line-height: 21px;
          width: 100%;
          @media only screen and (max-width: 767px) {
            font-size: 14px;
            line-height: 20px;
          }

          .tick {
            border: 1px solid $link-color;
            border-radius: 3px;
            display: block;
            float: left;
            height: 20px;
            margin-right: 10px;
            width: 20px;

            &:before {
              display: none;
            }
            &.active {
              &:before {
                display: block;
                font-size: 13px;
                padding: 3px 1px;
              }
            }
          }
        }
      }
    }
  }
}
