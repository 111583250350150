@import '../../../animate';
@import '../../../vars';

.paragraph-related-content-two-item {
  .mobile-margin {
    margin-bottom: $margin * 1.5;
  }

  &__container {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
}
