@import '../../animate';
@import '../../vars';

// Split style sheets.
// -- Breadcrumb
@import './breadcrumb/breadcrumb.scss';
// -- Map
@import './map/map.scss';
@import './map/modal.scss';
@import './map/tabs.scss';
// -- Page
@import './page/detailsPage.scss';
@import './page/peoplePage.scss';
// -- Pagination
@import './pagination/pagination.scss';
// -- Search
@import './search/facets.scss';
@import './search/filters.scss';
@import './search/navTabs.scss';
@import './search/search.scss';
@import './search/searchControls.scss';
@import './search/searchViewOptions.scss';
@import './search/tabs.scss';
// -- Whats on
@import './whatsOn/filters.scss';
@import './whatsOn/controls.scss';

.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes,
.page-whatson .region-content,
.page-schools .region-content,
.page-collection-search .region-content,
.page-collection-near-you .region-content,
.node-type-collection-landing-page .region-content,
.node-type-location .region-content,
.node-type-people .region-content {
  .icon-search.btn:hover {
    background: $link-color-hover;
  }

  .nav-tabs {
    border-bottom: none;
    li a {
      @include set-text($paragraph-size);
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 0 !important;
      span {
        @include icomoon;
      }
      @media only screen and (max-width: $mobile-breakpoint) {
        @include set-text($paragraph-size-mobile);
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  @media (min-width: 768px) {
    #cny-tabs {
      display: flex;
      > div {
        display: flex;
        flex: 1 0 auto;
        float: none;
        width: auto !important;
        a {
          flex: 1 0 auto;
        }
      }
    }
  }

  .location-wrapper,
  .loading-people-objects .container {
    padding-top: 16px;
    @media only screen and (min-width: 768px) {
      padding-top: 30px;
    }
  }

  .object-tabs {
    .nav-tabs {
      width: 25%;
      float: left;
      background: #fff;
      margin-bottom: 30px;
      padding: 16px 0 16px 16px;
      li a {
        span {
          font-family: $paragraph-font !important;
        }
      }
    }
    .tab-pane {
      width: 75%;
      float: left;
      margin-bottom: 30px;
      padding: 20px;
      min-height: 330px;
      position: relative;
    }
    h4 {
      margin-bottom: 10px;
    }
    .nav-tabs > li {
      float: none;
      cursor: pointer;
      width: 100%;
      &.active {
        cursor: default;
      }
      > a {
        width: 100%;
        border: none;
        padding: 14px 30px 14px 0;
        &:before {
          @include icomoon;
          content: '\e902';
          opacity: 0;
          transform: translate(-15px, 0);
          transition: all 0.2s ease;
          position: absolute;
          right: 16px;
          font-size: 12px;
          top: 20px;
        }
      }
    }
    .nav-tabs > li a:hover:before,
    .nav-tabs > li.active a:before {
      opacity: 1;
      transform: translate(0, 0);
    }
    .tab-content > .active {
      display: block !important;
    }
    #load-more {
      margin-bottom: 16px;
      @media only screen and (min-width: 768px) {
        margin-bottom: 30px;
      }
    }
  }

  .object-tabs .nav-tabs li.active a,
  .object-tabs .nav-tabs > li > a:hover,
  .object-tabs .nav-tabs > li > a:focus {
    color: $paragraph-body-color;
    background-color: #f8f8f8;
    padding: 14px 30px 14px 14px;
  }

  .object-tabs .nav-tabs > li.active > a {
    border-right: none !important;
  }

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .object-tabs .nav-tabs > li > a {
      border-bottom: 2px solid #afcbe0;
    }
  }

  @media only screen and (min-width: 768px) {
    .object-tabs .tab-pane {
      padding: 0 0 0 20px;
    }
  }

  .section-title-strapline,
  .section-title h1 {
    text-align: center;
    margin-bottom: 10px;
  }

  .tab-pane,
  .search-controls,
  .filter-options {
    padding: 0 0 30px 0;
    @media only screen and (max-width: 767px) {
      padding: 0 0 16px 0;
    }
  }

  .tabs-wrapper form {
    margin-top: -4px;
    margin-right: -4px;
  }

  .search-controls {
    padding-top: 0 !important;
  }

  .filter-options {
    a {
      width: 100%;
      text-align: center;
    }
  }

  .chosen-container,
  .category-list {
    @include set-text($tag-size);
    line-height: 21px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  .tab-pane,
  .search-controls {
    padding: $margin * 1.5 0;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding: $mobile-global-margin 0;
    }
  }

  .icon-cross:before {
    content: '\e92c';
    font-size: 13px;
  }

  .filter-options {
    padding-bottom: $margin * 1.5;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding-bottom: $mobile-global-margin;
    }
  }

  .search-controls {
    padding-top: 0 !important;
  }

  .filter-options {
    a {
      width: 100%;
      text-align: center;
    }
  }

  .icon-filter-empty,
  .icon-filter-full {
    font-size: 14px;
    padding-right: 10px;
  }

  .nav-tabs .icon-filter-empty,
  .nav-tabs .icon-filter-full {
    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  .icon-filter-empty:before {
    content: '\e92b';
  }

  .icon-filter-full:before {
    content: '\e928';
  }

  hr {
    border-top: 1px solid #f4f4f4;
  }

  // forms

  input {
    margin-bottom: 0;

    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
    }

    &:focus {
      outline: 0 !important;
      outline: none !important;
    }
  }

  .dropdown-menu {
    > li > a:hover,
    > li > a:focus {
      outline: 0;
      outline: none;
    }
    @include set-text($tag-size);
    line-height: 21px;
    margin-bottom: none;
    @media only screen and (max-width: 767px) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
      margin-bottom: none;
    }
  }

  .bootstrap-select .dropdown-toggle:focus {
    outline: 0;
    outline: none !important;
  }
  .hero-search .refine-wrapper,
  .search-controls {
    h3 {
      font-family: $paragraph-font-bold;
      .small {
        font-family: $paragraph-font;
      }
    }
    h3,
    .small {
      font-size: 16px;
      line-height: 21px;
    }
    @media only screen and (min-width: 768px) {
      h3,
      .small {
        padding-top: 13px;
        font-size: 19px;
        line-height: 24px;
      }
    }
    .bootstrap-select {
      width: 100% !important;
    }
    .bootstrap-select.btn-group .dropdown-toggle .caret {
      width: auto;
      height: auto;
      font-size: 12px;
      margin-top: -7px;
    }
    .caret {
      @include icomoon;
      border: none;
      &:before {
        content: '\e913';
      }
    }
  }

  .region-content .btn.btn-sm {
    padding: 15px;
  }

  .m-t-md {
    margin-top: 0;
  }

  .m-b-md {
    margin-bottom: 0;
  }

  // helper

  .dropdown-menu.helper {
    display: block !important;
    padding: 15px !important;
    img {
      float: left;
      width: 12px;
      position: relative;
      top: 13px;
      margin-left: 7px;
      opacity: 0.5;
    }
  }

  /* Location header */
  .edinburgh-theme > .location-header {
    h3,
    a,
    small {
      color: $edinburgh-color !important;
    }
  }

  .windsor-theme > .location-header {
    h3,
    a,
    small {
      color: $windsor-color !important;
    }
  }

  .london-theme > .location-header,
  .other-theme > .location-header {
    h3,
    a,
    small {
      color: $link-color !important;
    }
  }

  .location-header {
    margin-bottom: 4px;
    h3 {
      text-align: left;
    }
    @media only screen and (min-width: 768px) {
      margin-bottom: 18px;
      a {
        float: right;
        padding-top: 10px;
        i {
          @include icomoon;
          font-size: 12px;
          padding-left: 10px;
          &:before {
            content: '\e902';
          }
        }
      }
    }
  }

  .search-result-wrapper.mobile-white {
    background: transparent;
  }

  /* Collection theme */

  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search #search-themes {
    .search-controls .bootstrap-select.btn-group .dropdown-toggle .caret {
      margin-top: -3px;
      right: 15px;
    }
    .angular-typehead .input-group-btn {
      top: 0;
      right: 0;
    }
    .side-inner .caret:before {
      color: #fff;
    }
    .floated-breadcrumb {
      margin: 0 10px 0 0;
      @media only screen and (max-width: 991px) {
        padding: 0 0 16px 0;
      }
    }
    .search-controls {
      padding: 0;
      @media only screen and (max-width: 991px) {
        .hidden-xs {
          display: none;
        }
      }
    }
    .side-collapse .search-controls {
      @media only screen and (max-width: 991px) {
        margin: 0;
      }
    }
    .side-collapse .side-inner .btn,
    .side-collapse .search-controls .select-dropdown {
      margin: 0;
    }
    .search-controls .bootstrap-select {
      @media only screen and (max-width: 991px) {
        width: 100% !important;
      }
    }

    .floated-breadcrumb .breadcrumb li {
      @media only screen and (max-width: 767px) {
        line-height: 28px;
      }
      @media only screen and (min-width: 768px) {
        font-size: 17px;
        line-height: 21px;
      }
    }
    .floated-breadcrumb .breadcrumb li.active {
      color: #333;
    }

    @media only screen and (max-width: 991px) {
      .refine-wrapper .media-body {
        margin: 0;
        float: left;
        width: 50%;
      }
      .hero-search .refine-wrapper h3 {
        margin-bottom: 0;
      }
      .select-dropdown,
      .theme-search-form {
        margin-bottom: 16px;
      }

      .collection-filter {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 991px) {
      .hero-search .refine-wrapper h3 {
        margin-top: 12px;
      }
    }
  }

  .side-inner h3,
  .search-controls h3 {
    text-align: left;
  }

  // General global styles ------------------------------------------------

  @mixin legacy-full {
    .page-resources,
    .page-schools-resources,
    .page-collection-conservation,
    .page-collection-themes-search,
    .page-whatson .region-content,
    .page-schools .region-content,
    .page-collection-search .region-content,
    .page-collection-near-you .region-content,
    .node-type-collection-landing-page .region-content,
    .microsite-search {
      @content;
    }
  }

  @mixin legacy-pages {
    .page-resources,
    .page-schools-resources,
    .page-collection-conservation,
    .page-collection-themes-search,
    .page-whatson .region-content,
    .page-schools .region-content,
    .page-collection-search .region-content,
    .page-collection-near-you .region-content {
      @content;
    }
  }

  /* Chosen */

  .chosen-container {
    margin-bottom: 0;
    .chosen-results {
      color: $paragraph-body-color;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 5px 15px;
        line-height: 21px;
        &:hover,
        &.highlighted {
          background: $lighter-gray;
          color: $link-color;
        }
      }
    }
  }

  .chosen-select {
    @media only screen and (max-width: 767px) {
      width: 100%;
      padding: 7px 15px !important;
      border-color: #e9e9e9 !important;
      background: #fff;
      background-color: #fff;
      height: 52px;
      background-image: url(./select-caret.png);
      background-repeat: no-repeat;
      background-position: 96% 10px;
      margin-bottom: 10px;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }

  .chosen-container-single .chosen-search input[type='text'] {
    background: url(search-01.svg) no-repeat 97% 12px;
    background-size: 19px !important;
    font-size: 14px;
  }

  /* Date picker */
  .datepicker {
    &.dropdown-menu {
      box-shadow: none;
      min-width: 262px;
      margin-left: -26px;
    }
    table {
      width: 100%;
    }
  }

  .input-group .form-control:last-child,
  .input-daterange input:first-child,
  .input-daterange input:last-child {
    border-radius: 3px !important;
  }

  .input-group-addon {
    border: none;
    background: #f4f4f4;
    color: #333;
    padding: 6px 6px;
  }

  .datepicker th {
    font-family: $paragraph-font-bold;
  }

  .datepicker td {
    border-radius: 3px;
  }

  .datepicker table tr td.day.focused,
  .datepicker table tr td.day:hover {
    color: #999;
  }

  .datepicker table tr td.today:hover:hover .datepicker td:hover,
  .datepicker th:hover,
  .datepicker table tr td.today:hover,
  .datepicker .datepicker-switch:hover,
  .datepicker .next:hover,
  .datepicker .prev:hover,
  .datepicker tfoot tr th:hover {
    background: $link-color;
    background-color: $link-color;
    color: #fff;
  }

  .datepicker td.day,
  .datepicker table tr td.today,
  .datepicker table tr td.today.disabled {
    background: #f4f4f4;
  }

  .datepicker table tr td.disabled,
  .datepicker table tr td.new,
  .datepicker table tr td.old {
    background: white;
    color: #d0d0d0;
  }

  .datepicker table tr td.active.active,
  .datepicker table tr td.active:active,
  .datepicker table tr td.active:hover,
  .datepicker table tr td.active:hover.active,
  .datepicker table tr td.active:hover:active,
  .datepicker table tr td.active:hover:hover,
  .datepicker table tr td.day.focused,
  .datepicker table tr td.day:hover {
    background: $link-color;
    color: white;
    text-shadow: none;
  }

  .datepicker table tr td.today:hover,
  .datepicker table tr td.today:hover:hover {
    background: #cd9696;
  }

  .datepicker table tr td.new:hover {
    background: white;
  }

  li.date-controls {
    padding: 15px;
    background: #f4f4f4;
    .input-group {
      margin-bottom: 12px;
    }
    .btn {
      margin: 0;
    }
    .input-sm {
      font-size: 15px;
    }
  }

  #active-filter {
    .facet-list,
    .facet-list {
      margin-bottom: 0;
    }

    .active-facet {
      margin-bottom: $margin;
      @media only screen and (max-width: 767px) {
        margin-bottom: $mobile-global-margin;
      }
    }

    .select-facet {
      left: 10px;
    }
  }

  .popover {
    background: $link-color;
    border-radius: 0;
    border: none;
    box-shadow: none;
    .popover-content {
      min-width: 100px;
    }
    span {
      display: block;
      margin-bottom: 12px;
      cursor: pointer;
      border-bottom: 1px solid $link-color;
      &:hover {
        border-bottom: 1px solid white;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    small {
      color: #fff;
      font-size: 17px;
      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
    &.top > .arrow {
      border-top-color: $link-color;
      &:after {
        border-top-color: $link-color;
      }
    }
    i {
      @include icomoon;
      font-size: 15px;
      position: relative;
      color: white;
      width: 23px;
      text-align: center;
      display: inline-block;
    }
    .icon-facebook:before {
      content: '\e908';
    }
    .icon-twitter:before {
      content: '\e906';
    }
    .glyphicon-envelope:before {
      content: '\e935';
    }
  }

  .node-type-location .region-content,
  .node-type-people .region-content {
    .object-tab-listing .search-view {
      margin-bottom: 10px;
    }

    .title-section {
      margin-bottom: 30px;
      .btn {
        display: inline-block;
        width: auto;
      }
    }
    @media only screen and (max-width: 767px) {
      .title-section {
        margin-bottom: 16px;
        padding: 0;
      }

      .grey-background {
        background: white;
      }
    }

    .object-accordion {
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 0px;
      }
      .panel-collapse {
        background: transparent;
      }
      .panel-body {
        padding: 16px 0;
      }
      > ul {
        border-top: 1px solid #d0d0d0;
        > li {
          position: relative;
          border-bottom: 1px solid #d0d0d0;
          > a {
            font-family: $titles-font;
            color: $link-color;
            font-size: 20px;
            line-height: 23px;
            padding: 16px 20px 0 0;
            border-bottom: none;
            .accordion-triangle {
              @include icomoon;
              font-size: 12px;
              border: none !important;
              top: 15px;
              right: 5px;
              transition: all 0.3s ease;
              transform: rotate(180deg);
              width: 12px;
              height: 12px;
              &:before {
                content: '\E913';
              }
            }
            &.collapsed {
              background: transparent;
              padding: 16px 20px 16px 0;
              .accordion-triangle {
                transform: rotate(0deg);
              }
            }
          }
        }
      }
      .tab-controls .btn {
        @media only screen and (max-width: 767px) {
          margin-bottom: 16px;
        }
      }

      .white {
        h3,
        .hide-tab {
          display: none;
        }
      }
    }
    .all-objects {
      .tab-controls .btn {
        @media only screen and (max-width: 767px) {
          margin-bottom: 16px;
        }
      }
      .checkbox-tick-list {
        li {
          @media only screen and (max-width: 767px) {
            position: absolute;
          }
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      float: left;
      min-width: 100%;
      padding: 0px;
      margin: 0;
      list-style: none;
      text-align: left;
      background-color: $gray;
      background-clip: padding-box;
      border: none;
      box-shadow: none;
      width: 100%;
      > li > a {
        padding: 5px 15px 5px 15px;

        white-space: inherit;
        &:hover,
        &:focus {
          background: $lighter-gray;
          color: $link-color;
          small {
            color: #fff;
          }
        }
      }
      li a.disabled {
        border-bottom: none;
        font-size: 19px;
        line-height: 25px;
        padding-top: 12px; // aw amend
        color: #333;
        font-family: $paragraph-font-bold;
        &:hover,
        &:focus {
          background-color: $gray;
          color: #f4f4f4;
          font-family: $paragraph-font-bold;
        }
        @media only screen and (max-width: 991px) {
          font-size: 16px;
          line-height: 21px;
        }
      }
    }

    .dropdown-menu > .active > a,
    .dropdown-menu > .active > a:hover,
    .dropdown-menu > .active > a:focus {
      background: $lighter-gray;
      color: $link-color;
      small {
        color: $link-color;
      }
    }

    .open > .dropdown-menu {
      display: block !important;
    }

    .search-view {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        float: right;
        li {
          float: left;
          a {
            background: #f4f4f4;
            display: block;
            padding-top: 14px;
            border: 1px #8a2a2b solid;
            width: 50px;
            height: 50px;
            text-align: center;
            color: #8a2a2b;
            font-size: 19px;
            border-radius: 50%;
            margin-left: 10px;
            &:hover,
            &:focus {
              background: #8a2a2b;
              color: #fff;
            }
          }
          &.active a {
            background: #8a2a2b;
            color: #fff;
          }
        }
      }
    }

    .checkbox-tick-list {
      margin-top: 12px;
      a {
        .facet-name {
          padding-left: 30px;
        }
        .select-facet {
          @include icomoon;
          &:before {
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 14px;
            left: 10px;
            border: 1px solid #8a2a2b;
            padding: 3px 1px;
            border-radius: 3px;
            content: '\E92D';
            font-size: 13px;
            @media only screen and (max-width: 991px) {
              top: 2px;
              left: 0px;
            }
          }
        }
      }
      @media (max-width: 767px) {
        a {
          display: block;
          .facet-name {
            padding-left: 24px;
          }
          .select-facet:before {
            top: -1px;
          }
        }
      }
    }

    .checkbox-tick-list .select-facet.remove-facet:before {
      content: ' ';
    }

    .nav-tabs li a {
      font-size: 17px;
      line-height: 21px;
    }

    .bootstrap-select.btn-group .dropdown-toggle .filter-option {
      width: auto;
      display: block;
    }

    .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
      width: 100%;
    }

    .tab-controls {
      margin-bottom: 16px;
      .btn {
        padding: 15px 28px 15px 15px;
        display: block;
      }
      .bootstrap-select.btn-group .dropdown-toggle .caret {
        font-size: 12px;
        border: none;
        @include icomoon;
        position: absolute;
        top: 21px;
        right: 37px;
        &:before {
          content: '\E913';
        }
      }
      span {
        overflow: visible !important;
        white-space: normal !important;
        text-overflow: initial !important;
      }
      @media (min-width: 768px) {
        margin-bottom: 30px;
      }
    }

    .loacation-description {
      img {
        width: 100%;
        margin-bottom: 12px;
      }
    }
    .hide-tab {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      background: transparent;
      color: #333;
      padding: 10px 30px 0 0;
      font-family: $paragraph-font;
      @media only screen and (max-width: 991px) {
        top: 50px;
      }
      .caret {
        @include icomoon;
        border: none;
        font-size: 12px;
        position: absolute;
        top: 15px;
        right: 16px;
        &:before {
          content: '\E914';
        }
      }
    }
    .tab-pane h3 {
      padding-right: 85px;
    }
  }

  .node-type-people .region-content {
    .col-sm-12 h3 {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 767px) {
      .col-sm-12 h3 {
        margin-bottom: 16px;
      }
    }

    .people-list .btn {
      margin-bottom: 30px;
    }

    .object-tab-listing button {
      margin-bottom: 16px;
    }
  }

  .icon-RCT-location:before {
    color: #737373 !important;
  }

  .page-collection .region-content {
    .search-results-cards__card-list {
      @media screen and (min-width: 768px) and (max-width: 1300px) {
        padding: 0px 30px;
      }
      @media screen and (min-width: 1301px) {
        padding: 0 105px;
      }
    }
  }

  /* collection object play button */

  .full-slide-show a {
    text-decoration: none;
  }

  .full-slide-show__icon-close {
    top: 30px;
  }

  #full-slide-show {
    .full-slide-show__icon-controls > a.glyphicon-pause:before {
      content: '\e944';
    }
    .card-expander {
      overflow: visible;
      position: static;
      background: rgba(00, 00, 00, 0.05);
      &__top {
        position: static;
        padding-bottom: 0;
        height: 70vh;
        img {
          height: 100%;
        }
      }
      &__image {
        position: static;
        a {
          height: 100%;
        }
      }
      &__content {
        position: static;
        background: transparent;
        height: auto;
        transform: none;
        .short-description {
          display: none;
        }
      }
      &__body-wrap {
        padding: 15px 0;
        background: #111;
        border-top: 1px solid #222;
        position: relative;
      }
      &__md-toggle {
        display: none;
      }
      &__initial {
        height: auto;
        padding: 0;
      }
      .card-collection-object-container__initial {
        text-align: left;
        padding: 0 170px 0 30px;
      }
      .list-icons li {
        color: #fff;
        margin-bottom: 5px;
        .icon-RCT-location:before {
          color: #fff !important;
        }
      }
      .card-expander__image a {
        display: none;
      }
      &__hidden {
        opacity: 1;
        border: 0;
        padding: 0 30px;
        .inner {
          text-align: left;
          border: none;
          padding: 0 140px 0 0;
        }
        .button {
          width: auto;
          align-self: left;
          position: absolute;
          right: 30px;
          top: 20px;
          color: #fff;
          text-decoration: none;
          background: #8a2a2b;
        }
      }
      &__image {
        &:hover {
          transform: none;
        }
      }
      &__placeholder {
        display: none;
      }
    }
  }

  .dropdown-menu > li > a {
    line-height: 21px;
  }

  .node-type-collection-landing-page .region-content .tab-pane {
    padding: 0;
  }

  .page-collection-search {
    .region-content .btn-default {
      .badge {
        color: $link-color;
        transition: all 0.3s ease-out;
      }

      &.button-outline {
        &:hover {
          .badge {
            color: $body-background;
          }
        }
      }
    }

    .region-content {
      .nav-tabs > {
        li {
          a {
            background-color: $brand-red;
            border-right: 1px solid $white;
            color: $white;

            &:hover {
              border-left-color: transparent;
              background-color: $link-color-hover;
              color: $white;
            }
          }

          &.active > {
            a {
              background: $white;
              color: $header-a-color;
              border: none;

              span {
                &:before {
                  color: $header-a-color;
                }
              }
            }

            #more-tab {
              border-right: 1px solid $brand-red;
            }
          }
        }
      }
    }
  }

  .page-collection-near-you {
    .region-content {
      #cny-tabs {
        a {
          // Default link styling.
          &,
          &:hover {
            background-color: $brand-red;
            border-right: 1px solid $white;

            &,
            h4,
            h4 small {
              color: $white !important;
            }
          }

          &:hover {
            background-color: $link-color-hover;
          }

          span {
            color: $white;
            border-color: $white;
          }

          // Active link styling.
          &.active {
            background-color: $white;
            border-right: 1px solid $brand-red;

            span {
              color: $header-a-color;
            }

            &,
            h4,
            h4 small {
              color: $header-a-color !important;
            }
          }
        }

        // Default info icon styling.
        .hover-info {
          .info-icon {
            color: $white;
            border-color: $white;
          }
        }

        // Active info icon styling.
        div.active {
          .hover-info {
            .info-icon {
              color: $header-a-color;
              border-color: $header-a-color;
            }

            &:hover {
              .info-icon {
                border-color: $brand-red;
              }
            }
          }
        }
      }
    }
  }

  @include legacy-full {
    .input-cross {
      .remove-filter {
        display: inline-block;
        position: absolute;
        background: $link-color;
        font-size: 17px;
        line-height: 22px;
        padding: 13px 16px;
        right: 8px;
        top: 0;
        text-align: center;
        min-height: 50px;
        cursor: pointer;
        @media only screen and (max-width: $mobile-breakpoint) {
          font-size: 16px;
          line-height: 21px;
          padding: 12px 15px;
          min-height: 48px;
        }
      }
    }

    #full-slide-show {
      display: none;
    }
  }

  @include legacy-pages {
    .page-resources #search-themes,
    .page-schools-resources #search-themes,
    .page-collection-conservation #search-themes,
    .page-collection-themes #search-themes,
    .page-collection-search .region-content .search-controller-the-search-area,
    .page-collection-near-you .region-content {
      .section-title-strapline,
      .section-title h1 {
        text-align: center;
        margin-bottom: 10px;
      }

      .page-collection-themes-search {
        .loader-cont {
          margin-top: -15px;
          right: 60px;
        }
      }

      .chosen-container-single .chosen-single {
        @media only screen and (max-width: 767px) {
          line-height: 21px !important;
        }
      }

      .input-cross a {
        @media only screen and (max-width: 767px) {
          min-height: 45px !important;
        }
      }

      .microsite-search {
        .container {
          .card-expander {
            &__image .inner img {
              @media only screen and (max-width: $mobile-breakpoint) {
                max-height: 90px;
              }
            }
          }
        }
      }
    }
  }

  .trigger-display {
    display: none;
  }

  .trigger-display.active {
    display: block;
  }

  .dropdown-toggle {
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .main-wrapper {
    .hero-text {
      h4 {
        text-transform: uppercase;
        font-size: 90%;
      }
      small {
        color: #fff;
      }
      p {
        margin-bottom: 0;
      }
    }
    .search-module {
      .bootstrap-select {
        margin-bottom: 0;
      }
      .dropdown-menu {
        max-height: 310px !important;
        a {
          padding-left: 25px !important;
        }
        dt {
          padding: 3px 15px;
        }
      }
      ul.dropdown-menu {
        padding-top: 5px !important;
      }
    }
    .person-title {
      h4 {
        margin-top: 8px;
      }
      h1 {
        font-size: 34px;
        margin-bottom: 14px;
        margin-top: 1px;
      }
    }
    .people-list {
      margin-top: 20px;

      .person {
        .list-image {
          padding: 0;
          height: 218px;
          width: 218px;
        }
        .list-description {
          height: auto !important;
          h2 {
            margin-bottom: -5px;
            margin-top: 5px;
            a {
              display: block;
            }
          }
        }
      }
    }

    .links {
      > ul li {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 0;
        &.disabled {
          display: none;
        }
        &:first-child {
          margin-top: 0;
        }
        .icon-location {
          margin-right: 2px;
        }
        a {
          font-weight: 100;
          font-size: 14px;
          i {
            font-size: 11px;
            margin-left: 1px;
            position: relative;
          }
        }
      }
    }
  }

  .hero-wrapper.top-hero.m-bottom {
    margin-bottom: 0px;
  }

  .view-collectors .dropdown-menu a {
    padding-left: 25px !important;
  }

  .bootstrap-select.btn-group .dropdown-menu,
  .bootstrap-select.btn-group .dropdown-menu.inner {
    max-width: 100%;
    span,
    a {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  #people-list .search-result .list-description,
  .people-list .search-result .list-description {
    height: auto !important;
    min-height: 200px !important;
  }

  .list-unstyled {
    > li.disabled a,
    > li.disabled span {
      display: none;
    }
  }

  #myTab {
    > li.disabled {
      display: none;
    }
  }

  .panel-body {
    padding: 20px;
  }

  /*
* Mobiles - Maximum width of 768 */
  @media (max-width: $screen-xs-max) {
    .links {
      h5,
      hr {
        display: none;
      }
    }
    .people-landing {
      .hero-text {
        p {
          margin-bottom: 0;
        }
      }
    }
    .location-wrapper {
      padding-bottom: 15px;
    }
    .search-module.links {
      background-color: transparent !important;
      padding: 0;
      hr,
      h5 {
        display: none;
      }
    }
    .search-result .list-description {
      min-height: 180px !important;
      height: 180px !important;
    }
    .object-accordion {
      margin: 0px 0 30px 0;
    }
  }

  /*
* Mobiles - Maximum width of 768 */
  @media (max-width: 480px) {
    .search-result .list-description {
      min-height: 115px !important;
      height: auto !important;
    }
  }

  /*
* Mobiles - Maximum width of 768 */
  @media (max-width: 767px) {
    #toggle-images {
      margin-left: 6px;
    }
  }

  /*
* Large screens - screen large than 1200 */
  @media (min-width: $screen-lg-min) {
    .object-tabs .tab-pane {
      padding: 20px 70px 30px 70px;
      margin-bottom: 40px;
    }
    .object-tab-listing .search-tile .list-image {
      width: 220px;
      height: 220px;
    }
  }
}
