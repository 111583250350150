@import 'vars';

html,
body {
  padding: 0;
  margin: 0;
  background: $body-background;
  max-width: 100% !important;
}

html {
  padding-top: 120px;
  @media only screen and (max-width: $menu-break) {
    padding-top: 70px;
  }
  @media only screen and (min-width: $menu-break) and (max-width: 1100px) {
    padding-top: 90px;
  }
}

img {
  max-width: 100%;
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.temp-remove-class {
  border: 1px solid #999;
  padding: 20px;
}
