@import '../../../animate';
@import '../../../vars';

.residence-intro {
  margin-bottom: 50px;
  @media (max-width: 767px) {
    margin-bottom: $margin * 0.2;
  }
  &__container {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 100%;
    }
    a {
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: $margin * 0.6;
      }
    }
  }

  &__price {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    margin-left: 20px;
    @media (max-width: 767px) {
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
      margin-left: 0;
    }

    p {
      margin-bottom: 0;
      @media (max-width: 767px) {
        margin-bottom: 12px;
      }
    }
  }

  ul,
  ol {
    padding-left: 20px;
  }
  ul {
    margin: 1em 0;
    li {
      list-style-type: disc;
    }
  }
}
