@import '../../../animate';
@import '../../../vars';

.card-media-new {
  @include card();
  h6 {
    max-height: 125px;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      max-height: 115px;
    }
  }
  .date {
    color: $paragraph-subtitle-color;
    font-size: 14px;
    max-height: 25px;
    width: 89%;
    @media only screen and (min-width: 768px) {
      bottom: 16px;
      font-size: 14px;
      position: absolute;
    }
    @media only screen and (max-width: 767px) {
      max-height: 21px;
    }
  }
}
