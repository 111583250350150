@import '../../../animate';
@import '../../../vars';

.paragraph-four-images-with-text-half {
  padding-top: $margin * 1.5;
  margin-bottom: $margin * 1.5;
  .text-center {
    margin-bottom: $margin * 1.5;
  }

  @media only screen and (max-width: 767px) {
    padding-top: $margin * 0.8;
    margin-bottom: $margin * 0.8;
  }

  h5 {
    @media only screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
    }
  }

  &__container {
    display: flex;
    padding-bottom: $margin * 1.5;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-bottom: $margin * 0.8;
    }
  }

  &__row {
    position: relative;
    display: flex;

    @media screen and (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    &:first-child {
      @media screen and (min-width: 768px) {
        padding-right: 10px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: $margin * 0.8;
      }
    }

    &:last-child {
      @media screen and (min-width: 768px) {
        padding-left: 10px;
      }
    }
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;

    @media screen and (max-width: 991px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      flex: 0 0 auto;
    }
  }

  &__image-wrapper {
    display: block;
    overflow: hidden;
  }

  &__image {
    @media screen and (min-width: 992px) {
      align-self: top;
    }

    a {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    img {
      width: 100%;
      display: block;
      border-radius: 3px 3px 0 0;
      @include animate(0.3);
      &:hover {
        @include transform(scale(1.1));
      }
      @media screen and (min-width: 992px) {
        border-radius: 3px 0 0 3px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: $margin * 1.5;
    text-align: center;
    background-color: #fff;

    @media screen and (max-width: 767px) {
      padding: $margin * 0.8 0;
      border-bottom: 1px solid #e9e9e9;
    }
    @media screen and (min-width: 768px) {
      border-radius: 0 0 3px 3px;
      min-height: 239px;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }
    @media screen and (min-width: 992px) {
      border-radius: 0 3px 3px 0;
    }
  }

  &__row:nth-child(even) {
    @media screen and (max-width: 991px) {
      .paragraph-four-images-with-text-half__image img {
        border-radius: 0 0 3px 3px;
      }
    }

    .paragraph-four-images-with-text-half__content {
      @media screen and (min-width: 768px) {
        border-radius: 3px 3px 0 0;
      }
      @media screen and (min-width: 992px) {
        border-radius: 0 3px 3px 0;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding-top: 30px;
      flex-direction: column-reverse;
    }
  }

  .tag {
    font-size: 15px;
    line-height: 19px;
  }

  h5 {
    margin-bottom: 5px;
    max-height: 68px;
    display: inline-block;
    overflow: hidden;
    width: 100%;
  }

  &__description-one {
    font-size: 17px;
    line-height: 21px;
    color: $paragraph-subtitle-color;
    margin-bottom: 12px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }

    @media screen and (min-width: 768px) {
      max-height: 72px;
      display: inline-block;
      overflow: hidden;
    }
    @media screen and (min-width: 992px) {
      max-height: 72px;
    }
  }

  &__description-two {
    @media screen and (min-width: 991px) {
      max-height: 72px;
      display: inline-block;
      overflow: hidden;
    }
  }

  &__secondary-link {
    i {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .btn {
    align-self: flex-start;
    margin: 0 auto;
  }
}
