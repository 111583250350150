@import '../../animate';
@import '../../vars';

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  /*
  * CNY Tabs
  *********************************************************************************/

  #cny-tabs {
    div:last-child {
      .hover-tail {
        left: auto;
        right: -10px;
        @media (max-width: $screen-xs-max) {
          right: 0px;
        }
        &:after {
          left: auto;
          right: 8px;
        }
      }
    }
  }

  #cny-tabs,
  .nav-justified-main {
    text-align: center;
    .col-xs-12 {
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        margin-bottom: $mobile-global-margin;
      }
    }
    .col-xs-3 {
      padding: 0;
    }
    .col-sm-3,
    .col-sm-4 {
      padding: 0 !important;
      &:last-child a {
        border-right: none !important;
      }
    }
    .col-xs-12 {
      margin-bottom: $margin * 1.5;
    }
    .col-xs-12:first-child a {
      border-left: 1px solid $link-color;
    }
    @media (max-width: $screen-xs-max) {
      margin: 0 !important;
      .col-xs-12 {
        margin: 0;
        a {
          border-bottom: 1px solid $link-color;
        }
      }
      .col-xs-12:first-child a {
        border-left: none;
      }
    }
    span {
      display: block;
    }
    .badge {
      display: inline-block;
      padding: 0px;
      position: absolute;
      top: -1px;
      color: $link-color;
      right: 10px;
      top: 10px;
      @media (max-width: $screen-xs-max) {
        i {
          font-size: 24px;
        }
      }
    }
    a {
      display: block;
      background: $lighter-gray;
      padding: 12px 15px;
      font-size: 16px;
      line-height: 21px;
      color: $link-color !important;
      border-right: 1px solid $link-color;
      @media (min-width: 768px) {
        padding: 13px 16px;
        font-size: 17px;
        line-height: 22px;
      }
      &.active,
      &:hover {
        background: white;
        color: $paragraph-body-color !important;
      }
      @media (max-width: $screen-xs-max) {
        border-right: none !important;
      }
      span {
        text-transform: uppercase;
      }
      h4 {
        font-family: $paragraph-font;
        color: $paragraph-subtitle-color;
        @include set-text($tag-size);
        line-height: 21px;
        margin-bottom: 0;
        @media only screen and (max-width: $mobile-breakpoint) {
          @include set-text($tag-size-mobile);
          line-height: 20px;
          margin-bottom: 0;
        }
        small {
          @include set-text($tag-size);
          line-height: 21px;
          @media only screen and (max-width: $mobile-breakpoint) {
            @include set-text($tag-size-mobile);
            line-height: 20px;
          }
        }
      }
    }
    li:last-child a {
      border: none !important;
    }
  }

  #people-tabs {
    padding: 0;
    background: none;
    margin-top: -72px;
    ul:before,
    ul:after {
      content: none;
    }
    a {
      color: #fff !important;
      text-shadow: 1px 1px 1px #000;
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
      background: #1e2d5a;
      background: rgba(30, 45, 90, 0.75);

      padding: 24px;
      -webkit-transition: none;
      -moz-transition: none;
      -o-transition: none;
      transition: none;
      &:hover {
        background: lighten(#1e2d5a, 5%);
        background: lighten(rgba(30, 45, 90, 0.75), 7%);
      }
      &.active {
        background: white;
        text-shadow: none;
        color: #474747 !important;
        &:hover {
          background: white;
        }
      }
    }
  }

  .people-landing {
    .top-hero .hero-text {
      top: 23%;
    }
    #people-list .search-result .list-description,
    .people-list .search-result .list-description {
      height: 95px !important;
      min-height: 0px !important;
      padding-top: 14px;
    }
  }

  /*
  * Mobiles - Maximum width of 768 */
  @media (max-width: $screen-xs-max) {
    .refine-results {
      display: none !important;
    }
    .images {
      display: none;
    }
    .gm-style-iw {
      #marker-content {
        padding: 5px 10px 0px 5px;
        direction: ltr;
        max-height: 170px;
        hr {
          display: none;
        }
      }
      > div {
        direction: rtl;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
          border-radius: 0;
          margin-top: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 0;
          background: #ccc;
        }
      }
    }
    .list-description {
      padding-bottom: 50px;
    }
    .search-controls {
      margin: 20px 5px 20px 10px;
      .col-xs-6 {
        min-height: 0 !important;
        width: 50%;
      }
      .checkbox-tick-list {
        padding-bottom: 0 !important;
        float: left;
      }
    }
    .tabs-main-wrapper .search-controls {
      margin: 0;
    }
    #cny-tabs {
      margin: 0 -15px;
      overflow: visible !important;
      @include clearfix();
      > div {
        margin-top: 4px;
        &:first-child {
          margin-top: 0;
        }
      }
      a {
        text-align: left;

        h4 {
          margin: 0;
          font-size: 14px;
          margin-left: 2px;
          display: inline-block;
          position: relative;
        }
      }
      span {
        display: inline-block;
        float: left;
        font-size: 14px;
        span {
          float: none;
          display: inline-block !important;
          margin-right: 2px;
        }
      }
    }

    #search-cny {
      width: 100%;
      margin-left: -50%;
      position: fixed;
      top: 153px;
      &.fixed-on-map {
        width: 100% !important;
        padding-left: 0px;
        padding-right: 0px;

        height: 55px;
        .modal-dialog {
          width: 100%;
        }
        .modal-body {
          padding: 6px;
        }
        .modal-footer {
          display: none;
        }
        .modal-content {
          -webkit-box-shadow: none;
          box-shadow: none;
          border-radius: 0px;
        }

        .input-group .btn {
          &.icon-back {
            display: block;
            margin-left: 5px;
          }
          &.icon-search {
            margin-left: 3px;
          }
        }
        .no-mobile {
          width: 1%;
          right: 47px;
          button {
            width: 49px !important;
            height: 52px !important;
            border-radius: 3px;
          }
        }
      }
    }

    #map-container #map {
      height: 280px;
      &.map-closed {
        height: 46px !important;
      }
    }

    #map.search-open {
      margin-top: 52px;
    }
    #map-container #map.map-closed {
      height: 46px !important;
    }
    .section-title {
      font-size: 18px;
      margin: -8px -10px 15px 0px;
    }
    .section-title-strapline {
      font-size: 14px;
      margin: -8px -10px 15px 0px;
    }
  }

  @media (max-width: 480px) {
    .list-description {
      padding-bottom: 0px;
    }
  }

  @media (min-width: $screen-sm-min) and (max-width: 1025px) {
    #search-cny {
      width: 100%;

      position: fixed;
      top: 279px;
    }
  }

  /*
  * Tablets - between 768 and 992 */
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    #map-container #map {
      [aria-label='Street View Pegman Control'] {
        display: none;
      }
    }
  }

  /*
  * Tablets - between 768 and 992 */
  @media (min-width: 1000px) and (max-width: 1100px) {
    #search-cny {
      &.fixed-on-map {
        margin-top: 8px;
      }
    }
    #map-container #map {
      [aria-label='Street View Pegman Control'] {
        display: none;
      }
    }
  }
}
