@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  .tab-pane {
    background: #fff;
    .clearfix {
      margin-bottom: 0;
    }
  }
  .nav-tabs {
    border-left: 1px solid $link-color;
    display: table;
    width: 100%;
    line-height: 20px;
    @media only screen and (min-width: 768px) {
      margin-top: 2px;
    }
    > li {
      margin-bottom: 0;
      display: table-cell;
      float: none;
      > a {
        font-size: 16px;
        line-height: 21px;
        border-radius: 0;
        border-right: 1px solid $white;
        border-top: none;
        margin-right: 0;
        padding: 12px 15px;
        background: $brand-red;
        display: inline-block;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        border-bottom: none;
        color: $white;
        @media only screen and (min-width: 992px) and (max-width: 1060px) {
          padding: 2px 14px;
        }

        @media only screen and (min-width: 768px) {
          font-size: 17px;
          line-height: 22px;
          padding: 12px 16px;
        }

        &:hover,
        &:focus {
          background: $link-color-hover;
          border-bottom: none;
        }
      }
    }
    > li.active {
      border-bottom: none;
      > a {
        background: #fff;
        color: $paragraph-body-color;
      }
    }
    li:last-child a {
      border-right: none;
    }
  }
}

@include legacy-full {
  .colection-intro .collection-search {
    margin-bottom: $margin * 1.5;
    @media only screen and (max-width: 767px) {
      margin-bottom: $mobile-global-margin;
    }
    @media (max-width: 991px) {
      width: calc(100% - 52px);
    }
  }

  .collection-main-search-mobile form {
    margin-right: -6px;
  }

  .collection-search input {
    background: #fff;
    padding: 11px 14px;
    font-size: 16px;
    line-height: 21px;
    border-right: none;
    border-radius: 3px 0 0 3px;
    @media only screen and (max-width: 768px) {
      padding: 12px 15px;
      font-size: 17px;
      line-height: 22px;
    }
  }
  .search-controls {
    background: #fff;
    h3 {
      margin-bottom: 0;
    }
    p {
      margin-top: 10px;
    }
  }

  .filter-options {
    background: #fff;
    a {
      @media only screen and (max-width: 480px) {
        padding: 11px 2px;
        .icon-filter-full,
        .icon-filter-empty {
          padding-right: 2px;
        }
      }
    }
    h4 {
      margin-top: 12px;
    }
  }

  .filter-option {
    text-overflow: ellipsis;
  }

  .bootstrap-select > .dropdown-toggle {
    white-space: nowrap;
    position: relative;
  }

  .tabs-wrapper {
    background: #fff;
    .nav-tabs {
      @media only screen and (max-width: 768px) {
        li {
          max-width: 20%;
          a {
            display: block;
            padding: 7px 5px 5px 5px;
            span {
              display: block;
              margin-bottom: 4px;
            }
          }
        }
      }
    }
  }

  #tabs-content > .tab-pane {
    display: none;

    &.active {
      display: block;
    }
    hr {
      margin-bottom: 0;
      margin-top: $margin * 1.5;
      @media only screen and (max-width: 768px) {
        margin-top: 16px;
      }
    }
  }
}
