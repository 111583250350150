@import '../../../animate';
@import '../../../vars';

.paragraph-views-embed {
  padding-bottom: 0;
  @media (min-width: 768px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 16px !important;
  }

  &__title {
    text-align: center;
    margin-bottom: $margin * 1.5;
    @media (max-width: 768px) {
      margin-bottom: $margin * 0.8;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-top: 12px;
    }
  }

  .col-md-3:last-child .card-box-shadow {
    margin-bottom: 0;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-bottom: $margin * 1.5;
    @media (max-width: 768px) {
      margin: $margin * 0.8 0;
    }
  }

  .pager {
    width: 800px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin * 1.5;

    li.pager-item a {
      display: inline-block;
      line-height: 40px;
      border-radius: 50px;
      height: 42px;
      width: 42px;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      &:hover {
        color: #fff;
        background-color: $link-color-hover;
      }
    }

    @media (max-width: 991px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      margin: $margin * 0.8 0 0;
    }
  }

  .pager-previous,
  .pager-next {
    display: inline-block;
    text-transform: uppercase;
    background: $link-color;
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    @include button-padding();

    a {
      color: #fff;
    }

    &:hover,
    &:focus {
      background: lighten($link-color, 2%);
      color: #fff;
    }
    &:active {
      background: darken($link-color, 1%);
    }

    .icon-RCT-arrow {
      position: relative;
      font-size: 12px;
      margin: 0 0 0 10px;
    }

    .icon-RCT-arrow-left {
      position: relative;
      font-size: 12px;
      margin: 0 10px 0 0;
    }
  }

  .pager-current {
    display: inline-block;
    line-height: 40px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #8a2a2b;
    border-radius: 50px;
    height: 42px;
    width: 42px;

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }

  .pager-item,
  .pager-current {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
