@import '../../../animate';
@import '../../../vars';

.paragraph-single-image-with-text {
  img {
    display: block;
    width: 100%;
  }

  h2,
  .tag {
    margin-bottom: 5px;
  }

  &__text {
    text-align: center;
    background: #fff;
    padding: 40px;

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      padding: 30px;
    }

    @media only screen and (max-width: 767px) {
      padding: 16px;
    }
  }

  @media only screen and (min-width: 1200px) {
    .m-b-lg {
      margin: $margin * 2 0 $margin * 2;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .m-b-lg {
      margin: $margin * 1.5 0 $margin * 1.5;
    }
  }

  @media only screen and (max-width: 767px) {
    .text.m-b-lg {
      margin-bottom: 16px;
      > *:last-child {
        margin-bottom: 0;
      }
    }

    .image {
      margin-top: 0;
    }

    .m-b-lg {
      margin: $mobile-global-margin;
    }
  }
}
