@import '../../../animate';
@import '../../../vars';

.group-page-list {
  @media only screen and (min-width: 768px) {
    padding-bottom: 0 !important;
  }
  @media only screen and (max-width: 767px) {
    .row > .col-md-3:last-child {
      > div {
        margin-bottom: 0;
      }
    }
  }
}

body.page-group {
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
