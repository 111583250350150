@import '../../../animate';
@import '../../../vars';

$collection-object-header-area-padding: $margin;
$collection-object-header-area-margin: $margin;

.collection-object-header-area {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 30px;
  margin-bottom: $collection-object-header-area-margin * 1.5;
  @media only screen and (max-width: 768px) {
    padding-top: $collection-object-header-area-padding * 0.8;
    margin-bottom: $collection-object-header-area-margin * 0.8;
  }

  &__row-top,
  &__row-bottom {
    display: flex;
  }

  &__row-top {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: $collection-object-header-area-margin * 1.5;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      margin-bottom: $collection-object-header-area-margin * 0.8;
    }
  }

  &__row-bottom {
    flex-direction: column;
  }

  &__search-button {
    font-family: $paragraph-font;
    @media only screen and (max-width: 767px) {
      order: 1;
      margin-bottom: $collection-object-header-area-margin * 0.8;
    }
  }

  &__title {
    font-size: $h2-size;
    line-height: $h2-line-height;
    text-transform: none;
    margin-bottom: 5px;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: $h2-size-tablet;
      line-height: $h2-line-height-tablet;
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 767px) {
      font-size: $h2-size-mobile;
      line-height: $h2-line-height-mobile;
      margin-bottom: 5px;
    }
  }

  &__date {
    margin: 0;
    font-family: $titles-font;
    font-size: 1.5rem;
    font-weight: lighter;
    display: inline-block;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__subtitle {
    color: $paragraph-subtitle-color;
  }

  &__location {
    color: $paragraph-subtitle-color;
    padding: 20px;
    margin-top: 18px;
    background-color: $lighter-gray;
    @media only screen and (max-width: 767px) {
      margin-top: $collection-object-header-area-margin * 0.2;
      padding: 16px;
    }

    & span {
      vertical-align: middle;
    }
  }

  .object-search-link {
    @media only screen and (max-width: 767px) {
      .btn {
        width: 100%;
      }
    }
  }

  .object-pagination {
    .previous,
    .next {
      font-size: 12px;
      height: 52px;
      @media only screen and (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    @media only screen and (max-width: 767px) {
      order: 2;
    }
  }

  .object-pagination > ul {
    @media only screen and (max-width: 767px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .object-pagination-text-container {
    vertical-align: middle;
    padding: 20px;
    a {
      cursor: pointer;
    }
  }

  .icon-RCT-location {
    vertical-align: middle;
    margin-right: 6px;
    top: -3px;
    position: relative;
  }

  .rcin {
    display: inline-block;
  }
}

.node-type-collection2 .object-pagination {
  display: none !important;
}
