@import '../../../../vars';

.search-facet-dates {
  position: relative;
  &__picker {
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    z-index: 1024;

    @media screen and (min-width: 1020px) {
      width: auto;
      height: auto;
      position: absolute;
      top: 40px;
      left: 0;
    }
  }

  &__arrow {
    display: none;
    position: relative;
    width: 0;
    height: 0;
    top: -1px;
    left: 20px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid rgba(0, 0, 0, 0.09);
    z-index: 4;
    @media screen and (min-width: 1020px) {
      display: block;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 1px;
      left: -12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid #fff;
    }
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 20px;
    border-radius: 50%;
    @media screen and (min-width: 768px) {
      top: 20px;
    }
  }

  &__year {
    display: flex;
    flex-direction: column;
    margin: 0 4px;
    font-size: 16px;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      font-size: 17px;
    }

    &__input-row {
      display: flex;
    }

    &__input-column {
      display: flex;
      flex-direction: column;
      width: calc(50% - 20px);
      margin-bottom: 10px;
      @media screen and (min-width: 768px) {
        flex-direction: row;
        width: auto;
        margin-bottom: 0;
      }
    }

    &__input-column + &__input-column {
      margin-left: 20px;
      @media screen and (min-width: 768px) {
        margin: 0;
      }
    }

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
      margin-bottom: 3px;
      @media screen and (min-width: 768px) {
        margin-bottom: 0;
      }
    }
    input {
      width: 100%;
      @media screen and (min-width: 768px) {
        max-width: 66px;
        margin-right: 15px;
      }
    }

    // Turn off the defualt number spinners
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
    &__button {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: auto;
      }
    }
  }
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $brand-red;
  border: 1px double $brand-red;
  color: $paragraph-body-color;
}

.CalendarDay__selected_span {
  background: $light-red;
  border: 1px double $brand-red;
  color: $paragraph-body-color;
}

.CalendarDay__selected {
  color: #fff;
}

.CalendarDay__selected:hover {
  color: #fff;
}

.CalendarDay__selected_span:hover {
  border: 1px double $brand-red;
  color: $paragraph-body-color;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: $light-red;
  border: 1px double $brand-red;
  color: $paragraph-body-color;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $light-red;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before,
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid $brand-red;
}

.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-left: 33px solid $brand-red;
}

.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid $brand-red;
}

.DayPicker_transitionContainer__vertical {
  height: 100vh;
}
