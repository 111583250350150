@import '../../../animate';
@import '../../../vars';

.paragraph-three-content-highlight {
  .text-center {
    margin-bottom: $margin * 1.5;
  }
  .mobile-margin {
    h3 {
      margin-bottom: 0;
    }
  }
  .view-button {
    text-align: center;
  }
  img {
    width: 100%;
  }
  .grid:after {
    display: block;
    content: '';
    clear: both;
  }
  .grid-col {
    float: left;
    width: 49%;
  }
  .grid-col--4 {
    margin-right: 0;
  }
  .grid-col--2,
  .grid-col--3 {
    display: none;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    .masonary-container {
      padding: 0px 20px 0px 30px;
    }
    .grid {
      margin: 0 -2%;
    }
    .grid-col {
      width: 33%;
      padding: 0 2%;
    }
    .grid-col--2 {
      display: block;
    }
  }
  @media (max-width: 767px) {
    .grid-col {
      width: 100%;
    }
    .grid-col--2 {
      display: block;
    }
    .text-center {
      margin-bottom: $mobile-global-margin;
    }
    .button-outline {
      width: 100%;
    }

    .masonary-container {
      .grid-col {
        width: auto;

        .card-theme-object {
          border-bottom: none;

          .details {
            display: none;
          }

          .image {
            width: 100%;
          }
        }
      }

      .grid-col--1,
      .grid-col--3 {
        margin-right: 4%;
      }

      .grid-col--2,
      .grid-col--4 {
        display: block;

        .card-theme-object {
          .image {
            width: 100%;
            margin-right: 0;
          }
        }
      }

      .grid-col--1,
      .grid-col--2 {
        width: 48%;
        display: block;
      }

      .grid-col--3,
      .grid-col--4 {
        display: none;
      }
    }
  }
  .grid-item {
    margin-bottom: 18%;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  @media (min-width: 991px) {
    .grid {
      margin: 0 -2%;
    }
    .grid-col {
      width: 25%;
      padding: 0 2%;
    }
    .grid-col--2,
    .grid-col--3 {
      display: block;
    }
  }

  &.grey-background {
    background: $lighter-gray;
  }
}
