@import '../../../animate';
@import '../../../vars';

$location-info-below-desktop: 1300px;
$location-info-mobile: 768px;

.location-info {
  &__modal {
    display: none;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__modal-window {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 1010px;
    max-width: 100%;
    max-height: 90vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    @media (max-width: $location-info-below-desktop) {
      width: 90%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  &__top-row {
    position: relative;
    display: block;
    text-align: center;
    padding: $margin * 2 0;
    @media (max-width: $location-info-mobile) {
      padding: $margin * 0.8 0;
    }
  }

  &__tagline {
    text-transform: none;
  }

  &__close-button {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background: $link-color;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    &:hover {
      cursor: pointer;
      background: $link-color-hover;
    }
  }

  iframe {
    width: 100%;
    height: 350px;
    border: none;
  }

  &__map {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__map-button {
    position: absolute;
    bottom: 20px;
    right: 100px;
    background: $link-color !important;
    color: #fff !important;
    @media (max-width: $location-info-mobile) {
      position: relative;
      bottom: auto;
      right: auto;
      text-align: center;
      margin: $margin * 0.8 $margin * 0.8 0 $margin * 0.8;
    }
  }

  .icon-directions:before {
    color: #fff !important;
  }

  .icon-RCT-bus,
  .icon-RCT-car {
    padding-left: 5px;
  }

  &__map-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__map-direction-info {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: auto;
    padding: 30px 0 18px 0;
    > h6 {
      display: inline;
    }
    @media (max-width: 992px) {
      padding: 30px 20px 18px 20px;
    }
    @media (max-width: $location-info-mobile) {
      padding: 16px 0 4px 0;
    }
  }

  &__map-direction-info-container {
    display: flex;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      margin-bottom: 4px;
    }
    &:last-child {
      margin: 0;
      p {
        margin-bottom: 12px;
      }
    }
  }

  &__column-one {
    padding-right: 15px;
  }

  .no-scroll {
    overflow: hidden;
  }

  .container {
    width: auto;
  }
}
