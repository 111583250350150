@import '../../../animate';
@import '../../../vars';

.on-this-page-accordion {
  $self: &;
  margin-bottom: $margin * 1.5;

  @media only screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  &__mobile-accordion-content {
    padding-bottom: 30px;

    @media only screen and (max-width: 767px) {
      padding-bottom: $margin * 0.8;
    }
  }

  &__accordion-title {
    padding-top: $margin * 0.5;
    margin-bottom: $margin * 2;
  }

  &__header {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
  }

  &__link {
    text-transform: uppercase;
    text-align: center;
    span {
      font-size: 15px;
      margin-left: 10px;
    }
  }

  &__back-link {
    position: relative;
    text-align: center;
    span {
      position: absolute;
      top: 6px;
      font-size: 12px;
      margin-left: -6px;
      @media only screen and (max-width: 767px) {
        top: -2px;
      }
    }
  }

  &__back-link-text {
    display: inline;
    text-transform: uppercase;
    margin-right: 15px;
    font-size: 17px;
    line-height: 21px;
  }

  .icon-RCT-down {
    display: none !important;
  }

  @media only screen and (min-width: 768px) {
    .icon-RCT-down {
      display: none !important;
    }
  }

  &__mobile-accordion {
    margin-bottom: 50px;
  }

  &__mobile-accordion.grey-background {
    margin-bottom: 50px;
    .grey-background {
      margin-bottom: 0px;
    }

    .paragraph-related-content,
    .paragraph-two-images-with-text-full,
    .paragraph-four-images-with-text-half {
      padding: 0;
    }
    .on-this-page-accordion__accordion-title {
      padding-top: $margin * 2;
      margin-bottom: $margin * 2;
      .on-this-page-accordion--small-title & {
        margin-bottom: $margin;
      }
    }
  }

  &__accordion-content {
    background: $lighter-gray;
  }

  @media only screen and (max-width: 767px) {
    .icon-RCT-down {
      display: block !important;
      position: absolute;
      bottom: 9px;
      left: 50%;
      margin-left: -6px;
      font-size: $chevron-font-size;
      color: $link-color;
      @include animate(0.3);
    }

    .arrow-active {
      display: none !important;
    }

    &__mobile-accordion:first-of-type {
      border-top: 1px solid $hr-border;
    }

    &__mobile-accordion {
      margin-bottom: 0;
      display: block;
      padding: 0;
      position: relative;
      border-bottom: 1px solid $hr-border;

      &.selected {
        background: $lighter-gray;
      }
    }

    &__mobile-accordion-content {
      display: none;
      padding-bottom: 22px;
    }

    &__mobile-accordion--open {
      #{$self}__mobile-accordion-content {
        display: block;
      }
    }

    &__header {
      cursor: pointer;
      margin-bottom: 0;
      color: $link-color;
    }

    &__mobile-accordion.grey-background {
      margin-bottom: 0;
      background: #fff;
      .grey-background {
        margin-bottom: 0px;
      }
      .on-this-page-accordion__accordion-title {
        padding: 16px 0 16px 0;
        margin-bottom: 19px;
        &.active {
          padding: $margin * 0.8 0 $margin * 0.8 0;
        }
      }
    }

    &__accordion-title {
      padding: $margin * 0.8 0 $margin * 0.8 0;
      margin-bottom: 19px;
      &.active {
        margin-bottom: 0 !important;
      }
    }
    &__accordion-title.active {
      padding: $margin * 0.8 0 $margin * 0.8 0;
    }

    .grey-background .on-this-page-accordion__mobile-accordion-content {
      background: #fff;
    }
    .grey-background.white-background .on-this-page-accordion__mobile-accordion-content {
      background: #fff;
    }
  }

  .grey-background {
    .p-t-lg {
      padding-top: 0px;
    }
    .p-t-b-lg {
      padding: 0px 0 30px 0;
      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }
    .paragraph-views-embed.p-t-b-lg {
      @media only screen and (min-width: 768px) {
        padding: 0;
      }
    }
  }

  &__back-link {
    cursor: pointer;
  }

  &__accordion-close {
    display: none;
  }
  &__back-link {
    display: block;
  }

  @media only screen and (max-width: 767px) {
    &__accordion-close {
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -6px;
      font-size: 12px;
      color: #8a2a2b;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    &__back-link {
      display: none;
    }
  }

  &.grey-background .grey-background.paragraph-whats-on-tab-listing {
    @media only screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
}

// Applies to only search site page.
.page-search-site {
  .on-this-page-accordion {
    &__mobile-accordion.grey-background {
      .on-this-page-accordion {
        &__accordion-title {
          margin-bottom: 0;

          @media only screen and (max-width: 767px) {
            padding: 16px 0 0 0;
          }
        }

        &__header {
          margin-bottom: 5px;
        }
      }
    }
  }
}

// IE 11
_:-ms-fullscreen,
:root .on-this-page-accordion__back-link span {
  margin-left: 6px;
}
