@import '../../../animate';
@import '../../../vars';

.paragraph-exhibition-reference {
  background: white;
  .grey-card {
    .inner {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      .right-text {
        flex: 1;
        padding-left: 20px;
        @media only screen and (max-width: 767px) {
          padding: 0 0 0 10px;
        }
      }
      .left-image {
        width: 180px;
        a {
          display: block;
        }
        @media only screen and (max-width: 767px) {
          width: 90px;
        }
        img {
          width: 100%;
        }
      }
    }
    .description {
      color: $paragraph-subtitle-color;
    }
  }

  .max-container-width {
    > .m-b-lg:last-child {
      margin-bottom: 0;
    }
  }
  + .paragraph-shop-link {
    padding-top: 0 !important;
  }
}
