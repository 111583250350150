@import '../../../animate';
@import '../../../vars';

.paragraph-gallery-widget {
  .slick-slider {
    .custom_paging {
      display: inline-block;
      li {
        display: none;
        font-size: $paragraph-small;
        line-height: $paragraph-small-line-height;
        &.slick-active {
          display: block;
        }
      }
    }

    margin-bottom: 0;
    &.carousel {
      background: white;
    }
    &.nav {
      margin-bottom: $margin;
      text-align: center;
      .slick-track {
        background: $lighter-gray;
      }
      .slick-slide {
        margin: 5px;
        height: 140px;
        background: white;
        padding: 5px;
        cursor: pointer;
        &:hover,
        &.slick-active.slick-center {
          background: $lighter-gray;
        }
        img {
          margin: auto auto;
          display: block;
          width: auto;
          position: relative;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          max-width: 100%;
          max-height: 100%;
        }
      }
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  .carousel {
    .carousel-img {
      text-align: center;
      background: $lighter-gray;
      margin-bottom: 12px;
      img {
        height: 420px;
        display: inline-block;
        text-align: center;
        margin: auto auto -5px auto;
      }
    }

    .carousel-text {
      border-bottom: 1px solid $hr-border-soft;
    }
  }

  .slick-prev,
  .slick-next {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $link-color;
    border: 1px solid white;
    top: 65px;
    &:hover,
    &:focus {
      background: $link-color-hover;
    }
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'icomoon' !important;
    font-size: 12px;
    position: absolute;
    top: 14px;
    opacity: 1;
  }
  .slick-prev:before {
    content: '\e915';
    right: 18px;
  }
  .slick-next:before {
    content: '\e902';
    left: 18px;
  }
  .slick-dots li button {
    &:before {
      font-size: 26px;
      color: $link-color;
    }
  }

  .carousel .slick-prev,
  .carousel .slick-next {
    top: 210px;
  }

  @media only screen and (max-width: 767px) {
    .slick-next {
      right: -17px;
    }
    .slick-prev {
      left: -17px;
    }
  }
}
