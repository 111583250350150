@import '../../../animate';
@import '../../../vars';

.location-banner {
  background: yellow;
  padding-top: 10px;
  > h2 {
    line-height: 120px;
    position: relative;
    top: -11px;
  }
}
