@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@include legacy-full {
  // People page ---------------------------------------------------------

  .node-type-location,
  .node-people {
    .dropdown-btn {
      .dropdown-menu {
        li {
          > span {
            padding: 4px 15px !important;
            display: block;
          }
        }
      }
    }
    .object-tabs,
    .object-accordion {
      .search-result {
        .result {
          &.col-md-3 {
            @media (min-width: $screen-md-min) {
              width: 33.33333%;
            }
            @media (max-width: 480px) {
              width: 100%;
            }
          }
        }
      }
    }
    .location-page-search {
      min-height: 600px;
      .search-list {
        .list-image {
          margin: 15px;
        }
      }
    }
  }

  #map-container {
    #map {
      height: 625px !important;
      @media only screen and (max-width: 767px) {
        height: 375px !important;
      }
    }
  }

  .main-cny-container {
    position: relative;
  }

  #cny-location-search {
    width: 100%;
    input {
      height: 52px;
      @media only screen and (min-width: 767px) {
        height: 48px;
      }
    }
    .input-group-btn {
      position: absolute;
      right: 0;
      z-index: 9;
      .btn-warning {
        background: none;
        color: $paragraph-subtitle-color;
        top: 0px;
        right: 0px;
        width: 54px;
        height: 54px;
        font-size: 29px;
        &.icon-location-scope {
          @media (max-width: 767px) {
            top: -2px;
          }
        }
      }
      .icon-RCT-search {
        right: 0px;
        width: 52px;
        height: 48px;
        font-size: 19px;
        top: -5px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        @media only screen and (min-width: 767px) {
          top: -7px;
        }
      }
    }
  }

  .modal-content {
    box-shadow: none !important;
    border: none;
  }

  @media (max-width: $screen-max-container-max) {
    .modal.fixed-on-map {
      left: 30px !important;
      margin-left: 0 !important;
    }
  }

  @media (max-width: 1100px) {
    .modal.fixed-on-map {
      left: 20px !important;
    }
  }

  @media (max-width: 1024px) {
    .modal.fixed-on-map {
    }
  }

  @media (max-width: 815px) {
  }

  @media (max-width: 767px) {
    .modal.fixed-on-map {
      top: 1px !important;
      left: 0 !important;
      margin-top: 0 !important;
    }
    #cny-location-search .icon-RCT-search.btn.btn-success {
      top: -5px !important;
      right: 52px !important;
      width: 49px !important;
      height: 52px !important;
      padding: 11px;
    }
    #pac-input {
      width: calc(100% - 100px);
      text-overflow: ellipsis;
      padding-right: 50px;
    }
    #cny-location-search .input-group-btn .btn-warning {
      right: 55px;
    }
  }

  .cny-help-icon {
    position: absolute;
    top: -1px;
    right: 54px;
    text-align: center;
    padding-top: 0px;
    margin-top: 0;
    cursor: pointer;
  }

  .no-mobile .hide-map-btn {
    .icon-RCT-down {
      font-size: 12px;
      display: block;
      @include animate(0.3);
      @include transform(rotate(180deg));
    }
    &.switch-chevron {
      .icon-RCT-down {
        @include transform(rotate(0deg));
      }
    }
  }
}

.dropdown-btn {
  .dropdown-menu {
    li {
      > span {
        padding: 4px 15px !important;
        display: block;
      }
    }
  }
}
.object-tabs,
.object-accordion {
  .search-result {
    .result {
      &.col-md-3 {
        @media (min-width: $screen-md-min) {
          width: 33.33333%;
        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
    }
  }
}
.location-page-search {
  min-height: 600px;
  .search-list {
    .list-image {
      margin: 15px;
    }
  }
}

.tab-content > .tab-pane {
  display: none;
}

.page-collection-conservation {
  .select-facet {
    padding: 0 !important;
  }
  .active-facet a {
    .select-facet::before {
      top: 0 !important;
      content: '\e92d';
      bottom: 0px;
      color: $link-color;
    }
    .remove-facet::before {
      content: '' !important;
    }
  }
}
