@import '../../../animate';
@import '../../../vars';

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  /*
  * Search controls
  *********************************************************************************/

  .search-controls {
    .bootstrap-select {
      margin-bottom: 0 !important;
    }
    .active-facet a {
      min-height: 25px;
    }

    .checkbox-tick-list {
      float: right !important;
      @media only screen and (max-width: 767px) {
        padding-right: 16px;
      }
      @media only screen and (min-width: 992px) {
        float: left !important;
      }
    }

    p {
      font-family: $paragraph-font-bold;
      .small {
        font-family: $paragraph-font;
        @include set-text($paragraph-size);
        line-height: 24px;
        @media only screen and (max-width: $mobile-breakpoint) {
          @include set-text($paragraph-size-mobile);
          line-height: 21px;
        }
      }
    }
  }

  .gmnoprint img {
    max-width: none;
  }

  h3.search-controller--results-summary {
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    font-family: 'Gill Sans Nova SemiBold', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
    font-size: 19px;
    font-weight: 100;
    line-height: 24px;
    margin-bottom: 20px;
    padding-top: 2px;
    text-align: left;
    span.small {
      font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
      font-size: 19px;
      font-weight: 100;
      line-height: 24px;
      margin-bottom: 12px;
      padding-top: 13px;
      text-align: left;
    }
  }
}
