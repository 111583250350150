@import '../../../animate';
@import '../../../vars';

.card-collection-object-container {
  &.open-wrapper .mobile-toggle i {
    @include animateDelay(0.2, 0.2);
    @include transform(rotate(0deg) translate(0, -2px));
  }

  background-color: #fff;

  .collection-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    height: 100%;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0;
    }

    .inner {
      height: auto;
      overflow: hidden;
    }
  }

  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
      object-position: center center;
    }
  }

  &__button {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding-top: 24px;
    }
  }

  .card-expander__hidden > .inner {
    flex-direction: column;
    padding: 16px 0;
  }

  @media only screen and (min-width: $mobile-breakpoint) {
    .card-expander__hidden > .inner {
      border-top: 1px solid #f4f4f4;
    }
  }

  .card-expander__content {
    @media only screen and (max-width: 767px) {
      min-height: 100px;
    }
  }

  .card-expander__initial {
    @media only screen and (max-width: 767px) {
      min-height: 100px;
    }
  }

  &__initial {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .card-collection-object .list-icons {
    position: absolute;
    bottom: 16px;
    width: 90%;
    font-size: 15px;
    line-height: 19px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .list-icons {
    display: flex;
    flex: 1 0 auto;
    font-size: 15px;
    line-height: 19px;

    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      margin-top: 5px;
    }
  }

  .rcin-number {
    font-size: 15px;
    line-height: 19px;
    color: #737373;

    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .icon-RCT-location {
    position: absolute;
    top: 2px;
    left: 0;
    margin-right: 0;
  }

  .list-icons li {
    color: #737373;
    align-self: flex-end;
    max-height: 40px;
    display: inline-block;
    overflow: hidden;
    font-size: 15px;
    line-height: 19px;
    padding-left: 22px;
    position: relative;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 18px;
      max-height: 36px;
    }
  }

  .tag,
  .short-description,
  h6 {
    margin-bottom: 5px;
  }

  .tag {
    font-size: 15px;
    line-height: 19px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__rcin {
    color: #333;
  }

  .short-description {
    max-height: 62px;
    display: inline-block;
    overflow: hidden;
    @media only screen and (min-width: 768px) {
      max-height: 103px;
    }
  }

  .short-description,
  .description {
    font-size: 16px;
    line-height: 21px;
    @media only screen and (min-width: 768px) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  .button {
    align-self: center;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    width: 60%;
    @media screen and (min-width: 990px) and (max-width: 1060px) {
      width: auto;
    }

    a {
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      text-align: center;
    }

    @media only screen and (min-width: 768px) {
      font-size: 15px;
      line-height: 19px;
    }
  }
}
