@import '../../../animate';
@import '../../../vars';

.admin-tabs {
  background: $link-color;
  padding-top: 10px;
  ul {
    li {
      padding-top: 10px;
      margin-right: 0px;
      a {
        font-size: 12px;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background: #fff;
        padding: 4px 15px 0px 15px;
        display: block;
        position: relative;
        top: 1px;

        &:hover {
          background: lighten(#ddd, 5%);
        }
        &:active {
          background: darken(#ddd, 5%);
        }
      }
      &.active {
        a {
          padding: 3px 15px 1px 15px;
          cursor: default;
          color: #999;
          background: #f8f8f8;
        }
      }
    }
  }
}
