@import '../../../animate';
@import '../../../vars';

.collection-object-info {
  &__accordion {
    border-top: 1px solid $hr-border;
    > li {
      border-bottom: 1px solid $hr-border;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  &__accordion-tab {
    a {
      display: block;
      padding: $margin 0;
      position: relative;
      .icon-RCT-down {
        position: absolute;
        right: 0;
        top: 32px;
        font-size: $chevron-font-size;
        @include animate(0.3);
      }
      @media only screen and (max-width: $mobile-breakpoint) {
        padding: $margin * 0.8 $margin * 0.8 $margin * 0.8 0;
        .icon-RCT-down {
          top: 22px;
        }
      }

      &.active {
        .icon-RCT-down {
          @include transform(rotate(180deg));
        }
      }
    }
  }

  &__accordion-inside {
    display: none;
    padding-bottom: $margin;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 0 $mobile-global-margin 0;
      flex-direction: column;
    }
  }
}
