@import '../../../animate';
@import '../../../vars';

.share-tool {
  text-align: center;
  margin-top: 7px;
  li {
    display: inline-block;
    button {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;

      i {
        &:before {
          color: #fff;
          font-size: 16px;
          position: absolute;
          top: 13px;
        }

        &.icon-envelope:before {
          left: 11px;
        }

        &.icon-RCT-twitter:before {
          left: 12px;
        }

        &.icon-RCT-fb:before {
          left: 17px;
        }

        &.icon-RCT-google:before {
          left: 9px;
        }

        &.icon-Pinterest-logo:before {
          content: '\e909';
          color: #fff;
          left: 15px;
        }

        &.icon-Whatsapp-logo:before {
          content: '\e949';
          color: #fff;
          left: 13px;
        }
      }

      @media only screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        border: 5px solid #fff;
        i {
          &:before {
            font-size: 13px;
            top: 8px;
          }

          &.icon-envelope:before {
            left: 6px;
          }

          &.icon-RCT-twitter:before {
            left: 7px;
          }

          &.icon-RCT-fb:before {
            left: 10px;
            font-size: 15px;
            top: 6px;
          }

          &.icon-RCT-google:before {
            left: 5px;
          }

          &.icon-Pinterest-logo:before {
            left: 10px;
            top: 9px;
          }

          &.icon-Whatsapp-logo:before {
            left: 9px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767px) {
    margin-top: 0;
    li {
      margin: -3px;
    }
  }
}
