@import '../../../animate';
@import '../../../vars';

//This is to style the injected class to make video promos available to all components
@mixin supportsVideoPromo {
  position: relative;
}

.supports-video-promo {
  @include supportsVideoPromo;
}

.video-promo-card {
  @media screen and (max-width: 767px) {
    display: inline-block;
    width: 90px;
    height: 90px;
    padding-bottom: 0;
  }
}

.video-promo {
  width: 100%;
  height: auto;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__play-button {
    width: 65px;
    height: 40px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    transition: background-color 0.3s ease;

    i {
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: 1px;
      transform: translate(-50%, -50%);
    }
  }

  &__play-button:hover {
    cursor: pointer;
    background-color: $link-color;
    transition: background-color 0.3s ease;
  }

  &__modal {
    display: none;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;

    i:before {
      position: absolute;
      top: 13px;
      left: 14px;
    }
  }

  &__modal-window {
    position: absolute;
    width: 1010px;
    max-width: 100%;
    height: 80vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  &__close-button {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    background: $link-color;
    color: #fff;
    padding: 10px;
    font-size: 15px;
    z-index: 9999;
    &:hover {
      cursor: pointer;
      background: $link-color-hover;
    }
  }

  &__modal-window iframe {
    width: 100%;
    height: 100%;
  }
}
