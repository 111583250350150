@import '../../../../vars';

.search-facet-filters {
  &__row {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: 10px;
    @media screen and (min-width: 768px) {
      margin-top: 20px;
    }
  }
  &__link {
    display: inline-block;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    @media only screen and (min-width: $screen-sm) {
      font-size: 19px;
      line-height: 24px;
    }
  }
  &__filter-icon {
    &::before {
      font-family: 'icomoon';
      content: '\e948';
      margin-right: 12px;
      color: $link-color;
    }
  }
  &__filter-arrow {
    &::before {
      display: inline-block;
      font-family: 'icomoon';
      content: '\e902';
      font-size: 12px;
      margin-left: 12px;
      color: $link-color;
      transition: transform 0.3s ease;
      will-change: transform;
      cursor: pointer;
    }
    &--toggled {
      &::before {
        transform: rotate(90deg);
      }
    }
  }
  &__list {
    margin-bottom: 2px;
  }
  &__filter {
    position: relative;
    display: inline-block;
    color: $link-color;
    background: $lighter-gray;
    padding: 7px 16px;
    font-size: 17px;
    line-height: 21px;
    margin: 0 4px 8px 4px;
    border-radius: 3px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    @media only screen and (min-width: $screen-sm) {
      line-height: 22px;
    }
    &--active {
      color: $paragraph-subtitle-color;
      background: $lighter-gray;
      cursor: not-allowed;
    }
    &--selected {
      color: $white;
      background: $link-color;
    }
  }
  &__empty {
    color: $paragraph-subtitle-color;
    margin-left: 4px;
  }
}

.search-facet-filter {
  p {
    margin-top: 10px;
  }
}
