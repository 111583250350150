@import '../../../animate';
@import '../../../vars';

.container-1-column {
  p a {
    text-decoration: none;
  }

  .paragraph-image-with-caption {
    width: 100%;
    padding: 0;

    &--align-left {
      width: calc(50% + 15px);
      float: left;
      clear: right;
      padding-right: 30px;
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        margin-top: 12px;
      }
    }

    &--align-right {
      width: calc(50% + 15px);
      float: right;
      padding-left: 30px;
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        margin-top: 12px;
      }
    }

    @media (max-width: 767px) {
      padding: 0;
      padding-bottom: 16px;
      margin: 0;
    }
  }

  .paragraph-text {
    margin: 0;

    blockquote p:first-child:before {
      position: relative;
      left: -10px;
    }

    @media (max-width: 767px) {
      padding: 0;
      margin: 0;
    }
  }
}
