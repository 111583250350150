/*
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or its suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=503b4940-870c-44a3-97d6-f0c13462509a');
@font-face {
  font-family: 'Gill Sans Nova Medium';
  src: url('./webfonts/a71e09b7-20b8-4176-9e6e-6cb538680c96.woff2') format('woff2'),
    url('./webfonts/8dcc242e-6af3-4b0a-9ed0-fcc8fa707882.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Gill Sans Nova SemiBold';
  src: url('./webfonts/18030c4e-991d-4cd3-a4e2-2490a4d28fd0.woff2') format('woff2'),
    url('./webfonts/268855c5-5ac1-44a2-a838-96f7b848aebe.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'GoudyOldStyleBT-Roma';
  src: url('./webfonts/17bd0d3f-2614-4d39-8ac9-40cdc0f593c1.woff2') format('woff2'),
    url('./webfonts/037ed60b-9dea-4320-a041-8c812bcff05b.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
