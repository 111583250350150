@import '../../../animate';
@import '../../../vars';

.paragraph-visit-cards {
  &__container {
    display: flex;
  }

  .row{
    display: flex;
    flex-wrap: wrap;
  }

  @media only screen and (min-width: 768px) {
    .card-visit {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }
  }
}
