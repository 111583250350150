@import '../../../animate';
@import '../../../vars';

$location-info-below-desktop: 1300px;
$location-info-mobile: 768px;

.location-info {
  iframe {
    width: 100%;
    height: 350px;
    border: none;
  }

  &__map {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__map-button {
    position: absolute;
    bottom: 20px;
    right: 100px;
    background: $link-color !important;
    color: #fff !important;
    @media (max-width: $location-info-mobile) {
      position: relative;
      bottom: auto;
      right: auto;
      text-align: center;
      margin: $margin * 0.8 $margin * 0.8 0 $margin * 0.8;
    }
  }

  .icon-directions:before {
    color: #fff !important;
  }

  .icon-RCT-bus,
  .icon-RCT-car {
    padding-left: 5px;
  }

  &__map-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__map-direction-info {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    overflow: auto;
    padding: 30px 0 18px 0;
    > h6 {
      display: inline;
    }
    @media (max-width: $location-info-mobile) {
      padding: 16px 0 4px 0;
    }
  }

  &__map-direction-info-container {
    display: flex;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      margin-bottom: 4px;
    }
    &:last-child {
      margin: 0;
      p {
        margin-bottom: 12px;
      }
    }
  }

  &__column-one {
    padding-right: 15px;
  }

  .no-scroll {
    overflow: hidden;
  }

  .container {
    width: auto;
  }
}
