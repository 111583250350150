@import '../../../animate';
@import '../../../vars';

.collection-object-featured-in-area {
  &__text-container {
    margin-bottom: $desktop-global-margin;
    @media only screen and (max-width: 767px) {
      margin-bottom: $mobile-global-margin;
    }
  }

  &__title {
    text-align: center;
  }
}
