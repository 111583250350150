@import '../../../animate';
@import '../../../vars';

.paragraph-timeline {
  position: relative;
  z-index: 0;
  .timeline-wrapper {
    position: relative;
    button.circle {
      position: absolute;
      bottom: 12px;
      right: 8px;
      padding: 10px;
    }
  }

  .in-full-view {
    height: 100vh;
    width: 100vw;
    .expand-full-screen {
      display: none;
    }
  }
}
