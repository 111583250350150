@import '../../../animate';
@import '../../../vars';


body #CybotCookiebotDialog {
  font-family: "Gill Sans Nova Medium", "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, sans-serif !important;
  border-radius: 3px;
  margin: 0;
  font-weight: 400 !important;
  h2 {
    font-size: $paragraph-size;
    line-height: 24px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: $paragraph-size-mobile;
      line-height: 21px;
    }
  }
  
  * {
    font-size: $paragraph-size;
    line-height: 24px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: $paragraph-size-mobile;
      line-height: 21px;
    }
  }
 
  #CybotCookiebotDialogBodyButtons .CybotCookiebotDialogBodyButton:not(:last-of-type) {
    margin-right: 1.5em;
  }

  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
    font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    background: $link-color;
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    line-height: 21px;
    transition: all 0.3s ease-out;
    font-size: 16px;
    padding: 12px 15px;
    opacity: 1;

    @media screen and (min-width: 767px) {
      line-height: 22px;
      font-size: 17px;
      padding: 13px 16px;
    }
    &:hover,
    &:focus {
      background: $link-color-hover;
      color: #fff;
      i,
      i:before {
        color: #fff;
      }
    }
  }

  #CybotCookiebotDialogBodyButtonDecline,
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
    border: 1px solid $link-color;
    background: transparent;
    color: $link-color;
    font-size: 17px;
    line-height: 22px;
    padding: 12px 15px;
    opacity: 1;
    i,
    i:before {
      color: $link-color;
    }
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
      padding: 11px 14px;
    }
    &:hover,
    &:focus {
      opacity: 1;
      border: 1px solid $link-color;
      background: $link-color;
      color: white;
      i:before {
        color: white;
      }
    }
  }
  #CybotCookiebotDialogBodyLevelButtonsSelectPane .CybotCookiebotDialogBodyLevelButtonDescription {
    font-weight: 400 !important;
  }
  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
    font-weight: 400 !important;
  }
  #CybotCookiebotDialogDetailBodyContentTextAbout a {
    border-bottom: 1px dotted #8a2a2b;
    opacity: 1 !important;
    font-weight: 400 !important;
  }
  .CybotCookiebotDialogBodyLevelButtonSliderWrapper {
    opacity: 1 !important;
    font-weight: 400 !important;
  }
  #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton:hover{
    opacity: 1 !important;
    font-weight: 400 !important;
  }
  .CybotCookiebotDialogBodyLevelButtonSliderWrapper:hover input[type=checkbox]:not(:disabled)+.CybotCookiebotDialogBodyLevelButtonSlider{
    opacity: 1 !important;
    background: $link-color-hover;
    font-weight: 400 !important;
  }
  #CybotCookiebotDialogBodyContentText a:hover,#CybotCookiebotDialog #CybotCookiebotDialogBodyLevelButtonIABHeaderViewPartnersLink:hover,#CybotCookiebotDialogDetailBulkConsentList dt a:hover,#CybotCookiebotDialogDetailFooter a:hover,.CybotCookiebotDialogBodyLevelButtonIABDescription a:hover,.CybotCookiebotDialogDetailBodyContentCookieLink:hover{
    opacity: 1 !important;
    background: $link-color-hover;
    font-weight: 400 !important;
  }
  .CybotCookiebotDialogBodyLevelButtonSlider {
    font-weight: 400 !important;
  }
  .CybotCookiebotDialogBodyLevelButtonSlider:hover {
    opacity: 1 !important;
    background: $link-color-hover;
    font-weight: 400 !important;
  }
}
