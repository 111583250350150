@import '../../animate';
@import '../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  .alt-breadcrumb .breadcrumb {
    display: block;
    padding: 15px 0;
  }

  .breadcrumb-wrapper {
    display: inline-block;
    position: relative;
    padding: 0;
    .remove-filter {
      float: right;
      padding: 15px 13px 12px 14px;
      position: absolute;
      z-index: 5;
      right: 0;
    }
    .breadcrumb {
      margin: 0 0 30px 0;
      @media (max-width: 767px) {
        margin: 0 0 16px 0;
      }
    }
    .icon-cross:before {
      content: '\e92c';
      display: block;
      width: 13px;
      height: 13px;
      line-height: 1;
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }

  .breadcrumb > li + li:before {
    content: '/';
    @include icomoon;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0 10px;
    color: $paragraph-body-color;
  }

  .breadcrumb li a,
  .breadcrumb li.active {
    color: $link-color;
  }

  .breadcrumb li a:hover,
  .breadcrumb li a:focus {
    color: #5d1c1c;
    cursor: pointer;
  }

  // floated breadcrumb theme trail
  .floated-breadcrumb {
    margin: 0 10px 30px 0;
    .breadcrumb {
      padding: 11px 55px 10px 15px;
    }
  }
}

@include legacy-full {
  .breadcrumb-wrapper {
    .icon-cross {
      display: inline-block;
      position: absolute;
      background: $link-color;
      padding: 13px 16px;
      right: 0;
      top: 0;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-out;
      @media only screen and (max-width: $mobile-breakpoint) {
        padding: 12px 15px;
      }
      &:hover,
      &:focus {
        background: $link-color-hover;
      }
    }
  }
}

.page-resources #search-themes,
.page-schools-resources #search-themes,
.page-collection-conservation #search-themes,
.page-collection-themes #search-themes,
.page-collection-search .region-content .search-controller-the-search-area,
.page-collection-near-you .region-content {
  .breadcrumb {
    font-size: 15px;
    line-height: 19px;
    color: $paragraph-body-color;
    position: relative;
    padding: 13px 57px 13px 16px;
    display: inline-block;
    background: $gray;
    border-radius: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    @media (max-width: 767px) {
      padding: 12px 57px 12px 15px;
    }
  }
}
