@import '../../../animate';
@import '../../../vars';

.collection-accordion {
  margin-bottom: $margin * 1.5;
  @media only screen and (max-width: $mobile-breakpoint) {
    margin-bottom: $margin * 0.8;
    padding: 0 $margin * 0.8;
  }

  &__accordion {
    border-top: 1px solid $hr-border;
    > li {
      border-bottom: 1px solid $hr-border;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  &__accordion-tab {
    a {
      display: block;
      padding: $margin 0;
      position: relative;
      .icon-RCT-down {
        position: absolute;
        right: 0;
        top: 32px;
        font-size: $chevron-font-size;
        @include animate(0.3);
      }
      @media only screen and (max-width: $mobile-breakpoint) {
        padding: $margin * 0.8 $margin * 0.8 $margin * 0.8 0;
        .icon-RCT-down {
          top: 22px;
        }
      }

      &.active {
        .icon-RCT-down {
          @include transform(rotate(180deg));
        }
      }
    }
  }

  &__accordion-inside {
    display: none;
    padding-bottom: $margin;

    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 0 0 $mobile-global-margin 0;
      flex-direction: column;
    }

    h4 {
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 12px;
      @media only screen and (min-width: 768px) and (max-width: 992px) {
        font-size: 23px;
        line-height: 25px;
        margin-bottom: 12px;
      }
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 23px;
        margin-bottom: 12px;
      }
    }

    .marking {
      margin-bottom: 12px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &-info-area {
    display: flex;

    @media only screen and (max-width: $mobile-breakpoint) {
      display: initial;
    }

    &__column {
      flex-direction: column;
      width: calc(50% - 25px);
      flex: 1 auto;

      &:not(:first-child) {
        @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
          padding-left: 25px;
        }
      }

      .paragraph-text {
        margin-left: 0;
      }

      @media only screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    &.column-only {
      display: initial;

      .collection-accordion-info-area__column {
        width: 100%;
        margin-bottom: 20px;

        &:not(:first-child) {
          @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
            padding-left: 0;
          }
        }
      }
    }
  }
}
