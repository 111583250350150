@import '../../../animate';
@import '../../../vars';

.card-theme-object {
  img {
    width: 100%;
    display: block;
  }

  .details {
    padding: 10px 0;
    h6 {
      small {
        font-family: $titles-font;
        margin-bottom: 0;
        color: $link-color;
      }
    }
    .creator {
      font-size: 15px;
      line-height: 19px;
      margin-bottom: 5px;
      text-transform: uppercase;
      color: $paragraph-subtitle-color;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    > * {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .image {
      width: 90px;
      padding-bottom: 0;
      float: left;
      display: block;
      border-radius: 0;
      margin-right: 10px;
    }
    img {
      width: auto;
      margin: auto;
    }
    .details {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      width: calc(100% - 100px);
      padding-right: 0;
      float: right;
      min-height: 0;
    }
    &:after {
      visibility: hidden;
      display: block;
      font-size: 0;
      content: ' ';
      clear: both;
      height: 0;
    }
  }
}
