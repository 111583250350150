@import '../../../animate';
@import '../../../vars';

.mobile-accordion.open {
  border-bottom: 1px solid #fff;
}

.hover-menu-links {
  li.border-line {
    background: #fff;
    height: 1px;
    border-left: none;
  }
  li.border-line:last-child {
    display: none;
  }
  li {
    position: relative;
    margin-bottom: 0px;
    position: relative;
    a {
      border-left: 5px solid $link-color;
    }
    ul li a {
      border-left: 10px solid $link-color;
    }
    ul li ul li a {
      border-left: 15px solid $link-color;
    }
    ul li ul li ul li a {
      border-left: 20px solid $link-color;
    }
    ul li ul li ul li ul li a {
      border-left: 25px solid $link-color;
    }
    ul li ul li ul li ul li ul li a {
      border-left: 30px solid $link-color;
    }
    &:first-child {
      border-left: none;
    }
    a {
      display: block;
      @include button-padding();
      padding-right: 50px + $button-padding-desktop;
      @media only screen and (max-width: 767px) {
        padding-right: 50px + $button-padding-mobile;
      }
      i {
        position: relative;
        top: 2px;
        margin-right: 10px;
      }
    }
    > i.icon-RCT-arrow {
      position: absolute;
      top: 50%;
      right: 15px;
      font-size: 12px;
      margin-top: -6px;
      color: $link-color;
      @include animate(0.2);
      opacity: 0;
      @include translate(-15px, 0);
    }
    &:hover {
      a {
        background: $lighter-gray;
      }
      > i.icon-RCT-arrow {
        opacity: 1;
        @include translate(0, 0);
      }
    }
    &:active {
      a {
        background: #fff;
      }
    }
    .mobile-accordion {
      padding: 0;
    }
  }
}
