@import '../../../animate';
@import '../../../vars';

$group-navigation-btn-height: 48px;
$group-navigation-btn-height-mobile: 45px;

.group-navigation {
  &.p-t-b-lg {
    @media only screen and (max-width: 767px) {
      padding: 16px 0;
    }
  }

  z-index: 100;
  @include animate(0.3);
  @include clearfix;
  opacity: 0;
  &.window {
    height: $group-navigation-btn-height;
    @media (max-width: 768px) {
      height: $group-navigation-btn-height-mobile;
    }
    position: fixed;
    bottom: -$group-navigation-btn-height;
    @media (max-width: 768px) {
      bottom: -$group-navigation-btn-height-mobile;
    }
    opacity: 1;
    width: 100%;
    .button {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .button {
    width: 300px;
    height: $group-navigation-btn-height;
    border: 1px solid #fff;
    border-bottom: none;
    i {
      font-size: 12px;
      margin-left: 10px;
      position: relative;
      top: -1px;
      @media (max-width: 767px) {
        margin-left: 10px / $mobile-ratio;
      }
    }
    .ellipsis {
      max-height: 24px;
      display: block;
    }
    &.next {
      .ellipsis {
        float: left;
        width: 248px;
      }
    }
    @media (max-width: 767px) {
      height: $group-navigation-btn-height-mobile;
      width: 45%;
      padding-right: 20px;
    }
    &.prev {
      float: left;
      i {
        margin-right: 10px;
        float: left;
        top: 5px;
      }
      @media (max-width: 768px) {
        padding-left: 20px;
        i {
          margin-left: 0px;
          margin-right: 10px;
          @media (max-width: 767px) {
            margin-right: 10px / $mobile-ratio;
          }
        }
      }
    }
    &.next {
      float: right;
      text-align: right;
      i {
        float: right;
        top: 5px;
      }
    }
  }
  &.static {
    position: relative;
    bottom: auto !important;
  }
}

.group-navigation-in-view {
  .group-navigation.window {
    @include translate(0, -$group-navigation-btn-height);
    @media (max-width: 767px) {
      @include translate(0, -$group-navigation-btn-height-mobile);
    }
    &.static {
      @include translate(0, 0);
    }
  }
}
