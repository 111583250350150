@import '../../../animate';
@import '../../../vars';

.paragraph-ticket-listings {
  margin-top: -10px;

  @media (min-width: 768px) {
    margin-top: -40px;
  }

  .switcher-wrapper {
    a {
      border-bottom: 1px dotted #8a2a2b;
    }
  }
}

.paragraph-ticket-listings-card {
  .hidden {
    display: none;
  }

  .switcher-wrapper, .whats-on-wrapper {
    margin: 0 auto 20px 0;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 19px;
      margin-bottom: 2em;
    }

    .switcher-cta-wrapper {
      display: block;

      @media (min-width: 768px) {
        display: inline-block;
      }
    }
  }

  .whats-on-wrapper {
    a {
      display:block;
      border-bottom: 1px dotted #8a2a2b;

      @media (min-width: 768px) {
        display: inline;
      }
    }
  }

  .listings-item {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 16px;
    padding-bottom: 16px;

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    @media (min-width: 768px) {
      border-bottom: none;
      display: flex;
      margin-bottom: 0;
      padding-bottom: 5px;
    }
  }

  .listings-item:last-of-type {
    border: none;
  }

  .image-wrapper {
    border-radius: 3px;
    margin-bottom: 1em;
    height: 267px;
    overflow: hidden;
    position: relative;
    width: 100%;

    @media (min-width: 768px) {
      height: auto;
      margin-right: 20px;
      max-height: 100%;
      width: 285px;
    }

    @media (min-width: 1024px) {
      margin-right: 21px;
    }

    .corner-container {
      height: 105px;
      left: 0;
      position: absolute;
      top: 0;
      width: 175px;
      z-index: 1
    }

    .corner-flash {
      background-color: #8a2a2b;
      color: #fff;
      display: block;
      font-size: 15px;
      left: -46%;
      line-height: 15px;
      max-height: 105px;
      max-width: 118%;
      padding: 125% 35% 26px;
      position: absolute;
      text-align: center;
      top: -164%;
      transform: rotate(-32deg);
      width: 120%;

      @media (min-width: 768px) {
        max-width: 125%;
      }

      span {
        color: #fff;
        display: inline-block;
        font-size: 16px;
        line-height: 20px;
        margin-left: -58px;
        margin-top: -26px;
        text-align: center;

        @media (min-width: 768px) {
          font-size: 17px;
          line-height: 21px;
        }
      }
    }

    img {
      border-radius: 3px;
      bottom: 0;
      position: absolute;
      width: 100%;

      @media (min-width: 768px) {
        margin: 0;
        position: relative;
      }
    }
  }

  .details-wrapper {

    @media (min-width: 768px) {
      margin-bottom: 1.5em;
      width: 405px;
    }

    @media (min-width: 1024px) {
      width: 600px;
    }

    h4 {
      color: #8a2a2b;
      text-align: left;
      margin-bottom: 5px;

      @media (min-width: 768px) {
        margin-bottom: 10px;
      }
    }

    .open-dates {
      font-size: 15px;
      line-height: 19px;
      color: #737373;
      margin-bottom: 10px;

      i {
        margin-right: 10px;
      }
    }

    .pricing-wrapper {
      font-size: 14px;
      margin-bottom: 1em;

      .item {
        display: inline-block;
      }

      .show-name {
        font-size: 14px;
        line-height: 18px;
        width: 50%;

        @media (min-width: 1024px) {
          font-size: 15px;
          line-height: 19px;
          width: 25%;
        }
      }

      .show-price {
        text-align: right;
        width: 40px
      }
    }
  }
}


/* Temporarily Hide ticket prices (RR-1340) */
.paragraph-ticket-listings-card .pricing-wrapper {
  display: none;
}