@import '../../../animate';
@import '../../../vars';

.hero-image {
  text-align: center;
  background: #fff;

  h1,
  .tag {
    margin-bottom: 5px;
  }

  @media only screen and (min-width: 1200px) {
    .m-b-lg {
      margin: $margin * 2 0 $margin * 2;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .m-b-lg {
      margin: $margin * 1.5 0 $margin * 1.5;
    }
  }

  @media only screen and (max-width: 767px) {
    .text.m-b-lg {
      margin-bottom: 16px;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    .text *:last-child {
      margin-bottom: 0;
    }

    .image {
      margin-top: 0;
    }

    .m-b-lg {
      margin: $mobile-global-margin;
    }
  }

  &__image-container {
    position: relative;
    height: auto;

    img {
      width: 100%;
      display: block;
    }
  }

  &__image-description {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 6px;
    background: $lighter-gray;
    color: $paragraph-subtitle-color;
    font-size: 15px;
    line-height: 20px;
    border-top-right-radius: 3px;
    width: 100%;
    text-align: left;

    @media only screen and (max-width: 767px) {
      font-size: 13px;
      line-height: 14px;
    }

    a {
      color: $link-color;
      position: relative;
      top: 1px;
    }
  }

  // Carousel
  &__carousel {
    img {
      display: inline-block;
      text-align: center;
      margin: auto auto -5px auto;
    }
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .slick-slide {
    position: relative;
  }

  .slick-prev,
  .slick-next {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.6);
    top: 50%;
    transform: translateY(-50%);

    &::before {
      font-family: 'icomoon' !important;
      font-size: 12px;
      position: absolute;
      top: 14px;
      opacity: 1;
    }

    &:hover,
    &:focus {
      background: $link-color-hover;
    }
  }

  .slick-prev {
    left: 20px;

    &::before {
      content: '\e915';
      right: 18px;
    }

    @media only screen and (max-width: 767px) {
      left: 8px;
    }
  }

  .slick-next {
    right: 20px;

    &::before {
      content: '\e902';
      left: 18px;
    }

    @media only screen and (max-width: 767px) {
      right: 8px;
    }
  }

  // Video
  &__video-container {
    position: relative;
    background: #f8f8f8;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;

    @media only screen and (max-width: 767px) {
      padding-bottom: 75%; /* 4:3 */
    }
  }

  &__video-container--full-width {
    position: relative;
    padding-bottom: 39.0625%; /* 64:25 */
    height: 0;

    @media only screen and (max-width: 767px) {
      padding-bottom: 78.125%; /* 32:25 */
    }

    &.is-vimeo {
      padding: 39.0625% 0 0 0;
      position: relative;

      &.has-mobile-aspect-video {
        /* If we have a separate video for mobile in the mobile aspect ratio, update our auto-scaling */
        @media only screen and (max-width: 767px) {
          height: auto;
          padding: 75.14% 0 0 0;
        }
      }


      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__vimeo {
    display: none;
  }

  margin-bottom: 0 !important;
  > .image {
    margin-bottom: 30px !important;
    @media only screen and (max-width: 767px) {
      margin-top: 16px !important;
    }
  }
  &__title-wrapper {
    margin-top: 30px !important;
    @media only screen and (max-width: 767px) {
      margin-top: 30px !important;
    }
    @media only screen and (min-width: 1200px) {
      margin-top: 40px !important;
    }
    &--hidden {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  .hero-image__title-wrapper--hidden.hero-image__is-text-content {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
    @media only screen and (min-width: 767px) {
      margin-top: 30px !important;
      margin-bottom: 0 !important;
    }
    @media only screen and (min-width: 1200px) {
      margin-top: 40px !important;
      margin-bottom: 0 !important;
    }
  }
  .hero-image__title-wrapper--hidden.hero-image__is-not-text-content {
    margin-bottom: 0 !important;
    margin-top: 30px !important;
    @media only screen and (min-width: 767px) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    @media only screen and (min-width: 1200px) {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}
