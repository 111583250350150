@import '../../../animate';
@import '../../../vars';

.revisions-list {
  margin-bottom: 30px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 16px;
  }

  &__title {
    h4 {
      border-bottom: 1px solid $hr-border;
      padding-bottom: 12px;
      margin-bottom: 12px;
      @media only screen and (max-width: 767px) {
        padding-bottom: 10px;
        margin-bottom: 10px;
      }
    }
  }

  &__revisions {
    ul {
      li {
        .reason {
          p {
            color: $paragraph-subtitle-color;
          }
        }

        @media only screen and (max-width: 767px) {
          border-bottom: 1px solid $hr-border;
          padding-bottom: 10px;
          margin-bottom: 10px;

          p {
            margin: 0;
          }

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
