@import '../../../animate';
@import '../../../vars';

$toggle-right-margin: 12px;

.card-expander {
  // card-event-container overrides this, stop them.
  margin-bottom: 0 !important;
  position: relative;

  @media only screen and (min-width: 768px) {
    margin-bottom: 30px !important;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
    overflow: hidden;
  }

  &__top {
    position: absolute;
    top: 0;
    left: 0;

    @media only screen and (min-width: 768px) {
      position: relative;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  &__content {
    width: 100%;
    padding-left: 100px;
    display: inline-block;
    transition: all 0.3s ease;

    @media only screen and (min-width: 768px) {
      padding-left: 0;
      position: absolute;
      display: block;
      background-color: #fff;
      height: calc(100% - 50px);
      top: 50px;
      transform: translateY(100%) translateY(-230px);
    }
  }

  &--open &__content {
    @media only screen and (min-width: 768px) {
      transform: translateY(0);
      height: calc(100% - 50px);
    }
  }

  &__placeholder {
    @media only screen and (min-width: 768px) {
      height: 230px;
      background: #fff;
    }
  }

  &__image {
    width: 90px;
    height: auto;
    margin-right: 10px;
    @include animate(0.3);

    @media only screen and (min-width: 768px) {
      margin-right: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &:hover {
        @include transform(scale(1.1));
      }
    }
  }

  @media only screen and (min-width: 768px) {
    &__initial {
      padding: 16px;
      height: 230px;
    }
  }

  &__body-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__hidden {
    margin-left: -100px;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    overflow: hidden;
    max-height: 0;
    will-change: max-height, opacity;

    border-top: 1px solid #f4f4f4;

    @media only screen and (min-width: 768px) {
      margin-left: 0;
      max-height: 100rem;
      width: 100%;
      padding: 0 16px 0 16px;
      border-top: none;
      height: calc(100% - 230px);
    }
  }

  &--open &__hidden {
    transition: max-height 0.3s ease, opacity 0.3s ease 0.3s;
    opacity: 1;
    max-height: 15rem;

    @media only screen and (min-width: 768px) {
      max-height: 100rem;
      margin-left: 0;
    }
  }

  &__sm-toggle,
  &__md-toggle {
    font-size: 12px;
    border-top: 1px solid $hr-border;
    height: 30px;

    i {
      @include transform(rotate(180deg));
      @include animateDelay(0.2, 0);
      display: block;
      padding-bottom: 4px;
    }
    a {
      display: block;
      border: 1px solid $hr-border;
      border-top: none;
      width: 48px;
      height: 24px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      background: #fff;
      margin: -1px $toggle-right-margin 0 auto;
      text-align: center;
      cursor: pointer;
    }
    a:focus {
      outline: none;
    }
  }

  &__md-toggle {
    transform: rotateX(180deg);
    margin-top: -30px;

    a {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 767px) {
    &__md-toggle {
      display: none;
    }
  }
  @media only screen and (min-width: 768px) {
    &__sm-toggle {
      display: none;
    }
  }

  &__toggle--open {
    i {
      @include animateDelay(0.2, 0.2);
      @include transform(rotate(0deg) translate(0, 2px));
    }
  }

  .edinburgh-theme {
    color: $edinburgh-color !important;
    i:before {
      color: $edinburgh-color !important;
    }
  }

  .windsor-theme {
    color: $windsor-color !important;
    i:before {
      color: $windsor-color !important;
    }
  }

  .london-theme,
  .other-theme {
    color: $link-color !important;
    i:before {
      color: $link-color !important;
    }
  }

  &__special {
    background-color: $link-color;
    font-size: 15px;
    line-height: 15px;
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 100% 20% 10px;
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-4%) translateY(-83%);
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    &__wrapper {
      padding: 0 20px;

      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding: 0 25px;
      }
      @media screen and (max-width: 767px) {
        padding: 2px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      transform: rotate(45deg) translateX(-5%) translateY(-75%);
      padding: 100% 10% 10px;
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      border-top: 1px solid #fff;
      transform: rotate(0) translateX(0) translateY(0);
      width: 90px;
      max-height: 90px;
      font-size: 12px;
      padding: 0px;
    }
  }
}
