@import '../../../animate';
@import '../../../vars';

.card-visit {
  @include card();

  @media only screen and (min-width: 768px) {
    text-align: center;
    .fixed-height-small {
      min-height: 159px;
    }
  }
}
