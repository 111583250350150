@import '../../../animate';
@import '../../../vars';

.paragraph-vimeo {
  background: white;
  .vimeo-wrapper {
    position: relative;
    padding-bottom: 54.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      padding-bottom: 53.25%; /* 16:9 */
    }
    @media only screen and (max-width: 767px) {
      padding-bottom: 51.25%; /* 16:9 */
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--caption {
    margin-bottom: 0;
    a {
      border-bottom: none;
    }
  }
}
