@import '../../../../animate';
@import '../../../../vars';

.react-before-and-after-component {
  position: relative;
  overflow: hidden;

  &.in-full-view {
    padding-top: 0;
    figure img {
      width: auto;
      height: 100vh;
    }
  }

  .button {
    text-transform: inherit;
    position: absolute;
    bottom: 8px;
    z-index: 10;
    margin-left: 8px;
    max-width: 36%;
    width: auto;
    background-color: rgba(51, 51, 51, 0.6);
    @include animate(0.3);
    &.label-right {
      right: 0;
      border-radius: 3px;
      margin-right: 8px;
    }
  }
  .expand-full-screen {
    position: absolute;
    z-index: 10;
    top: 8px;
    right: 8px;
    background: $link-color;
  }
  &.dragging {
    .button {
      @include translate(-100%, 0);
      opacity: 0;
      &.label-right {
        @include translate(100%, 0);
      }
    }
  }
  figure {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: white;
    }
    img {
      display: block;
      position: relative;
      object-fit: fill;
      width: 100%;
      height: 100%;
    }
  }
  .compare-controls {
    position: absolute;
    top: 50%;
    margin: -25px 0 0 0;
    padding: 0;
    width: 100%;
    input {
      margin: 0;
      padding: 0;
      margin-left: -25px;
      width: calc(100% + 50px);
      position: relative;
      z-index: 10;
    }
  }

  input[type='range'] {
    -webkit-appearance: none;
    min-height: 50px;

    background: transparent;
    border: none;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 50px;
    height: 50px;
    @include animate(0.2);
    cursor: pointer;
    background-image: url('./ba-left-right.svg');
    border-radius: 50%;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70%;
    background-color: $link-color;
    margin-left: -1px;
    &:hover {
      @include transform(scale(1.05));
      background-color: lighten($link-color, 2%);
    }
    &:active {
      @include transform(scale(0.9));
      background-color: darken($link-color, 2%);
    }
  }

  input[type='range']:focus {
    outline: none;
  }

  //Moz

  input[type='range']::-moz-range-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-moz-range-thumb {
    width: 50px;
    height: 50px;
    @include animate(0.2);
    cursor: pointer;
    background-image: url('./ba-left-right.svg');
    border-radius: 50%;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70%;
    background-color: $link-color;
    margin-left: -1px;
    &:hover {
      @include transform(scale(1.05));
      background-color: lighten($link-color, 2%);
    }
    &:active {
      @include transform(scale(0.9));
      background-color: darken($link-color, 2%);
    }
  }

  input[type='range']::-moz-focus-outer {
    border: 0;
  }

  //IE

  input[type='range']::-ms-thumb {
    width: 50px;
    height: 50px;
    @include animate(0.2);
    cursor: pointer;
    background-image: url('./ba-left-right.svg');
    border-radius: 50%;
    border: 2px solid #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 70%;
    background-color: $link-color;
    margin-left: -1px;
    &:hover {
      @include transform(scale(1.05));
      background-color: lighten($link-color, 2%);
    }
    &:active {
      @include transform(scale(0.9));
      background-color: darken($link-color, 2%);
    }
  }

  ::-ms-tooltip {
    display: none;
  }

  input[type='range']::-ms-fill-lower,
  input[type='range']:focus::-ms-fill-lower,
  input[type='range']::-ms-fill-upper,
  input[type='range']:focus::-ms-fill-upper {
    background: transparent;
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  @media only screen and (max-width: 768px) {
    .button {
      display: block;
      max-width: 36%;
    }
  }
}
