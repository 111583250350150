@import '../../../animate';
@import '../../../vars';

.collection-object-image-area {
  &__carousel {
    .carousel-img {
      text-align: center;
      background: $body-background;
      img {
        display: inline-block;
        text-align: center;
        margin: auto auto -5px auto;
      }

      &.has-iiif-media {
        .collection-object-image-area__image-container {
          position: relative;

          .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            z-index: 1;

            &__inner {
              position: relative;
              top: 37%;

              .title {
                color: $white;
                padding: 0 20px;
                margin-bottom: 30px;
              }

              .launch.button {
                .button__title {
                  text-transform: uppercase;
                  color: $white;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &__image-container {
    height: 500px;
    background: $lighter-gray;
    @media only screen and (max-width: 992px) {
      height: 400px;
    }
    @media only screen and (max-width: 767px) {
      height: 300px;
    }

    > img,
    > iframe,
    > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__media-info {
    display: none;
  }

  .download-container {
    display: inline;
  }

  iframe {
    width: 100%;
  }

  .object-video {
    position: relative;
  }

  .icon-play {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transform: translateX(-50%);
  }

  .slick-prev,
  .slick-next {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $link-color;
    border: 1px solid white;
    top: 65px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: 'icomoon' !important;
    font-size: 12px;
    position: absolute;
    top: 13px;
    opacity: 1;
  }
  .slick-prev:before {
    content: '\e915';
    right: 18px;
  }
  .slick-next:before {
    content: '\e902';
    left: 18px;
  }
  .slick-dots li button {
    &:before {
      font-size: 26px;
      color: $link-color;
    }
  }

  &__carousel .slick-prev,
  &__carousel .slick-next {
    top: 231px;
    @media only screen and (max-width: 992px) {
      top: 180px;
    }
    @media only screen and (max-width: 767px) {
      top: 130px;
    }
  }

  @media only screen and (max-width: 767px) {
    .slick-next {
      right: -17px;
    }
    .slick-prev {
      left: -17px;
    }
  }

  .carousel-text {
    margin-bottom: 12px;
    background-color: $lighter-gray;
    text-align: center;
    padding: 4px 13px 9px 13px;

    @media only screen and (max-width: 767px) {
      padding: 0 13px 18px 13px;
    }
  }

  .actions {
    position: relative;
    text-align: center;
    margin-bottom: $margin * 1.5;
    @media only screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
    & button {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      & i {
        font-size: 17px;
        position: absolute;
        top: 12px;
      }
    }
    .icon-RCT-download2:before {
      color: $body-background;
    }
  }

  .icon-zoom-out {
    left: 12px;
  }

  .icon-zoom-in {
    left: 13px;
  }

  .icon-RCT-expand {
    left: 10px;
    top: 12px !important;
  }

  .icon-share {
    left: 13px;
  }

  .icon-RCT-download2 {
    left: 13px;
  }

  .pointer {
    cursor: pointer;
  }

  #zoom_container,
  .zoom-outer {
    display: none;
  }

  .zoom-outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }

  .object-btns {
    position: absolute;
    z-index: 3;
  }

  .zoom-in a,
  .zoom-out a,
  .close-zoom a {
    color: #fff;
  }

  .object-btns__zoom {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    & .zoom-in,
    .zoom-out {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    & .zoom-in > a,
    .zoom-out > a {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }

  .object-btns__close {
    position: absolute;
    top: 15px;
    right: 30px;
    & .close-zoom {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    & .close-zoom > a {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }

  &__share {
    position: relative;
    display: inline-block;
  }

  .popover {
    position: absolute;
    top: -192px;
    left: -30px;
  }

  .popover-content {
    & .icon-facebook:before,
    .icon-twitter:before,
    .glyphicon-envelope:before,
    .icon-link:before {
      color: #fff;
    }
    a {
      display: block;
      margin-bottom: 12px;
      cursor: pointer;
      border-bottom: 1px solid #8a2a2b;
    }
    a:last-child {
      margin-bottom: 0;
    }
    a:hover {
      border-bottom: 1px solid #fff;
    }
  }

  .social-bottom-share > a {
    display: block;
  }

  .rct-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    border-top: 10px solid $link-color;
    top: auto !important;
    bottom: -10px;
  }

  #iiif-modal.modal {
    .modal-content {
      background: $black;
      padding: 0;

      .modal {
        &__close {
          margin-bottom: 10px;
          font-size: 35px;
        }
      }

      @media (max-width: $medium-font-breakpoint-max - 1) {
        margin: auto auto 15% auto;
        padding: 5px;

        iframe {
          height: 80vh;
        }
      }
    }
  }
}

.collection-object-image-area {
  .collection-object-image-area__nav {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .collection-object-image-area__carousel {
    background: white;
  }

  .custom_paging {
    display: inline-block;
    > li {
      display: none;
      font-size: $paragraph-small;
      line-height: $paragraph-small-line-height;
      &.slick-current {
        display: block;
      }
    }
  }

  .collection-object-image-area__nav {
    margin-bottom: $margin * 1.5;
    text-align: center;
    @media only screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
    .slick-track {
      background: $lighter-gray;
    }
    .slick-slide {
      margin: 5px;
      height: 140px;
      background: white;
      padding: 5px;
      &:hover {
        cursor: pointer;
      }
      &.slick-current.slick-center {
        background: $lighter-gray;
      }

      &.has-iiif-media {
        position: relative;
        overflow: hidden;

        .has-iiif-media__inner {
          color: $white;
          background-color: $brand-red;
          height: 130px;

          .image {
            margin: 0 auto;
            width: 50%;
            height: 89px;
          }

          .label {
            position: relative;
            top: -11px;
            font-size: 17px;
          }
        }

        @media only screen and (min-width: $carousel-thumbnail-break) {
          .has-iiif-media__inner {
            .image {
              width: 30%;
              height: 114px;
            }

            .label {
              top: -18px;
              margin: 0 3px;
            }
          }
        }
      }

      > img {
        margin: auto auto;
        display: block;
        width: auto;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .slick-list {
    padding: 0 !important;
  }

  .in-full-view {
    height: 100% !important;
    width: 100% !important;
    .collection-object-image-area__nav,
    .carousel-text,
    .collection-obj-full-screen,
    .collection-object-image-area__share,
    .download-container {
      display: none;
    }
    .collection-object-image-area__media-info {
      display: block;
    }
  }

  .disable-scroll .in-full-view .collection-object-image-area__media-info {
    display: none;
  }

  .copy-widget {
    position: fixed;
    top: -99999px;
    z-index: 9999999;
    color: #fff;
  }
  .copy-widget.visible {
    position: fixed;
    top: 250px;
    width: 100%;
    left: 0;
  }

  .copy-widget {
    h1 {
      color: #fff;
    }
    p {
      position: absolute;
      bottom: -70px;
      right: 50%;
    }
    a {
      color: #fff;
    }
  }

  //Slick.js

  .slick-no-slide .slick-track {
    width: 100% !important ;
    text-align: center !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }

  .slick-no-slide .slick-slide {
    float: none !important;
    display: inline-block !important;
    width: 110px !important;

    @media only screen and (min-width: $carousel-thumbnail-break) {
      width: 190px !important;
    }
  }
}

.iiif-placeholder {
  position: absolute !important;
  opacity: 0 !important;
  left: -4000px !important;
}
