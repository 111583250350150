@import '../../../animate';
@import '../../../vars';

.paragraph-quote {
  $self: &;
  position: relative;
  padding: 65px 0 35px;
  @media only screen and (min-width: $screen-sm) {
    padding: 120px 0 90px;
  }
  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: none;
  }
  &__wrapper {
    text-align: center;
    padding: 0 16px;
    @media only screen and (min-width: $screen-sm) {
      padding: 0 30px;
    }
  }
  &__quote {
    position: relative;
    color: $paragraph-body-color;
    margin-bottom: 25px;
    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 35px;
    }
    &::before,
    &::after {
      font-family: 'icomoon';
      position: absolute;
      font-size: 11px;
      @media only screen and (min-width: $screen-sm) {
        font-size: 24px;
      }
    }
    &:before {
      content: '\e920';
      top: 0;
      left: -16px;
      color: $brand-red;
      @media only screen and (min-width: $screen-sm) {
        left: -30px;
      }
    }
    &:after {
      content: '\e921';
      bottom: 0;
      right: -16px;
      color: $brand-red;
      @media only screen and (min-width: $screen-sm) {
        right: -30px;
      }
    }
    h3,
    p {
      @media only screen and (max-width: $screen-sm - 0.1) {
        font-size: 20px;
        line-height: 23px;
      }
    }
    p {
      @extend h3;
      margin: inherit;
      font-size: inherit;
      margin-bottom: inherit;
      line-height: inherit;
    }
  }
  &__horizontal-rule {
    position: relative;
    max-width: 115px;
    margin-bottom: 25px;
    border-top: 1px solid $brand-red;
    @media only screen and (min-width: $screen-sm) {
      max-width: 385px;
      margin-bottom: 20px;
    }
  }
  &__author {
    position: relative;
    small {
      color: $paragraph-body-color;
    }
  }

  &--brand-red {
    background-color: $brand-red;
    #{ $self }__quote {
      &::before {
        color: $white;
      }
      &::after {
        color: $white;
      }
      h3 {
        color: $white;
      }
    }
    #{ $self }__author {
      small {
        color: $white;
      }
    }
    #{ $self }__horizontal-rule {
      border-top: 1px solid $white !important;
    }
  }

  &--image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    #{ $self }__overlay {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  &--brand-red {
    &.paragraph-quote--image {
      #{ $self }__overlay {
        background-color: rgba(138, 42, 43, 0.9);
      }
    }
  }
}
