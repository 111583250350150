@import '../../../animate';
@import '../../../vars';

.paragraph-button-text {
  margin-bottom: $margin * 1.5;
  @media (max-width: 767px) {
    margin-bottom: $margin * 0.2;
  }
  &__container {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    margin-right: $margin * 1;
    @media (max-width: 767px) {
      width: 100%;
      margin-right: 0;
    }
    a {
      @media (max-width: 767px) {
        width: 100%;
        text-align: center;
        margin-bottom: $margin * 0.6;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: row;
      flex: 1;
      justify-content: flex-start;
    }

    p {
      margin-bottom: 0;
      @media (max-width: 767px) {
        margin-bottom: 12px;
      }
    }
  }
}
