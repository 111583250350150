@import '../../../animate';
@import '../../../vars';

.card-collection-person {
  @include card();

  .list-icons {
    @media only screen and (min-width: 768px) {
      position: absolute;
      bottom: 16px;
      width: 90%;
    }
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
    & > li {
      position: relative;
      margin-bottom: 5px;
      padding-left: 22px;
      font-size: 15px;
      line-height: 19px;
      max-height: 19px;
      @media only screen and (min-width: 768px) {
        display: inline-block;
        overflow: hidden;
      }
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
        max-height: 18px;
      }
      i {
        position: absolute;
        top: 2px;
        left: 1px;
        margin-right: 0;
      }
    }
  }
  .icon-RCT-location:before,
  .born,
  .died {
    color: $collection-object-secondary-text-color;
  }
}
