@import '../../animate';
@import '../../vars';

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  /// END
  /* CNY MAP */

  .collection-near-you .section-title {
    margin: -8px -10px 5px 0px;
  }

  .node-type-location {
    #toggle-images {
      display: none !important;
    }
  }

  .about-map {
    display: none;
    position: absolute;
    height: auto;
    z-index: 9999999999;
    left: 50%;
    width: 800px;
    margin-left: -400px;
    top: 10%;
    background-color: transparent;
    border: none;
    h1 {
      font-size: 40px;
    }
    .btn-close-about {
      position: absolute;
      right: 0px;
      z-index: 999;
      top: 0;
      font-size: 22px;
      background-color: transparent;
      border: none;
      padding: 8px 12px;
      text-align: center;
    }
    .panel-body {
      overflow: hidden;
      top: 0px;
      bottom: 0;
      background: $link-color;
      color: #fff;
      padding: 30px;
      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  .hover-tail {
    position: absolute;
    background: $link-color;
    color: white;
    width: 200px;
    opacity: 1;
    height: auto;
    color: #fff !important;
    bottom: 35px;
    left: 50%;
    margin-left: -100px;
    z-index: 999;
    padding: 10px;
    font-size: 12px;
    display: inline-block !important;
    white-space: normal;
    line-height: 18px;
    opacity: 0;
    -webkit-transition: all 120ms ease-out 60ms;
    -moz-transition: all 120ms ease-out 60ms;
    -o-transition: all 120ms ease-out 60ms;
    transition: all 120ms ease-out 60ms;
    -ms-transform: translate(0px, -20px);
    -webkit-transform: translate(0px, -20px);
    transform: translate(0px, -20px);
    pointer-events: none;

    @media (max-width: $screen-xs-max) {
      left: auto;
      right: 0px;
      bottom: 40px;
      margin-right: 0 !important;
    }
    &:after {
      content: ' ';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7px 0 7px;
      border-color: $link-color transparent transparent transparent;
      position: absolute;
      bottom: -10px;
      left: 50%;
      margin-left: -3px;
      @media (max-width: $screen-xs-max) {
        left: auto;
        right: 7px;
      }
    }
  }

  .info-icon {
    font-style: normal;
    border: 1px solid $link-color;
    color: $link-color;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 12px;
    padding-top: 1px;
    position: relative;
    top: -5px;
    right: -4px;
    -webkit-transition: all 60ms ease-out;
    -moz-transition: all 60ms ease-out;
    -o-transition: all 60ms ease-out;
    transition: all 60ms ease-out;
    line-height: 16px;
    font-family: arial;
    font-weight: 800;
    cursor: pointer;
    &:hover {
      background: $link-color;
      color: #fff;
    }
    @media (max-width: $screen-xs-max) {
      font-size: 17px;
      width: 26px;
      height: 25px;
      padding-top: 4px;
      background-color: transparent;
      margin-top: 0;
      top: 4px;
      margin-right: 6px !important;
    }
  }

  .hover-info {
    &:hover,
    &:focus,
    .visi {
      .hover-tail {
        opacity: 1;
        -ms-transform: translate(0px, 0px);
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px);
      }
      .info-icon {
        background: $link-color;
        border: 1px solid $link-color;
        color: #fff !important;
      }
    }
  }

  .inline-span-el-anchor {
    padding-right: 15px;
    position: relative;
    max-height: 21px;
    display: block;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    a,
    span {
      display: inline !important;
    }
  }

  .modal-content {
    -webkit-transition: all 100ms ease-out 1s;
    -moz-transition: all 100ms ease-out 1s;
    -o-transition: all 100ms ease-out 1s;
    transition: all 100ms ease-out 1s;
  }
  .modal-dialog-center {
    margin-top: 18%;
  }

  #cookie-message {
    z-index: 9999 !important;
  }

  .page-collection-near-you {
    .alert {
      display: none !important;
    }
  }

  .soft-hide {
    display: none;
  }

  .clear {
    clear: both;
  }

  .no-pad {
    padding: 0 !important;
  }

  .tmp-hide {
    display: none;
  }

  /*
  * Map
  *********************************************************************************/

  .pac-container {
    z-index: 9999 !important;
  }

  #map-container {
    position: relative;
    border-top: none;
    border-bottom: none;
    .loader {
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 6;
      .small {
        position: absolute;
        top: 50%;
        display: block;
        width: auto;
        text-align: center;
        width: 100%;
        color: $link-color;
        margin-top: 0px;
        z-index: 6;
      }
      img {
        width: 70px;
        top: 50%;
        position: absolute;
        display: block;
        left: 50%;
        margin: -45px -35px;
      }
    }
    #map {
      background: #b2d1ff;

      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
      &.map-closed {
        height: 73px !important;
      }
      &.half-closed {
        height: 200px !important;
      }
    }

    .hide-map {
      position: relative;
      .btn {
        position: absolute;
        top: 0;
        right: 30px;
        z-index: 6;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        &.hide-map-btn {
          padding-right: 24px;
        }

        .icon-RCT-down {
          font-size: 12px;
          margin-left: 0;
          right: -10px;
          top: 0px;
          position: relative;
          @include animate(0.3);
          @include transform(rotate(180deg));
        }
        &.switch-chevron {
          .icon-RCT-down {
            @include transform(rotate(0deg));
          }
        }
      }
    }

    .modal {
      position: absolute !important;
    }
    #map {
      width: 100%;
      height: 100%;
    }
    #marker-content {
      h2 {
        font-size: 18px;
        margin-bottom: 8px;
        padding-right: 14px;
        line-height: 24px;
      }
      a {
        font-size: 14px;
      }
      .btn-close {
        position: absolute;
        right: -16px;
        top: -8px;
        z-index: 999999999;
      }
      hr {
        margin-top: 5px;
        margin-bottom: 9px;
      }
      ul {
        li {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .images {
        margin-top: 9px;
        margin-bottom: 8px;
        @include clearfix();
        margin-left: -5px;
        li {
          width: 25%;
          padding-right: 0;
          display: inline-block;
          float: left;
          height: 80px;
          padding-left: 5px;
          margin-right: 0;
          &:last-child {
            padding-right: 0;
          }
        }
      }
      .ellipsis {
        max-height: 16px;
        display: block;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 30px;
      }
      .chev-links {
        margin-bottom: 0;
        padding: 10px 10px 10px 10px;
        a {
          padding-right: 15px;
          position: relative;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inherit;
          max-width: 100%;
          overflow: hidden;
          i {
            position: absolute;

            margin-left: 5px;
            top: 5px;
            margin-right: 4px;
            font-size: 10px;
          }
        }
      }
    }
    .gm-style-iw {
      margin-left: -2px;
      min-height: 100px;
      min-width: 100px;
      overflow: visible !important;
      @media (max-width: $screen-xs-max) {
        max-height: 170px !important;
      }
      div:first-child {
      }
    }
  }

  .gm-style {
    font-family: $paragraph-font;
  }

  .gm-style img {
    max-width: 100%;
  }

  .icon-RCT-down {
    -webkit-transition: all 280ms ease-in-out;
    -moz-transition: all 280ms ease-in-out;
    -o-transition: all 280ms ease-in-out;
    transition: all 280ms ease-in-out;
    display: inline-block;
  }

  .icon-RCT-down.closed {
    top: 0px !important;
    @include transform(rotate(180deg));
  }

  .map-marker-loader {
    text-align: center;
    width: 340px;
    height: 340px;
    margin: 0px;
    margin-right: 0;
    padding: 40px;
    > div {
      width: 90px;
      height: 60px;
      text-align: center;
      position: absolute;
      left: 50%;
      margin-left: -44px;
      top: 50%;
      margin-top: -30px;
    }
  }
  .map-marker-loader .loader-image {
    display: block;
    margin: 0 auto;
    margin-bottom: 11px;
    margin-left: 28px;
    width: 28px;
  }
}
