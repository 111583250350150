@import '../../../../vars';

.search-facets-applied {
  margin-bottom: 8px;
  &__row {
    margin-left: -4px;
    margin-right: -4px;
  }
  p {
    margin-left: 4px;
  }
  li {
    color: $white;
    background-color: $link-color;
    &::before {
      font-family: 'icomoon';
      content: '\e91c';
      font-size: 12px;
      margin-right: 12px;
    }
  }
}
