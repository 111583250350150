@import '../../../animate';
@import '../../../vars';

.paragraph-four-images-with-text {
  .text-center {
    margin-bottom: $margin * 1.5;
  }

  h5 {
    @media only screen and (max-width: 767px) {
      font-size: 22px;
      line-height: 25px;
    }
    .ellipsis-h5-2-line {
      max-height: 54px;
      color: $link-color;
      @media only screen and (max-width: 767px) {
        max-height: 51px;
      }
    }
  }

  .white-card {
    margin-top: 0;
    position: relative;
    text-align: center;
    padding: 30px 20px;
    @media only screen and (max-width: 767px) {
      border-bottom: 1px solid $hr-border;
    }
    @media only screen and (min-width: 768px) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }
    .tag {
      font-size: 15px;
      line-height: 19px;
      max-height: 19px;
      display: block;
    }
  }

  .white-card,
  .grey-card {
    .description {
      &.ellipsis-4-line {
        max-height: 84px;
        @media only screen and (max-width: 767px) {
          max-height: 80px;
        }
      }
    }
  }

  .four-images-odd {
    .white-card {
      border-radius: 0;
      border-top-left-radius: $card-border-radius;
      border-top-right-radius: $card-border-radius;
    }
    .image-animated {
      border-radius: 0;
      border-bottom-left-radius: $card-border-radius;
      border-bottom-right-radius: $card-border-radius;
    }
  }

  .more-link {
    text-transform: uppercase;
    @include set-text($tag-size);
    line-height: 21px;
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
    }
    i {
      font-size: 13px;
      @media only screen and (max-width: 767px) {
        font-size: 11px;
      }
    }
  }
  @media only screen and (min-width: 767px) {
    .white-card {
      min-height: 239px;
    }
    .four-images-odd {
      padding-top: $margin * 3;
    }
  }

  @media only screen and (max-width: 767px) {
    .white-card {
      padding: 16px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .text-center {
      margin-bottom: $mobile-global-margin;
    }
  }

  .white-card-wrapper:nth-child(even) {
    @media only screen and (min-width: 768px) {
      flex-direction: column-reverse;
      display: flex;
      padding-top: 30px;
      .img-animated-link {
        border-radius: 0 0 3px 3px;
      }
      .white-card {
        border-radius: 3px 3px 0 0;
      }
    }
    @media only screen and (min-width: 992px) {
      padding-top: 60px;
    }
  }

  .icon-RCT-vimeo-play:before {
    color: white;
  }
}
