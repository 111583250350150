@import '../../../animate';
@import '../../../vars';

.homepage-splash-image {
  $self: &;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 40px;
  @media only screen and (max-width: 991px) {
    margin-bottom: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 101;
    * {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  &__logo {
    position: absolute;
    top: 0;
    z-index: 2;
    margin: 3.5vw;
    width: 22vw;
    @media only screen and (max-width: 767px) {
      width: 174px;
      height: 56px;
      margin: 16px;
    }
  }

  &__section {
    position: absolute;
    bottom: 0;
    z-index: 102;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    padding: 40px 0;
    box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.5);
    @media only screen and (min-width: 1200px) {
      padding: 40px 0;
    }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      padding: 30px 0;
    }
    @media only screen and (max-width: 767px) {
      padding: 16px 0;
    }
  }

  &__section-content {
    text-align: center;

    h1 {
      color: #fff;
      text-transform: none;
    }
  }

  &__section-button {
    position: relative;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #fff;
    i {
      position: absolute;
      top: 16px;
      left: 16px;
      font-size: 12px;
    }
    i:before {
      color: $link-color;
    }
  }

  &__intro-text {
    p {
      color: $white;
      margin-bottom: 15px;
      @media only screen and (min-width: $screen-sm-min) {
        margin-bottom: 20px;
      }
    }
  }

  &__horizontal-rule {
    position: relative;
    max-width: 85px;
    margin-bottom: 15px;
    margin-top: 0;
    border-top: 1px solid $brand-red;
    @media only screen and (min-width: $screen-sm-min) {
      max-width: 185px;
      margin-bottom: 10px;
    }
  }

  &--large-gradient {
    #{ $self }__section {
      box-shadow: 0px -45px 45px 20px rgba(0, 0, 0, 0.5);
    }
  }
}
