@import '../../../animate';
@import '../../../vars';

$card-visit-large-tablet: 991px;
$card-visit-large-min-tablet: 992px;
$card-visit-large-max-mobile: 767px;
$card-visit-large-min-mobile: 768px;

.card-visit-large {
  margin-bottom: $margin * 1.5;
  @media screen and (max-width: $card-visit-large-max-mobile) {
    margin-bottom: $margin * 0.8;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__row {
    position: relative;
    display: flex;

    @media screen and (min-width: $card-visit-large-min-mobile) and (max-width: $card-visit-large-tablet) {
      padding-bottom: 25%;
    }

    @media screen and (min-width: $card-visit-large-min-tablet) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: $card-visit-large-max-mobile) {
      flex-direction: column;
    }
  }

  &__item {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;

    @media screen and (max-width: $card-visit-large-tablet) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .img-animated-link,
  .image-animated {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    @media screen and (max-width: 991px) {
      border-radius: 3px;
    }
  }

  .img-animated-link {
    width: 100%;
  }

  &__image {
    @media screen and (max-width: 991px) {
      padding-right: 0;
    }
    img {
      width: 100%;
      display: block;
    }

    @media screen and (min-width: $card-visit-large-min-tablet) {
      background: white;
    }
  }

  .card-visit-large__content {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    @media screen and (min-width: $card-visit-large-min-tablet) {
      padding: 30px;
    }
    @media screen and (max-width: 991px) {
      border-radius: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px 30px 30px 40px;
    text-align: center;
    background-color: #fff;

    @media screen and (min-width: $card-visit-large-min-tablet) {
      padding: 30px;
    }

    @media screen and (min-width: $card-visit-large-min-mobile) and (max-width: $card-visit-large-tablet) {
      width: calc(100% - 60px);
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
    }

    @media screen and (max-width: $card-visit-large-max-mobile) {
      padding: 16px 0;
      border-bottom: 1px solid #e9e9e9;
    }
  }

  &__title {
    margin-bottom: 5px;
  }

  &__tag-line {
    font-size: 19px;
    line-height: 24px;
    text-transform: none;
    margin-bottom: 12px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 21px;
    }
  }

  &__description {
    color: $paragraph-subtitle-color;
  }

  &__item-link-container {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    margin-bottom: 12px;
    @media only screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__item-link {
    padding: 0 10px;
    font-size: 17px;
    line-height: 21px;
    text-transform: uppercase;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
  &__button {
    align-self: center;
  }

  .btn.button-outline {
    &:hover,
    &:focus {
      color: #fff !important;
    }
  }

  .edinburgh-color.btn.button-outline {
    border: 1px solid $edinburgh-color;
    &:hover,
    &:focus {
      background-color: $edinburgh-color;
    }
  }

  .windsor-color.btn.button-outline {
    border: 1px solid $windsor-color;
    &:hover,
    &:focus {
      background-color: $windsor-color;
    }
  }
}
