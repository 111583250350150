@import '../../../animate';
@import '../../../vars';

.card-event-container {
  &.open-wrapper .mobile-toggle i {
    @include animateDelay(0.2, 0.2);
    @include transform(rotate(0deg) translate(0, -2px));
  }

  .tag,
  .short-description,
  h6 {
    margin-bottom: 5px;
  }

  .tag {
    font-size: 15px;
    line-height: 19px;
    @media only screen and (max-width: $mobile-breakpoint) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .exhibition-subtitle {
    color: #737373;
    font-size: 17px;
    line-height: 21px;
    max-height: 21px;
    display: block;
    overflow: hidden;
    @media screen and (max-width: $mobile-breakpoint) {
      font-size: 16px;
      line-height: 20px;
      max-height: 20px;
    }
  }

  .inner {
    @media screen and (min-width: $medium-font-breakpoint-min) {
      border-top: 1px solid #f4f4f4;
    }
    padding: 16px 0 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    @media only screen and (max-width: $mobile-breakpoint) {
      padding: 10px 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .list-icons {
      margin: 0;
    }
    .access-icons-list {
      padding-left: 0;

      li {
        padding-left: 0;
      }
    }
    .access-icons {
      margin: 16px 0;
      @media only screen and (max-width: $mobile-breakpoint) {
        margin: 10px 0;
      }
      i {
        font-size: 25px;
        line-height: 28px;
        color: $brand-red;
        position: unset;
      }
      .icon-autism,
      .icon-autisim {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
      }
    }
  }

  .collection-image .inner {
    padding: 0;
    border-top: none;
  }

  .image-animated.hidden-xs {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &__button {
    align-self: center;

    width: auto;

    a {
      font-size: 14px;
      line-height: 18px;
      width: 100%;
      text-align: center;
      @media only screen and (min-width: $medium-font-breakpoint-min) {
        font-size: 15px;
        line-height: 19px;
      }
    }
  }

  p {
    max-height: 20px;
    display: inline-block;
    overflow: hidden;
    color: #737373;
  }

  .list-icons {
    margin-top: 12px;
    margin-bottom: 16px;
    @media only screen and (max-width: $mobile-breakpoint) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    li {
      color: #737373;
      padding-left: 22px;
      position: relative;
      margin-bottom: 5px;
      overflow: hidden;
      font-size: 15px;
      line-height: 19px;
      @media only screen and (max-width: $mobile-breakpoint) {
        font-size: 14px;
        line-height: 18px;
      }
      &.ellipsis-2-line {
        max-height: 38px;
        @media only screen and (max-width: $mobile-breakpoint) {
          max-height: 36px;
        }
      }
      .ellipsis {
        max-height: 19px;
        @media only screen and (max-width: $mobile-breakpoint) {
          max-height: 18px;
        }
      }
      i {
        position: absolute;
        left: 0;
        top: 2px;
        &.icon-RCT-location {
          left: 2px;
        }
      }
    }
  }

  .short-description,
  .description {
    font-size: 16px;
    line-height: 21px;
    @media only screen and (min-width: $medium-font-breakpoint-min) {
      font-size: 17px;
      line-height: 21px;
    }
  }

  @media only screen and (min-width: $medium-font-breakpoint-min) {
    .collection-image {
      width: 100% !important;
      height: 100% !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}
