@import '../../animate';
@import '../../vars';

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  /*
  * Modal popup
  *********************************************************************************/

  #pac-input {
    text-overflow: ellipsis;
  }

  .bg-gray-lighter {
    background: #f4f4f4;
  }

  .marker-cluster {
    border-radius: 50%;
    -webkit-transition: all 140ms ease-in-out;
    -moz-transition: all 140ms ease-in-out;
    -o-transition: all 140ms ease-in-out;
    transition: all 140ms ease-in-out;
    transform: scale(1);
    @include transform(scale(1));
    background-color: transparent;
    font-family: $paragraph-font !important;
    &:hover {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.8);
      transform: scale(1.1);
      @include transform(scale(1.1));
    }
  }

  .lt-ie9 {
    .marker-cluster {
      background-color: none !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }
  #search-cny,
  #about-cny {
    -webkit-transition: opacity 280ms ease-out;
    -moz-transition: opacity 280ms ease-out;
    -o-transition: opacity 280ms ease-out;
    transition: opacity 280ms ease-out;
    display: block;
    width: 610px;
    height: 140px;
    left: 50%;
    margin-left: -305px;
    top: 50%;
    margin-top: -70px;
    -webkit-transform: none !important;
    transform: none !important;
    overflow: visible !important;
    display: none;
    .modal-dialog {
      margin: 0;
    }
    .modal-footer {
      position: relative;
      padding-top: 0;
      border-top: none;
    }
    .no-mobile {
      width: 0;
    }
    .input-group .btn {
      &.icon-search {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &.icon-location-scope {
        border-radius: 0;
        margin-left: 0px;
        border-color: transparent;
        border: none;
        position: relative;
        z-index: 0;
        display: none;
        color: $paragraph-subtitle-color;
        &:hover {
          background: none;
          border-color: transparent;
          border: none;
          color: lighten($paragraph-subtitle-color, 4%);
        }
      }
      &.icon-back {
        font-size: 16px;
        margin-left: 0px;
        display: none;
      }
    }
    .current-location {
      position: absolute;
      left: 16px;
      top: 50%;
      display: block;
      margin-top: -20px;
      &.soft-hide {
        display: none;
        margin-top: -17px !important;
        img {
          width: 20px !important;
          position: relative;
          top: -2px;
          margin-right: 8px;
        }
      }
      .icon {
        color: $link-color;
        font-size: 20px;
        position: relative;
        top: 3px;
        margin-right: 6px;
      }
    }
    &.fixed-on-map {
      position: absolute !important;

      padding-top: 0px;
      opacity: 0 !important;
      width: 390px;
      padding-left: 5px;
      height: 48px;
      overflow: hidden;
      margin: 0 auto;
      display: block !important;
      left: 0;
      z-index: 6;
      margin-left: 0px;
      margin-top: 10px;
      top: 0;
      .current-location {
        &.soft-hide {
          margin-top: 0px !important;
        }
      }
      .icon-location-scope {
        display: inline-block !important;
      }
      input {
        text-overflow: ellipsis;
        padding-right: 100px;
      }
      &.fade-in {
        opacity: 1 !important;
      }
      .modal-dialog {
        width: 450px;
        margin: 0;
        padding: 0;
        transform: none !important;
        -webkit-transform: none !important;
      }
      .modal-body {
        padding: 0px;
      }
      .current-location {
        margin: -2px 0px 0 0;
        position: relative;
        float: left;
        left: 0;
      }
      .modal-footer {
        display: none;
      }
      .btn-close {
        display: none !important;
      }
    }
  }

  .modal-backdrop {
    display: none;
  }

  .about-btn {
    right: 168px !important;
  }

  .map-open-class {
    .about-btn {
      right: 181px !important;
    }
  }

  #search-cny {
    display: none !important;
  }

  .res-with-images {
    display: none;
  }

  .modal {
    .btn-close {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 9;
      height: 49px;
      width: 49px;
      border-radius: 0;
      border-top-right-radius: 6px;
    }
  }

  #marker-content {
    .btn-close {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 9;
      height: 39px;
      width: 39px;
      border-radius: 0;
      border-bottom-left-radius: 3px;
      padding: 10px;
    }
  }

  #about-cny {
    width: 768px;
    height: 480px;
    margin-left: -384px;
    margin-top: -240px;

    .modal-dialog {
      width: 100%;
    }
    .modal-content {
      padding: 16px 50px 16px 16px;
      .modal-body {
        padding: 0 !important;
        h1 {
          margin-bottom: 16px;
        }
      }
    }
    .modal-body {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 400px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
        border-radius: 0;
        margin-top: 0px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background: #ccc;
      }
    }
  }

  @media (max-width: 767px) {
    #about-cny {
      width: 94%;
      height: 300px;
      margin-left: -47%;
      margin-top: -150px;
      h1 {
        font-size: 22px;
      }
      .modal-content {
        padding: 0;
      }
      .modal-body {
        max-height: 300px;
        font-size: 14px;
        padding: 20px;
        padding-right: 40px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    #about-cny {
      width: 660px;
      height: 480px;
      margin-left: -330px;
      margin-top: -240px;
    }
  }
}
