@import '../../../animate';
@import '../../../vars';

$collection-object-header-padding: $margin;
$collection-object-header-margin: $margin;

.collection-object-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: $collection-object-header-margin * 1.5;
  padding: 0 10px;
  @media only screen and (max-width: 768px) {
    padding-top: $collection-object-header-padding * 0.8;
    margin-bottom: $collection-object-header-margin * 0.8;
  }

  &__row-bottom {
    display: flex;
    flex-direction: column;
  }

  &__title {
    text-transform: none;
    margin-bottom: 5px;
    color: $titles-color;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 5px;
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
  }

  &__date {
    margin: 0;
    font-family: $titles-font;
    font-size: 1.5rem;
    font-weight: lighter;
    display: inline-block;
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      font-size: 1.25rem;
    }
    @media only screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__subtitle {
    color: $paragraph-subtitle-color;
    & span {
      margin-bottom: 0;
    }
  }
}

.object-pagination {
  .previous,
  .next {
    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 768px) {
    order: 2;
  }
}

.object-pagination > ul {
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.object-pagination-text-container {
  vertical-align: middle;
  padding: 20px;
}

.icon-RCT-location {
  vertical-align: middle;
  margin-right: 15px;
}

.rcin {
  display: inline-block;
}

.node-type-collection2 .object-pagination {
  display: none !important;
}
