@import '../../../animate';
@import '../../../vars';

.card-resource-listing {
  @include card();
  line-height: 0;

  h6 {
    margin-bottom: 0 !important;
  }

  h6 + * {
    margin-top: 5px;
  }

  &__content-container {
    display: flex;
    flex-direction: column;
  }

  .ellipsis-3-line {
    color: $link-color;
    max-height: 75px;
    @media only screen and (max-width: 1024px) {
      max-height: 69px;
    }
  }

  &__button {
    margin-top: auto;
    font-size: 15px;
    line-height: 19px;
    text-align: center;
    @media only screen and (max-width: 767px) {
      align-self: flex-start;
      font-size: 14px;
      line-height: 18px;
      margin-top: 5px;
      width: auto;
    }
  }
}
