@import '../../../animate';
@import '../../../vars';

.back-to-residence {
  text-transform: uppercase;
  padding: 15px 0 0;
  font-size: 17px;
  line-height: 21px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
  i {
    font-size: 12px;
    position: relative;
    top: -1px;
    padding-right: 5px;
    @media screen and (max-width: 767px) {
      top: 0;
    }
  }
}
