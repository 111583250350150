@import '../../../animate';
@import '../../../vars';

.paragraph-related-content {
  padding: $margin * 1.5 0;
  margin-bottom: $margin * 1.5;
  .mobile-margin {
    margin-bottom: $margin * 1.5;
  }
  &__title,
  &__descr {
    text-align: center;
  }
  .slick-slider {
    margin: 0;
    .slick-prev,
    .slick-next {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background: $link-color;
      border: 1px solid white;
      top: 130px;
      &:hover,
      &:focus {
        background: $link-color-hover;
      }
    }
    .slick-prev {
      left: -11px;
    }
    .slick-next {
      right: -11px;
    }
    .slick-prev:before,
    .slick-next:before {
      font-family: 'icomoon' !important;
      font-size: 12px;
      position: absolute;
      top: 14px;
      opacity: 1;
    }
    .slick-prev:before {
      content: '\e915';
      right: 18px;
    }
    .slick-next:before {
      content: '\e902';
      left: 18px;
    }
    .slick-dots li button {
      &:before {
        font-size: 26px;
        color: $link-color;
      }
    }
    .carousel .slick-prev,
    .carousel .slick-next {
      top: 210px;
    }
    @media only screen and (max-width: 1024px) {
      .slick-prev,
      .slick-next {
        top: 102px;
      }
    }
    @media only screen and (max-width: 991px) {
      .slick-prev,
      .slick-next {
        top: 31.5%;
      }
    }
    @media only screen and (max-width: 768px) {
      .slick-prev,
      .slick-next {
        top: 28.5%;
      }
    }
  }

  &__button-container {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      .mobile-margin {
        margin-bottom: $margin * 0.8;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    padding: $margin * 0.8 0 0;
    margin-bottom: $margin * 0.8;
  }
}
