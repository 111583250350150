@import '../../../animate';
@import '../../../vars';

.paragraph-two-images-with-text {
  position: relative;
  .desktop-image,
  .ipad-portrait-image,
  .mobile-image {
    display: none;
  }
  img {
    display: block;
    width: 100%;
    position: relative;
    z-index: 0;
    border-radius: 3px 3px 0 0;
    @media only screen and (min-width: 992px) {
      border-radius: 3px;
    }
  }

  .white-card-wrapper:nth-child(even) {
    @media only screen and (max-width: 767px) {
      flex-direction: column-reverse;
      display: flex;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      padding-top: 30px;
    }
    img {
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        border-radius: 0 0 3px 3px;
      }
    }
  }

  .white-card {
    z-index: 1;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 16px 0;
    border-bottom: 1px solid $hr-border;
    @media only screen and (max-width: 767px) {
      .tag {
        font-size: 15px;
        line-height: 19px;
      }
      .subtitle {
        font-size: 16px;
        line-height: 20px;
      }
    }
    @media only screen and (min-width: 768px) {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);
      padding: 30px;
    }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      min-height: 239px;
      .subtitle {
        font-size: 17px;
        line-height: 21px;
      }
    }
    @media only screen and (min-width: 992px) {
      position: absolute;
      z-index: 1;
      width: calc(100% - 120px);
      left: 50%;
      margin-left: calc(-50% + 60px);
      bottom: -90px;
      text-align: center;
      border-radius: 0;
    }
    .tag {
      margin-bottom: 0px;
    }
    &.bottom {
      top: 0px;
      bottom: auto;
    }
  }

  .image-animated,
  .image-not-animated {
    margin-top: 0px;
    @media only screen and (min-width: 992px) {
      &.bottom {
        margin-top: 90px;
      }
    }
  }

  .desktop-image,
  .ipad-portrait-image,
  .mobile-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  @media only screen and (max-width: 767px) {
    .ipad-portrait-image {
      display: block;
    }
  }
  .col-sm-6 {
    .image-animated {
      .image-wrapper {
        @include animate(0.3);
        &:hover {
          @include transform(scale(1.1));
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    .desktop-image {
      display: block;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 768px) {
    .ipad-portrait-image {
      display: block;
    }
  }
  @media only screen and (max-width: 480px) {
    .mobile-image {
      display: block;
    }
  }
}
