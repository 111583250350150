@import '../../../animate';
@import '../../../vars';

$form-elements-mobile: 767px;

.webform-client-form {
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  float: none;
  @media only screen and (max-width: $form-elements-mobile) {
    width: auto;
  }

  input {
    width: auto;
    margin-bottom: 12px !important;
    @media only screen and (max-width: $form-elements-mobile) {
      width: 100%;
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: #737373;
  }

  textarea {
    font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
  }

  input,
  textarea {
    font-size: 17px;
    line-height: 22px;
    padding: 12px 15px;
    border-radius: 3px;
    border: 1px solid #d0d0d0;
    margin-bottom: 12px;
    @media only screen and (max-width: $form-elements-mobile) {
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      padding: 11px 14px;
    }
  }

  input[type='radio'],
  input[type='checkbox'] {
    width: auto;
  }

  label {
    display: block;
    font-family: 'Gill Sans Nova SemiBold', sans-serif;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 5px;
    &.option {
      display: inline;
    }
    @media only screen and (max-width: $form-elements-mobile) {
      font-size: 16px;
      line-height: 21px;
      max-width: 100%;
    }
  }

  fieldset {
    border: none;
  }

  input[type='radio'] {
    margin-bottom: 0 !important;
    height: auto;
  }

  input.required {
    background-color: #f4ebeb;
  }

  input[type='submit'] {
    font-family: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
    display: inline-block;
    text-transform: uppercase;
    outline: 0;
    border-radius: 3px;
    cursor: pointer;
    font-size: 17px;
    line-height: 22px;
    padding: 12px 15px;
    transition: all 0.3s ease-out;
    border: 1px solid $link-color;
    background: transparent;
    color: $link-color;
    i,
    i:before {
      font-size: 12px;
      color: $link-color;
    }
    @media only screen and (max-width: $form-elements-mobile) {
      font-size: 16px;
      line-height: 20px;
      padding: 11px 14px;
    }
    &:hover,
    &:focus {
      border: 1px solid $paragraph-body-color;
      background: $link-color;
      color: white;
      i:before {
        color: white;
      }
    }
    &:active {
      background: darken($link-color, 1%);
    }
  }

  //Progress bar
  .webform-progressbar {
    text-align: center;
    margin-top: 54px;
    margin-bottom: 12px;

    .webform-progressbar-page-label {
      display: none;
    }

    .webform-progressbar-page {
      width: 42px;
      height: 42px;
      border: 1px solid #f8f8f8;
      background: #f8f8f8;
      border-radius: 50%;
      margin: -24px;
    }

    .webform-progressbar {
      margin-top: 30px;
    }

    .webform-progressbar-page-number {
      border-radius: 50%;
      display: block;
      padding: 0;
    }

    .webform-progressbar-page.completed {
      background: darken($link-color, 1%);
    }
    .webform-progressbar-inner {
      background: #8a2a2b;
      height: 6px;
    }
    .webform-progressbar-outer {
      background: #f8f8f8;
      height: 6px;
      border: none;
    }
    .completed > .webform-progressbar-page-number {
      width: 42px;
      height: 42px;
      background: darken($link-color, 1%);
      color: #fff;
    }
  }

  //Chosen
  .chosen-container {
    width: auto !important;
    min-width: calc(25% - 2px) !important;
    margin-bottom: 12px;
    font-size: 17px;
    line-height: 22px;
    padding: 12px 15px;
    @media screen and (max-width: $form-elements-mobile) {
      width: 100% !important;
      font-size: 16px;
      line-height: 20px;
      padding: 11px 14px;
    }
  }

  .chosen-container-single {
    .chosen-default,
    .chosen-single {
      color: #333;
      background: $gray !important;
      background-image: $gray !important;
      border-color: transparent !important;
      outline: 0;
      padding: 8px 10px 8px 10px;
      height: auto;
      box-shadow: none;
      border-radius: 3px;
      font-size: 17px;
      line-height: 22px;
      @media screen and (max-width: $form-elements-mobile) {
        font-size: 16px;
        line-height: 20px;
      }
      div b {
        background-image: none !important;
        width: 16px;
        height: 20px;
        position: absolute;
        top: 14px;
        right: 16px;
        font-size: 12px;
        &:before {
          @include icomoon;
          content: '\e913';
        }
      }
    }
    .chosen-single,
    .chosen-drop {
      padding: 12px 15px !important;
      border-color: $form-fields !important;
      box-shadow: none !important;
      background: #fff !important;
      background-image: #fff !important;
      border-radius: 3 !important;
      @media screen and (max-width: $form-elements-mobile) {
        padding: 11px 14px !important;
      }
    }
    &.chosen-container-active {
      .chosen-single,
      .chosen-drop {
        padding: 12px 15px !important;
        border-color: $form-fields !important;
        box-shadow: none !important;
        background: $gray !important;
        background-image: $gray !important;
        border-radius: 0 !important;
        @media screen and (max-width: $form-elements-mobile) {
          padding: 11px 14px !important;
        }
      }
      .chosen-drop {
        margin-top: 0px;
        padding: 0 !important;
        border: none;
      }
    }
    .chosen-search {
      padding: 10px;
      input[type='text'] {
        font-size: 17px;
        line-height: 22px;
        background-color: #fff !important;
        border: 1px solid $hr-border !important;
        padding: 12px 15px !important;
        border-radius: 3px;
        @media screen and (max-width: $form-elements-mobile) {
          font-size: 16px;
          line-height: 20px;
          padding: 11px 14px !important;
        }
      }
    }
  }

  body .chosen-container ul.chosen-results li {
    padding: 4px 10px !important;
    margin-bottom: 0px !important;
  }

  body .chosen-container ul.chosen-results li.highlighted {
    background-color: $lighter-gray !important;
    background-image: none !important;
    color: $link-color;
  }

  //Email
  .webform-component-email {
  }

  //Fieldset
  .webform-component--fieldset {
    border: none;
    padding: 0;
    margin: 0 0 30px 0;
    @media only screen and (max-width: $form-elements-mobile) {
      margin-bottom: 16px;
    }
  }

  ////////======Generic=======/////////

  //Description
  .description {
    margin-bottom: 12px;
  }

  .webform-progressbar-page-number {
    position: relative;
    text-align: center;
    padding: 0 12px;
    font-size: 17px;
    line-height: 42px;
    color: #333;
    @media only screen and (max-width: $form-elements-mobile) {
      font-size: 16px;
    }
  }
  @media screen and (max-width: $form-elements-mobile) {
    .webform-progressbar-outer {
      display: none;
    }
  }

  .current .webform-progressbar-page-number {
    display: inline-block;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    background-color: #8a2a2b;
    border-radius: 50%;
    border-color: #8a2a2b;
    height: 42px;
    width: 42px;
    @media only screen and (max-width: $form-elements-mobile) {
    }
  }

  .webform-next .button-primary .form-submit,
  .webform-previous .button-primary .form-submit {
    display: inline-block;
    text-transform: uppercase;
    background: #8a2a2b;
    color: #fff;
    outline: 0;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    padding: 12px 15px;
    font-size: 17px;
    line-height: 22px;
    margin-bottom: 12px;
    margin-bottom: 0;
    @media only screen and (max-width: $form-elements-mobile) {
      padding: 11px 14px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .webform-component-fieldset {
    margin-bottom: 30px;
  }

  input.webform-calendar {
    width: auto !important;
    border: none;
  }

  .error {
    background-color: #d44547;
    color: #fff;
  }
  .element-invisible {
    display: none;
  }

  .tooltip.in {
    opacity: 1;
  }

  .tooltip-inner {
    background-color: $link-color;
    border-radius: 0;
  }

  .tooltip-arrow {
    border-top-color: $link-color !important;
  }

  .form-actions {
    display: flex;
    justify-content: space-between;
  }

  .webform-previous,
  .webform-next,
  .form-submit {
    flex: 0 0 30%;
    max-width: 30%;
    @media screen and (max-width: $form-elements-mobile) {
      flex: 0 0 45%;
      max-width: 45%;
    }
  }

  .g-recaptcha {
    margin-bottom: 12px;
  }

  .form-file {
    margin-right: 5px;
  }
}
