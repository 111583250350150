@import '../../../animate';
@import '../../../vars';

$checkbox-tick-list: #f8f8f8;
$secondary-link-red: lighten($link-color, 8%);
$facet-font-size: 17px;
$facet-font-mobile: 14px;

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  // Facets

  .facet-list {
    margin-bottom: 20px;
    ul {
      margin-bottom: 0;
      list-style: none;
    }
  }

  .facet {
    position: relative;
    a {
      display: block;
      padding: 10px;
      font-size: $facet-font-size;
      line-height: 21px;
      @media only screen and (max-width: 767px) {
        @include set-text($facet-font-mobile);
        line-height: 20px;
      }
    }
    span {
      display: block;
      -moz-transition: all 0.3s 0.1s ease-in-out;
      -webkit-transition: all 0.3s 0.1s ease-in-out;
      transition: all 0.3s 0.1s ease-in-out;
    }
  }

  .checkbox-tick-list {
    .facet {
      a {
        font-size: $paragraph-small;
        line-height: $paragraph-small-line-height;
        @media only screen and (max-width: 767px) {
          font-size: $paragraph-small-mobile;
          line-height: $paragraph-small-mobile-line-height;
        }
      }
    }
  }

  .facet-list ul {
    list-style: none;
  }

  .tabs-wrapper .facet-list ul {
    padding-left: 0;
  }

  .tabs-wrapper .facet-list > ul {
    margin: 0;
  }

  .facet-name {
    padding: 0 0 0 40px;
    span {
      display: inline-block;
      padding-right: 8px;
      width: 30px;
    }
  }

  .search-controls .facet a {
    background: transparent;
  }

  .search-controls {
    @media (max-width: 768px) {
      .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
        width: 100%;
      }
    }
  }

  .facet a:hover,
  .facet a:focus {
    text-decoration: none;
  }

  .select-facet {
    width: 43px;
    position: absolute;
    top: 0;
    bottom: 0;
    background: $link-color;
    font-size: 13px;
    padding: 13px 16px;
    left: 0;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-out;
    @media only screen and (max-width: 767px) {
      padding: 12px 15px;
    }
    &:before {
      content: '\e92d';
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 13px;
      left: 13px;
      bottom: 0;
      border: 1px solid #fff;
      @include icomoon;
      padding: 3px 2px;
    }
    &:hover,
    &:focus {
      background: $link-color-hover;
    }
  }

  .tab-pane .select-facet:before {
    border: none;
    content: '\e92c';
  }

  .tab-pane .checkbox-tick-list .select-facet:before,
  .search-controls .checkbox-tick-list .select-facet:before {
    content: '\e92d';
    color: $link-color;
  }

  .tab-pane .checkbox-tick-list .select-facet.remove-facet:before,
  .search-controls .checkbox-tick-list .select-facet.remove-facet:before {
    content: ' ';
  }

  .tab-pane > p {
    margin-bottom: $margin;
    @media only screen and (max-width: 767px) {
      margin-bottom: $mobile-global-margin;
    }
  }

  .remove-facet {
    @include icomoon;
    &:before {
      content: ' ';
      top: 13px;
      left: 13px;
    }
  }

  .active-facet a {
    background: $gray;
    color: $paragraph-body-color;
    min-height: 52px;
    padding: 15px;
    margin-bottom: 2px;
    .select-facet:before {
      top: 16px;
    }
  }

  // secondary facet ---

  .search-controls .checkbox-tick-list {
    background: transparent;
    padding: 10px;
    margin-top: 0;
    padding-top: 5px;
    .facet a {
      @include set-text($paragraph-size);
      line-height: 24px;
      @media only screen and (max-width: $mobile-breakpoint) {
        @include set-text($paragraph-size-mobile);
        line-height: 21px;
      }
    }
  }

  .search-controls .facet-list {
    margin-bottom: 0;
    .facet-name {
      padding-left: 40px;
    }
    .remove-facet:before {
      top: 0;
    }
  }

  .checkbox-tick-list {
    background: $checkbox-tick-list;
    padding: 14px 19px 9px 19px;
    .select-facet {
      width: 20px;
      background: transparent;
      left: 10px;
      top: 3px;
    }
  }

  .who-filter-disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }

  .checkbox-tick-list.white-facet {
    background: #fff;
    .facet {
      padding: 0 5px 10px;
      @media (min-width: $screen-sm-min) {
        padding: 0 5px;
      }
    }
  }

  .checkbox-tick-list.facet-list ul {
    margin-left: -15px;
    margin-right: -15px;
  }

  .checkbox-tick-list {
    .facet {
      padding-bottom: 10px;
      @media only screen and (max-width: 767px) {
        padding-bottom: 0px;
      }
      a {
        padding: 0 !important;
      }
    }
    .active-facet a,
    .removed-facet a {
      background: transparent;
      color: $link-color;
    }
    .select-facet:before {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0px;
      left: 0px;
      border: 1px solid $link-color;
      padding: 3px 2px;
      border-radius: 3px;
    }
    .remove-facet:before {
      background-position: 50% -33px;
      top: 0px;
      left: 0px;
    }
    .facet-name {
      padding: 2px 0 0 30px;
    }
  }

  .chosen-container {
    width: 100% !important;
  }

  .chosen-container-single {
    .chosen-default,
    .chosen-single {
      color: #333;
      background: $gray !important;
      background-image: $gray !important;
      border-color: transparent !important;
      outline: 0;
      padding: 8px 10px 8px 10px;
      height: auto;
      box-shadow: none;
      border-radius: 3px;
      div b {
        background-image: none !important;
        width: 16px;
        height: 20px;
        position: absolute;
        top: 14px;
        right: 16px;
        font-size: 12px;
        &:before {
          @include icomoon;
          content: '\e913';
        }
      }
    }
    .chosen-single,
    .chosen-drop {
      padding: 12px 15px !important;
      border-color: $hr-border !important;
      box-shadow: none !important;
      background: #fff !important;
      background-image: #fff !important;
      border-radius: 3 !important;
      @media only screen and (max-width: 767px) {
        padding: 11px 14px !important;
      }
    }
    &.chosen-container-active {
      .chosen-single,
      .chosen-drop {
        padding: 12px 15px !important;
        border-color: $gray !important;
        box-shadow: none !important;
        background: $gray !important;
        background-image: $gray !important;
        border-radius: 0 !important;
        @media only screen and (max-width: 767px) {
          padding: 11px 14px !important;
        }
      }
      .chosen-drop {
        margin-top: 0px;
        padding: 0 !important;
        border: none;
      }
    }
    .chosen-search {
      padding: 10px;
      input[type='text'] {
        background-color: #fff !important;
        border: 1px solid $hr-border !important;
        padding: 15px !important;
        border-radius: 3px;
      }
    }
  }

  .chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: none;
    font-family: $paragraph-font-bold;
  }

  body .chosen-container ul.chosen-results li {
    padding: 4px 10px !important;
    margin-bottom: 0px !important;
  }

  body .chosen-container ul.chosen-results li.highlighted {
    background-color: $lighter-gray !important;
    background-image: none !important;
    color: $link-color;
  }

  .category-list {
    margin: 0 -1px 0 -1px;
    li {
      padding-left: 1px;
      padding-right: 1px;
      a {
        cursor: pointer;
        background: $gray;
        border-bottom: 1px solid white;
        display: block;
        padding: 12px 15px;
        line-height: 21px;
        font-size: 16px;
        @media screen and (min-width: 767px) {
          padding: 13px 16px;
          line-height: 22px;
          font-size: 17px;
        }
        span {
          font-size: $paragraph-small;
          line-height: $paragraph-small-line-height;
          @media only screen and (max-width: $mobile-breakpoint) {
            font-size: $paragraph-small-mobile;
            line-height: $paragraph-small-mobile-line-height;
          }
        }
      }
    }
  }

  .form-inline .form-group,
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
  }

  .category-list li a:hover,
  .category-list li a:focus {
    background: $lighter-gray;
  }

  .category-list .badge {
    float: right;
  }

  .badge {
    border-radius: 0;
    background: transparent;
    font-weight: normal;
  }

  .btn .badge {
    top: 0px;
  }

  .btn-default .badge {
    color: #fff;
    background-color: transparent;
  }
}
