@import '../../../animate';
@import '../../../vars';

.card-ticketing {
  @include card();

  &__overflow {
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      position: inherit;
      display: inline;
    }
  }

  .ellipsis-3-line {
    color: $link-color;
    max-height: 75px;
    @media only screen and (max-width: 1024px) {
      max-height: 69px;
    }
  }

  .description.ellipsis-2-line {
    max-height: 42px;
    @media only screen and (max-width: 767px) {
      max-height: 40px;
    }
  }

  ul.list-icons {
    margin-top: 12px;
  }

  .list-icons li {
    font-size: 15px;
    line-height: 19px;
    color: $collection-object-secondary-text-color;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__special {
    background-color: $link-color;
    font-size: 15px;
    line-height: 15px;
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 100% 20% 10px;
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-4%) translateY(-83%);
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    &__wrapper {
      padding: 0 20px;

      @media screen and (min-width: 992px) and (max-width: 1200px) {
        padding: 0 25px;
      }
      @media screen and (max-width: 767px) {
        padding: 2px;
      }
    }

    @media screen and (min-width: 768px) and (max-width: 1200px) {
      transform: rotate(45deg) translateX(-5%) translateY(-75%);
      padding: 100% 10% 10px;
    }

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      top: auto;
      right: auto;
      bottom: 0;
      left: 0;
      border-top: 1px solid #fff;
      transform: rotate(0) translateX(0) translateY(0);
      width: 90px;
      max-height: 90px;
      font-size: 12px;
      padding: 0px;
    }
  }

  &__image-container {
    position: relative;
  }
}
