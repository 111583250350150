@import '../../../animate';
@import '../../../vars';

.in-full-view {
  padding-top: 30px;
  .actions {
    padding-top: 30px;
  }
  .collection-obj-full-screen,
  .collection-object-image-area__share {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .collection-obj-full-screen {
    display: none;
  }
}

.collection-object-image {
  .carousel-text {
    margin-bottom: $margin * 1.5;
    @media only screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
  }
  &__button-container {
    text-align: center;
    margin-bottom: $margin * 1.5;
    @media only screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
  }
  &__rule {
    display: block;
    border-bottom: 1px solid $hr-border-soft;
  }
}

.collection-object-image-zoom-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  transition: opacity 0.5s;
  opacity: 0;
  &.in {
    opacity: 1;
  }

  .object-btns {
    position: absolute;
    z-index: 3;
  }

  .zoom-in a,
  .zoom-out a,
  .close-zoom a {
    color: #fff;
  }

  .object-btns__zoom {
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    & .zoom-in,
    .zoom-out {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }
    & .zoom-in > a,
    .zoom-out > a {
      position: absolute;
      top: 12px;
      left: 12px;
    }
  }

  .object-btns__close {
    position: absolute;
    top: 15px;
    right: 30px;
    & .close-zoom {
      position: relative;
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    & .close-zoom > a {
      position: absolute;
      top: 13px;
      left: 13px;
    }
  }
}
