@import '../../../animate';
@import '../../../vars';

.search-results-order-by {
  margin-bottom: $margin * 1.5;
  z-index: 1;

  @media (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  &__title {
    p {
      margin: 13px 0 0;

      @media (max-width: 767px) {
        margin: 0 0 $margin * 0.8 0;
      }
      span {
        font-weight: bold;
      }
    }
  }

  &__button,
  &__accordion-menu {
    text-align: left;
    margin-right: 0;
    .outer {
      position: relative;
      width: 100%;
    }
  }

  &__accordion-button {
    width: 100%;
    text-align: left;

    > i {
      position: absolute;
      display: inline-block;
      font-size: 12px;
      top: 50%;
      margin-top: -6px;
      right: 19px;
      transition: all 0.3s ease;
      transform: rotate(0deg);
    }

    .rotated {
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }

  &__accordion-menu {
    position: relative;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &__accordion-content {
    width: 100%;
    height: 0px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 999;
    transition: opacity 0.3s ease;

    > ul > li {
      background-color: #f4f4f4;
      border-bottom: 1px solid #fff;
      text-align: left;
      &:hover {
        background: #f8f8f8;
      }
    }
    > ul > li > a {
      display: block;
      padding: 15px;
      color: #000;
      &:hover {
        color: $link-color;
        cursor: pointer;
      }
    }
  }

  .open {
    height: auto;
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  @media only screen and (max-width: 1024px) {
  }
  @media only screen and (max-width: 991px) {
  }
  @media only screen and (max-width: 768px) {
  }
}
