@import '../../../animate';
@import '../../../vars';

.paragraph-faqs {
  background: white;
  .accordion {
    border-top: 1px solid $hr-border;
    > li {
      border-bottom: 1px solid $hr-border;
      ol li {
        list-style-type: decimal;
      }
      ul,
      ol {
        padding-left: 20px;
      }
      ul {
        margin: 1em 0;
        li {
          list-style-type: disc;
        }
      }
      &.selected {
        background: $lighter-gray;
      }
      h6 {
        margin-bottom: 0;
      }
      a {
        display: block;
        padding: $margin $margin * 2.5 $margin $margin;
        position: relative;
        .icon-RCT-down {
          position: absolute;
          right: 20px;
          top: 26px;
          font-size: $chevron-font-size;
          display: block;
          @include animate(0.3);
          left: auto;
        }
        @media only screen and (max-width: $mobile-breakpoint) {
          padding: 16px 36px 16px 16px;
          .icon-RCT-down {
            top: 22px;
          }
        }

        &.active {
          .icon-RCT-down {
            @include transform(rotate(180deg));
          }
        }
      }
      div {
        display: none;
        padding: 0 $margin $margin;
        @media only screen and (max-width: $mobile-breakpoint) {
          padding: 0 $mobile-global-margin $mobile-global-margin;
        }
      }
    }
    .accordion-inside a {
      padding: 0 !important;
      display: inline !important;
    }
  }
}
