@import '../../../animate';
@import '../../../vars';

.paragraph-resource {
  margin-bottom: $margin * 1.5;
  @media (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  margin-bottom: $margin * 1.5;
  &__container {
    background: $lighter-gray;
    padding: $margin;
    @media (max-width: 767px) {
      padding: 16px;
    }
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    > i {
      margin-top: 14px;
    }
  }

  &__col-one {
    flex: 1 0 50%;
    > p {
      margin-top: 12px;
      margin-bottom: 0;
      @media (max-width: 767px) {
        margin-bottom: $margin * 0.6;
      }
    }
  }

  &__col-two {
    margin-left: $margin * 1.5;
    @media (max-width: 767px) {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    margin-bottom: $margin * 1.5;
    @media (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__button {
    @media (max-width: 767px) {
      margin-bottom: $margin * 0.6;
    }
  }

  &__col-two:last-of-type &__button {
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .icon-document-08 {
    margin-right: $margin * 0.8;
    @media (max-width: 767px) {
      margin-right: $margin * 0.8;
    }
  }

  .icon-RCT-download2 {
    padding-right: 5px;
  }
}
