$margin: 20px;

$mobile-ratio: 1.1875;
$tablet-ratio: 1.06;

$tablet-global-margin: 24px;
$desktop-global-margin: 30px;
$mobile-global-margin: 16px;

$card-border-radius: 3px;

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$screen-max-container-max: 1300px;
$container-max: 1300px;

// Colors
$white: #ffffff;
$black: #000000;
$grey-silver-chalice: #aaaaaa;
$white-254: #fefefe;
$grey-136: #888888;
$titles-color: #222222;
$paragraph-body-color: #333;
$paragraph-subtitle-color: #737373;
$gray: #f4f4f4;
$lighter-gray: #f8f8f8;
$link-color: #8a2a2b;
$link-color-hover: #5d1c1c;
$body-background: #fff;
$hr-border: #e9e9e9;
$hr-border-soft: #f4f4f4;
$form-fields: #d0d0d0;
$secondary-menu-bg: #d8d8d8;
$collection-object-secondary-text-color: #737373;
$light-gray: #afafaf;
$white-alabaster: #f7f7f7;

$brand-red: #8a2a2b;
$light-red: #f4ebeb;

$white: #fff;
$brand-red: #8a2a2b;

$edinburgh-color: #3f2a56;
$windsor-color: #286140;

$required-color: #f4ebeb;

// Titles fonts
$titles-font: 'GoudyOldStyleBT-Roma', Georgia, Times, serif;

// Paragraph fonts
$paragraph-font: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
$paragraph-font-bold: 'Gill Sans Nova SemiBold', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;
$footer-label-font: 'Gill Sans Nova Medium', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Arial, sans-serif;

$medium-font-breakpoint-min: 768px;
$medium-font-breakpoint-max: 1024px;
$mobile-breakpoint: 767px;

$h1-size: 48px;
$h1-line-height: 51px;
$h2-size: 36px;
$h2-line-height: 41px;
$h3-size: 32px;
$h3-line-height: 36px;
$h4-size: 28px;
$h4-line-height: 32px;
$h5-size: 24px;
$h5-line-height: 27px;
$h6-size: 22px;
$h6-line-height: 25px;

$h1-size-tablet: 38px;
$h1-line-height-tablet: 41px;
$h2-size-tablet: 30px;
$h2-line-height-tablet: 34px;
$h3-size-tablet: 27px;
$h3-line-height-tablet: 31px;
$h4-size-tablet: 24px;
$h4-line-height-tablet: 27px;
$h5-size-tablet: 22px;
$h5-line-height-tablet: 25px;
$h6-size-tablet: 20px;
$h6-line-height-tablet: 23px;

$h1-size-mobile: 28px;
$h1-line-height-mobile: 31px;
$h2-size-mobile: 24px;
$h2-line-height-mobile: 27px;
$h3-size-mobile: 22px;
$h3-line-height-mobile: 25px;
$h4-size-mobile: 20px;
$h4-line-height-mobile: 23px;
$h5-size-mobile: 20px;
$h5-line-height-mobile: 23px;
$h6-size-mobile: 20px;
$h6-line-height-mobile: 23px;

$paragraph-small: 15px;
$paragraph-small-line-height: 19px;
$paragraph-small-mobile: 13px;
$paragraph-small-mobile-line-height: 17px;

$paragraph-size: 19px;
$paragraph-size-mobile: 16px;
$paragraph-size-extra-small: 14px;

$tag-size: 17px;
$tag-size-mobile: 14px;

$description-size: 17px;
$description-size-mobile: 16px;

$blockquote-size: 22px;
$blockquote-line-height: 24px;
$blockquote-size-mobile: 18px;
$blockquote-line-height-mobile: 22px;

$button-size: 17px;
$button-size-mobile: 16px;
$button-padding-desktop: 15px;
$button-padding-mobile: 14px;

$chevron-font-size: 12px;

// Header
$header-height: 120px;
$header-padding: 20px;
$menu-break: 991px;
$xl-breakpoint: 1300px;

$header-mobile-height: 86px;
$header-mobile-padding: 10px;

$header-a-color: #333;
$header-a-hover-color: #8a2a2b;

$light-box-shadow: 0px 0px 2px #ddd;
$card-box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.05);

// Carousel
$carousel-thumbnail-break: 1161px;

@mixin clearfix() {
  &:after {
    content: ' ';
    display: block;
    width: 100%;
    clear: both;
  }
}
