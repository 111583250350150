@import '../../../animate';
@import '../../../vars';

.paragraph-job-feed {
  margin-bottom: $margin * 1.5;

  .icon-RCT-location {
    margin-right: 0;
  }

  .text-center {
    h3 {
      color: $link-color;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
  }

  &__icon-block {
    background: $lighter-gray;
    margin-bottom: $margin * 1.5;
    padding: 12px $margin 32px $margin;

    &:last-child {
      margin-bottom: 0;
    }

    p {
      line-height: $margin * 1.8;
    }

    span {
      padding-left: $margin * 1.5;
      position: relative;

      &:before {
        color: $paragraph-body-color;
        left: 0;
        position: absolute;
        top: 0;
      }

      &.icon-RCT-location:before {
        top: -1px;
        left: 2px;
      }

      &.icon-RCT-date {
        top: -2px;
      }
    }
  }

  .icon-RCT-job {
    font-size: 14px;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;

    &__icon-block {
      padding: $margin * 0.8;
      margin-bottom: $margin * 0.8;

      p {
        line-height: $margin * 1.5;
      }
    }
  }
}
