@import '../../animate';
@import '../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@include legacy-full {
  .node-type-location .list-description {
    min-height: 0px !important;
    height: 200px;
  }

  .list-location.view-of {
    span {
      max-width: 100%;
    }
    > span {
      line-height: 16px;

      span {
        position: relative;
        top: -2px;
        left: -2px;
      }
    }
    span:nth-child(3) {
      width: 60%;
      margin-left: 5px;
      a {
        max-width: 86%;
      }
    }
    &.from {
      span:nth-child(3) {
        width: 71%;
      }
    }
    .small {
      max-height: 20px;
    }
    .icon-location {
      margin-right: 5px;
      margin-top: -1px;
    }
  }

  #myTabContent em {
    font-style: italic;
  }

  .dropdown-btn {
    &.open > .dropdown-menu {
      width: 100%;
      margin: 0px 0 0;
      border-radius: 0;
      border: none;
    }
    .dropdown-menu > li > a:hover,
    .dropdown-menu > li > a:focus {
      background: $link-color;
    }
    .caret {
      border-top: 6px solid;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      margin-left: 10px;
    }
  }

  @media (max-width: $screen-xs-max) {
    .node-type-location .list-description {
      min-height: 180px;
    }
  }
}
