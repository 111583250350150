@import '../../../animate';
@import '../../../vars';

.paragraph-event-reference {
  background: white;
  .grey-card {
    .inner {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      .right-text {
        flex: 1;
        padding-left: 20px;
        @media only screen and (max-width: 767px) {
          padding: 0 0 0 10px;
        }
      }
      .left-image {
        width: 180px;
        a {
          display: block;
        }
        @media only screen and (max-width: 767px) {
          width: 90px;
        }
        img {
          width: 100%;
        }
      }
    }
    .list-icons {
      li {
        padding-left: 30px;
        position: relative;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 5px;
        @media only screen and (min-width: 768px) {
          font-size: 17px;
          line-height: 21px;
          margin-bottom: 10px;
        }
        i {
          position: absolute;
          left: 0;
          top: 2px;
          &.icon-RCT-location {
            left: 3px;
            &:before {
              color: $paragraph-body-color;
            }
          }
        }
      }
      .location-grey {
        color: #737373;
      }
    }
    .description {
      color: $paragraph-subtitle-color;
    }
  }

  .max-container-width {
    > .m-b-lg:last-child {
      margin-bottom: 0;
    }
  }
  + .paragraph-shop-link {
    padding-top: 0 !important;
  }
}
