@import '../../../animate';
@import '../../../vars';

.paragraph-image-with-caption {
  img {
    width: 100%;
  }

  .full-container {
    position: relative;
  }

  .image {
    position: relative;
    line-height: 10px;

    .button {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 42px;
      height: 42px;
      i::before {
        left: 11px;
        position: absolute;
        top: 13px;
      }
    }
  }

  .iframe {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border: 0;
    }
  }

  p {
    font-size: $paragraph-small;
    line-height: $paragraph-small-line-height;
    margin-bottom: 0;
    background-color: $lighter-gray;
    padding: 4px 6px 6px 6px;

    a {
      border-bottom: none;
      position: relative;
      top: 1px;
    }
    @media only screen and (max-width: 767px) {
      font-size: $paragraph-small-mobile;
      line-height: $paragraph-small-mobile-line-height;
      margin-bottom: 0;
    }
  }
}

/** angular slide show **/

.full-slide-show {
  a {
    text-decoration: underline;
  }

  &__icon-close {
    position: absolute;
    right: 30px;
    margin-right: 0 !important;
    @media only screen and (max-width: 767px) {
      right: 16px;
    }
  }

  &__icon-controls > a,
  &__icon-close > a {
    position: relative;
    background: $link-color;
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &__icon-close > a:before {
    position: absolute;
    top: 13px;
    left: 14px;
    font-size: 15px;
    @media only screen and (max-width: 767px) {
      top: 13px;
      left: 13px;
    }
  }

  &__icon-controls > a:before {
    position: absolute;
    top: 12px;
    left: 12px;
  }

  .icon-RCT-expand::before {
    left: 9px;
    font-size: 17px;
  }

  #slideshow-next,
  #slideshow-previous {
    position: absolute;
    top: 50%;
    background: $link-color;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-top: -55px;
  }

  #slideshow-next > span:before {
    position: absolute;
    top: 15px;
    left: 19px;
    font-size: 12px;
    @media only screen and (max-width: 767px) {
      top: 15px;
      left: 18px;
    }
  }

  #slideshow-previous > span:before {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    @media only screen and (max-width: 767px) {
      top: 15px;
      left: 15px;
    }
  }

  #slideshow-next {
    right: 30px;
    @media only screen and (max-width: 767px) {
      right: 9px;
    }
  }

  #slideshow-previous {
    left: 30px;
    @media only screen and (max-width: 767px) {
      left: 9px;
    }
  }

  .slideshow-loader {
    color: white !important;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -100px;
    margin-top: -70px;
    height: 100px;
    width: 200px;
    text-align: center;
    span.small {
      margin-top: 15px;
      display: block;
      color: #ddd !important;
    }
  }
  display: none;
  #collection-slideshow-item {
    position: relative;
    .label-link {
      margin: -15px 0 14px 0 !important;
    }
  }
  p.small {
    margin-bottom: 0;
    a {
      line-height: 11px;
    }
  }
  position: fixed;
  right: 0;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99999;
  .collection-item {
    color: #ddd;
    height: 90%;
    text-align: center;
    h2 {
      margin-bottom: 5px;
      white-space: nowrap;

      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      margin-right: 1px;
      a {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @media (min-width: $screen-md-min) {
          padding-right: 120px;
        }
      }
    }
    .search-inner-wrapper {
      background: transparent;
      border: none;
      height: 80%;
    }
    p,
    span {
      color: #fff;
    }
    .list-description {
      height: 100px;
      background: transparent;

      .list-location {
        display: block;

        font-size: 12px;
        margin-top: 4px !important;
        max-width: 67%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
        .small {
          font-size: 12px;
          position: relative;
          top: -1px;
        }
      }
    }
    .list-description h2 a {
      height: auto;
      @media (max-width: $screen-xs-max) {
        font-size: 18px;
      }
    }
    img {
      width: auto !important;
    }
  }
  .full-width-list {
    padding: $margin * 1.5;
    background: #111;
    border-top: 1px solid #222;
    border-bottom: 1px solid #000;

    @media (max-width: $screen-xs-max) {
      padding: $margin * 0.8;
    }
    h2 {
      margin: 0;
    }
  }
  a {
    display: inline-block;
    color: #fff;
    &:hover,
    &.active {
      color: white;
    }
    &.stop {
      color: #333;
      cursor: default;
    }
  }
  #play-slideshow {
    font-size: 18px;
    margin-right: 10px;
  }
  .slideshow-controls {
    height: 14px;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    display: block;
  }
  #slideshow-previous,
  #slideshow-next {
    color: #fff;
  }

  .collection-item .list-description {
    padding: 15px;
    background: #111;
    border-top: 1px solid #222;
    border-bottom: 1px solid #000;
    -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 0px;
    -moz-box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 0px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 4px 0px;
    height: 130px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    text-align: left;
    overflow-y: scroll;
    @media (min-width: $screen-sm-min) {
      padding: 14px 14px 14px 54px;
    }
  }
  .collection-item .list-description .list-name {
    margin-bottom: 0;
    font-size: 13px;
  }

  .slide-show-lg-img.hidden {
    display: block !important;
    visibility: visible !important;
    width: 90%;
    margin: 0 auto;
    height: 100%;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: 50%;
    @media (max-width: $screen-xs-max) {
      position: absolute;
      bottom: 115px;
      top: 0px;
      left: 0;
      right: 0;
      height: auto;
    }
  }
  .list-image {
    display: none !important;
  }
}

.full-width-list > ul {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin: 0;
}

.full-width-list > ul > li {
  float: none;
  display: table-cell;
  padding-left: 0;
}

.full-width-list.bottom-list {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 12px;
  border-bottom: none;
}

.full-slide-show small {
  font-size: 13px;
}
