@import '../../../animate';
@import '../../../vars';

.container-2-column {
  background: #fff;
  p a {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  margin-bottom: $margin * 1.5;
  .row .col-md-6:first-child .blocker {
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      margin-bottom: 16px;
    }
  }
  .extra-margin-top {
    margin-top: 20px;
    @media (max-width: 991px) {
      margin-top: 30px;
    }
    @media (max-width: 767px) {
      margin-top: 16px;
    }
  }
}
