@import '../../../animate';
@import '../../../vars';

.breadcrumb {
  padding: 15px 0;
  position: relative;
  background: $lighter-gray;
  .breadcrumb-toggle {
    position: absolute;
    right: 15px;
    top: 16px;
    display: none;
    @include animate(0.3);
    width: 12px;
    height: 12px;
    -ms-transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    cursor: pointer;
    i {
      font-size: 12px;
      position: absolute;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 15px / $mobile-ratio 0;
    .breadcrumb-toggle {
      display: inline-block;
    }
  }
  ul {
    @media only screen and (max-width: 767px) {
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 30px);
    }
    li {
      position: relative;
      font-size: $paragraph-small;
      line-height: $paragraph-small-line-height;
      margin-bottom: 0;
      margin-right: 0;
      @media only screen and (max-width: 767px) {
        overflow: hidden;
        white-space: nowrap;
        max-height: 21px;
        display: inline-block;
        max-width: 22%;
        font-size: $paragraph-small-mobile;
        line-height: $paragraph-small-mobile-line-height;
        margin-bottom: 0;
      }
      color: $collection-object-secondary-text-color;
      &.chevron {
        width: 6px;
        color: #737373;
        text-align: center;
        &:last-child {
          display: none;
        }
      }
      &:last-child:after {
        display: none;
      }
    }
  }
  .open {
    .breadcrumb-toggle {
      @include transform(rotate(180deg));
    }
    ul {
      display: block;
      overflow: visible;
      white-space: normal;
      li {
        display: block;
        margin-bottom: 10px;
        max-height: none;
        overflow: visible;
        white-space: normal;
        max-width: 100%;
        width: 100%;
        a {
        }
        &:last-child,
        &:nth-last-child(2) {
          margin-bottom: 0;
        }
        &.chevron {
          display: none;
        }
      }
    }
  }
}
