@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@include legacy-full {
  .ng-hide {
    .facet-list {
      margin-bottom: 0;
    }
  }

  .back-to-search {
    display: none;
  }

  @media (min-width: 992px) {
    .colection-intro .collection-search {
      display: none;
    }
  }

  .nav-doubleflip {
    z-index: 99999;
    position: relative;
    float: left;
  }

  #load-more-mobile {
    display: none;
    .col-xs-12 {
      padding: 0 16px;
    }
    .panel-default {
      background: $lighter-gray;
      border: none;
      border-radius: 0;
      margin: 0 -16px 16px -16px;
      .panel-body {
        padding: 16px;
      }
      input {
        margin-bottom: 20px;
      }
      .btn {
        font-family: $paragraph-font;
      }
    }
  }

  .nav-doubleflip .jump-to {
    -webkit-perspective: 1000px;
    perspective: 1000px;
    display: block;
  }

  .nav-doubleflip .prev {
    -webkit-perspective-origin: 100% 50%;
    perspective-origin: 100% 50%;
    left: 0;
    top: 0px;
    position: absolute;
  }

  .nav-doubleflip .icon-wrap {
    font-family: $paragraph-font !important;
    position: relative;
    z-index: 100;
    display: block;
    width: 90px;
    padding: 18px 5px;
    height: 54px;
    color: $link-color;
    -webkit-transition: all 0.3s 0.3s;
    transition: all 0.3s 0.3s;
    top: -12px;
    text-transform: uppercase;
    @include set-text($tag-size);
    line-height: 21px;
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
    }
  }

  .nav-doubleflip svg.icon {
    position: relative;
    top: 50%;
    -webkit-transition: fill 0.3s 0.3s;
    transition: fill 0.3s 0.3s;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    fill: #fff;
  }

  .nav-doubleflip div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }

  .nav-doubleflip .jump-to div,
  .nav-doubleflip .jump-to h3 {
    font-family: $paragraph-font !important;
    top: -100%;
    -webkit-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    @include set-text($tag-size);
    line-height: 21px;
    @media only screen and (max-width: $mobile-breakpoint) {
      @include set-text($tag-size-mobile);
      line-height: 20px;
    }
  }

  .nav-doubleflip .level-two,
  .nav-doubleflip h3 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .nav-doubleflip .level-two input {
    background: #fff;
    height: 52px;
  }

  .nav-doubleflip .level-two .input-group-btn {
    top: -1px;
  }

  .nav-doubleflip .level-two .btn {
    height: 52px;
    padding: 6px 10px;
    border-radius: 0 3px 3px 0;
    font-family: $paragraph-font !important;
    margin-left: -3px;
  }

  .nav-doubleflip .level-two {
    position: absolute;
    bottom: 0px;
    width: 280px;
    height: 78px;
    top: -49px !important;
    background: $lighter-gray;
    padding: 12px;
    border-radius: 3px;
    &:after {
      border-color: #f8f8f8 transparent transparent;
      border-style: solid;
      border-width: 15px 12px 0;
      bottom: -15px;
      content: '';
      height: 0;
      left: 30px;
      position: absolute;
      width: 0;
    }
  }

  .nav-doubleflip h3 {
    display: none;
  }

  .nav-doubleflip .jump-to:hover .icon-wrap {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .nav-doubleflip .jump-to:hover svg.icon {
    fill: #000;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .nav-doubleflip .jump-to:hover div,
  .nav-doubleflip .jump-to:hover h3 {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }

  .nav-doubleflip .jump-to:hover div {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
  }

  .nav-doubleflip .jump-to:hover h3 {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }

  .lt-ie10 {
    .jump-to {
      .nav-doubleflip .level-two,
      .nav-doubleflip h3 {
        display: none;
      }
      .level-two {
        .btn {
          position: absolute;
          right: 0;
          top: 0px;
          z-index: 99;
        }
      }
    }
    .jump-to:hover {
      .nav-doubleflip .level-two,
      .nav-doubleflip h3 {
        display: inline-block;
      }
    }
  }
}

/*
  Collection search
  ************************************
  */

// .page-collection-search {
//   h1.title {
//     margin-bottom: $margin !important;
//   }
// }

// .page-collection-search .region-content {
//   .btn {
//     overflow: hidden;
//     display: inline-block;
//     .icon-cross {
//       padding-right: 10px;
//       &:before {
//         content: '\e92c';
//       }
//     }
//   }
// }

// .page-collection-search {
//   h1.title {
//     margin-bottom: $margin !important;
//   }
// }

.page-collection-search .region-content,
.node-type-collection-landing-page .region-content {
  .btn {
    overflow: hidden;
    display: inline-block;
    .icon-cross {
      padding-right: 10px;
      &:before {
        content: '\e92c';
      }
    }
  }
}

.input-group-btn:last-child > .btn.icon-RCT-search {
  padding: 11px 14px;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  min-height: 45px;
  @media only screen and (min-width: 768px) {
    padding: 12px 15px;
    font-size: 17px;
    line-height: 22px;
    min-height: 48px;
  }
  &:hover {
    background: $link-color-hover;
    color: #fff;
  }
}

.page-collection-near-you {
  .search-controls .row {
    margin: 0 -10px;
  }
  @media only screen and (max-width: 767px) {
    .search-controls .row {
      margin: 0;
    }
    #search-controller {
      .full-container,
      .container {
        padding: 0;
      }
      .grey-background,
      .alt-breadcrumb {
        .full-container,
        .container {
          padding: 0 16px;
        }
      }
      .collection-object-image-area__container {
        &.full-container,
        &.container {
          padding: 0 16px;
        }
      }
    }
    .search-controls {
      margin: 0 !important;
    }
    .search-controls .col-xs-8 {
      width: 100%;
      padding: 16px 16px 0 16px;
    }
  }
  @media only screen and (min-width: 768px) {
    #search-controller .grey-background {
      padding-top: 30px;
    }
  }
  .loading-wrapper {
    background: #f8f8f8;
  }
}

.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes,
.page-whatson .region-content,
.page-schools .region-content,
.page-collection-search .region-content,
.page-collection-near-you .region-content,
.node-type-collection-landing-page .region-content,
.node-type-location .region-content,
.node-type-people .region-content {
  /* Hero on search pages */
  .hero-search {
    h1 {
      color: $link-color;
      margin: 16px 0;
      text-align: center;
      @media only screen and (min-width: 768px) {
        margin: 40px 0;
      }
    }
    h3 {
      text-align: left;
    }

    @media only screen and (max-width: 767px) {
      &.m-b-xl {
        margin-bottom: 0;
      }
    }
  }

  /* Collection theme search */
  .collection-filter {
    margin-bottom: 30px;
    @media only screen and (max-width: 991px) {
      .icon-RCT-search {
        background: $link-color;
        display: block;
        position: absolute;
        left: -52px;
        color: white;
        width: 52px !important;
        height: 52px;
        &:hover,
        &:focus {
          background: $link-color-hover;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
      .icon-RCT-search {
        width: 48px !important;
        height: 48px !important;
        left: -48px;
      }
    }
    .breadcrumb-wrapper {
      padding: 0;
    }
    p {
      margin-bottom: 5px;
      font-family: $paragraph-font-bold;
      font-size: 17px;
      line-height: 21px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .collection-divider {
    margin: 0 0 30px;
    @media only screen and (max-width: 767px) {
      margin: 0 0 16px;
    }
  }
}

.page-resources #search-themes .collection-filter .icon-RCT-search,
.page-collection-themes-search #search-themes .collection-filter .icon-RCT-search {
  @media only screen and (max-width: 767px) {
    height: 52px;
  }
}

.loader-cont {
  position: absolute;
  right: 15px;
  top: calc(50% - 1px);
  transform: translateY(-50%);
  height: 30px;
  width: 30px;
  z-index: 10;

  @media only screen and (min-width: 767px) {
    top: calc(50% + 1px);
  }
}

.collection-main-search {
  .loader-cont {
    top: 50%;
    right: 0px;
  }
}

.node-type-collection-landing-page {
  .collection-main-search {
    width: calc(100% - 40px);
    @media only screen and (min-width: 767px) {
      width: calc(100% - 52px);
    }
  }
  form {
    position: relative;
    display: flex;
  }
  .dropdown-menu.helper {
    margin-top: -18px;
  }
  .dropdown-menu {
    width: calc(100% - 4px);
    margin-top: -2px;
    border-radius: 0;
    background: #f8f8f8;
  }
  a.icon-search.icon-RCT-search.btn.btn-info {
    @media only screen and (min-width: 767px) {
      top: -4px;
    }
  }
  .loader-cont {
    top: calc(50% - 5px);
    transform: translateY(-50%);
    right: 10px;
    @media only screen and (min-width: 767px) {
      top: calc(50% - 10px);
      transform: translateY(-50%);
    }
  }
}
