@import '../../../animate';
@import '../../../vars';

.paragraph-shop-link {
  .grey-card {
    .inner {
      overflow: hidden;
      display: flex;
      align-items: flex-start;
      .right-text {
        flex: 1;
        padding-left: 20px;
        @media only screen and (max-width: 767px) {
          padding: 0 0 0 10px;
        }
      }
      .left-image {
        width: 180px;
        @media only screen and (max-width: 767px) {
          width: 90px;
        }
        img {
          width: 100%;
        }
      }
    }
    .price {
      color: $paragraph-subtitle-color;
      @media only screen and (min-width: 768px) {
        margin-top: 10px;
      }
    }
  }

  .btn {
    font-size: 15px;
    line-height: 19px;
    padding: 11px 15px;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      padding: 10px 14px;
    }
  }

  .max-container-width {
    > .m-b-lg:last-child {
      margin-bottom: 0;
    }
  }
  + .paragraph-promotion {
    padding-top: 0 !important;
  }
}
