@import '../../../animate';
@import '../../../vars';

.paragraph-taxonomy-group-listing {
  @media screen and (max-width: 767px) {
    margin-bottom: $margin * 0.8;
  }

  ul.tabs {
    padding: 0;
    display: table;
    list-style: none;
    width: 100%;
    margin-bottom: $margin * 1.5;
  }

  ul.small-tabs {
    width: auto;
  }

  ul.tabs li {
    display: table-cell;
    margin: 0;
    cursor: pointer;
    border-left: 1px solid #fff;
    background-color: $link-color;
    color: #fff;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    text-align: center;
    padding: 12px 15px;
    font-size: 16px;
    line-height: 21px;
    vertical-align: middle;
    @media screen and (min-width: 768px) {
      padding: 13px 16px;
      font-size: 17px;
      line-height: 22px;
    }
  }

  .tab_last {
    border-right: 1px solid $link-color;
  }

  ul.tabs li:hover {
    background-color: $link-color-hover;
    color: #fff;
  }

  ul.tabs li.active {
    background-color: transparent;
    color: $paragraph-body-color;
  }

  ul.tabs li:first-child {
    border-left: 1px solid $link-color;
  }

  .tab_container {
    border-top: none;
    width: 100%;
    background: #fff;
  }

  .tab_content {
    display: none;
  }

  .tab_drawer_heading {
    display: none;
  }

  &__description {
    margin-bottom: $margin * 1.5;
    @media screen and (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
  }

  .icon-RCT-down {
    display: none;
  }

  @media screen and (max-width: 767px) {
    ul.tabs {
      display: none;
    }

    .tab_drawer_heading {
      border-top: 1px solid $hr-border;
      margin: 0;
      padding: 16px 20px 16px 0;
      display: block;
      cursor: pointer;
      color: $link-color;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:hover {
        color: $link-color-hover;
      }
    }
    .tab_drawer_heading_last {
      border-bottom: 1px solid $hr-border;
    }
    .tab_drawer_heading_last.d_active {
      border-bottom: none;
    }

    .tab_container {
      width: auto;
      margin: 0 $margin * 0.8;
    }

    .container,
    .full-container {
      padding: 0;
    }

    .icon-RCT-down {
      color: $link-color;
      display: block;
      font-size: 12px;
      position: absolute;
      top: 22px;
      right: 0;
      transition: all 0.3s ease;
    }

    .d_active .icon-RCT-down {
      transform: rotate(180deg);
    }
    .mobile-white.p-t-b-lg {
      padding: 0;
    }
    &__description-container {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .accordion-title-block {
    position: relative;
  }
}
