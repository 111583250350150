@import '../../../animate';
@import '../../../vars';

.paragraph-whats-on-tab-listing {
  // We need to fix the height of the carousel to prevent any hashed links jumping
  // out of position once the carousel loads and pushes the content down.
  min-height: 550px;

  // Hacky hack. We need a width on all tabs to init the carousels properly.
  // Display: none implies no width so we do it differently.
  .event-tab-content > .tab-pane {
    display: block; /* undo display:none          */
    height: 0; /* height:0 is also invisible */
    overflow: hidden; /* no-overflow                */
  }
  .event-tab-content > .active {
    height: auto; /* let the content decide it  */
    overflow: visible;
  }

  @media only screen and (max-width: 767px) {
    &.grey-background {
      background: #fff;
    }
    &.p-t-lg {
      padding-top: 0;
    }
  }

  .text-center {
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
  }

  .nav-tabs {
    margin-bottom: 30px;
    display: table;
    list-style: none;
    width: 100%;
    border: none;
    li {
      border-left: 1px solid #fff;
      text-transform: uppercase;
      text-align: center;
      font-size: 16px;
      line-height: 21px;
      vertical-align: middle;
      display: table-cell;
      float: none;
      background-color: $link-color;
      @media only screen and (min-width: 768px) {
        font-size: 17px;
        line-height: 22px;
      }
      &:first-child {
        border-left: 1px solid $link-color;
      }
      &:last-child {
        border-right: 1px solid $link-color;
      }
      a {
        padding: 12px 15px;
        color: #fff;
        margin: 0;
        border-radius: 0;
        border: none;
        line-height: 21px;
        @media only screen and (min-width: 768px) {
          padding: 13px 16px;
          line-height: 22px;
        }
        &:hover,
        &:focus {
          background: $link-color-hover;
        }
      }
      > li.active > a,
      > li.active > a:hover,
      > li.active > a:focus {
        border: none;
      }
      &.active {
        background: transparent;
        a {
          background-color: transparent;
          color: #333;
          border: none;
          &:hover,
          &:focus {
            background-color: transparent;
            color: #333;
            border: none;
          }
        }
      }
    }
  }

  .nav-tabs.small-tabs {
    @media only screen and (min-width: 768px) {
      width: auto;
    }
  }

  .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
    width: 100%;
  }

  .event-tab-filter {
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    .bootstrap-select {
      width: 100%;
      .caret {
        @include icomoon;
        margin-top: -3px;
        right: 15px;
        font-size: 12px;
        position: absolute;
        top: 22px;
        border: none;
        width: auto;
        height: auto;
        transition: all 0.3s ease;
        transform: rotate(0deg);
        &:before {
          content: '\E913';
        }
      }
      &.open {
        .caret {
          transform: rotate(180deg);
        }
      }
    }
    .bootstrap-select > .dropdown-toggle {
      white-space: nowrap;
      position: relative;
    }

    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 10px;
      z-index: 1000;
      float: left;
      min-width: 100%;
      padding: 0px;
      margin: 0;
      list-style: none;
      text-align: left;
      background-color: $gray;
      background-clip: padding-box;
      border: none;
      box-shadow: none;
      width: 100%;
      font-size: 17px;
      line-height: 21px;
      &.inner {
        max-width: 100%;
      }
      li {
        position: relative;
      }
      > li > a {
        padding: 15px;
        border-bottom: 1px solid #fff;
        white-space: inherit;
        &:hover,
        &:focus {
          background: #f8f8f8;
          color: #8a2a2b;
        }
      }
    }
  }

  .slick-dots {
    display: none !important;
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .slick-prev,
  .slick-next {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $link-color;
    border: 1px solid white;
    top: 130px;
    &:hover,
    &:focus {
      background: $link-color-hover;
    }
    @media only screen and (max-width: 991px) {
      top: 31.5%;
    }
    &:before {
      @include icomoon;
      font-size: 12px;
      opacity: 1;
    }
  }

  .slick-next {
    right: -11px;
    &:before {
      content: '\E902';
      margin-left: 4px;
    }
  }

  .slick-prev {
    left: -11px;
    &:before {
      content: '\E915';
      margin-left: -2px;
    }
  }

  /* mobile */

  .collapse {
    display: none;
  }

  .collapse.in {
    display: block;
  }

  .collapsing {
    position: relative;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    -o-transition: height 0.35s ease;
    transition: height 0.35s ease;
  }

  .panel-group {
    margin-bottom: 0;
    border-bottom: 1px solid $hr-border;
    .panel-default {
      border: none;
      box-shadow: none;
      margin-bottom: -5px;
      background: transparent;
    }
    .panel-heading {
      position: relative;
      padding: 0;
      border-top: 1px solid $hr-border;
      color: #8a2a2b;
      border-radius: 0;
      h4 {
        font-size: 20px;
        line-height: 23px;
        a {
          display: block;
          padding: 16px 20px 16px 0;
          &.collapsed {
            .caret {
              transform: rotate(0deg);
            }
          }
          .caret {
            @include icomoon;
            border: none;
            font-size: 12px;
            position: absolute;
            right: 12px;
            top: 22px;
            transition: all 0.3s ease;
            width: auto;
            height: auto;
            transform: rotate(180deg);
            &:before {
              content: '\e913';
            }
          }
        }
      }
    }
  }

  .panel-body {
    padding: 0;
    border-top: none;
  }

  .panel-group .panel-heading + .panel-collapse > .panel-body {
    border-top: none;
  }

  .panel-default > .panel-heading {
    background-color: transparent;
  }
}
