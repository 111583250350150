@import '../../../animate';
@import '../../../vars';

@mixin legacy-pages {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content {
    @content;
  }
}

@include legacy-pages {
  /* Search view options */

  .search-view p {
    display: block;
    float: left;
  }

  .search-view ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;
    li {
      float: left;
      a {
        display: block;
        background: $link-color;
        color: #fff;
        border-right: 1px $link-color solid;
      }
      &:last-child a,
      &.last a {
        border-right: none;
      }
    }
    @media (max-width: $screen-xs-max) {
      li a {
        padding: 8px;
      }
    }
  }

  .search-view li.active a,
  .search-view li a:hover,
  .search-view li a:focus {
    background: $link-color;
    color: #fff;
    text-decoration: none;
  }

  .search-view li.active a:hover,
  .search-view li.active a:focus {
    background: $link-color-hover;
  }

  .angular-typehead {
    .input-group-btn {
      position: absolute;
      top: -4px;
      right: -48px;
      padding: 0px;
      @media only screen and (max-width: 767px) {
        right: -44px;
      }
      @media only screen and (max-width: 991px) {
        top: 0px;
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 100%;
    padding: 0px;
    margin: 0;
    list-style: none;
    text-align: left;
    background-color: $gray;
    background-clip: padding-box;
    border: none;
    box-shadow: none;
    width: 100%;
    > li > a {
      padding: 5px 15px 5px 15px; // aw amend

      white-space: inherit;
      &:hover,
      &:focus {
        background: $lighter-gray;
        color: $link-color;
        small {
          color: #fff;
        }
      }
    }
    li a.disabled {
      border-bottom: none;
      font-size: 19px;
      line-height: 25px;
      color: #333;
      font-family: $paragraph-font-bold;
      &:hover,
      &:focus {
        background-color: $gray;
        color: #333;
        font-family: $paragraph-font-bold;
      }
      @media only screen and (max-width: 991px) {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    background: $lighter-gray;
    color: $link-color;
    small {
      color: $link-color;
    }
  }

  .open > .dropdown-menu {
    display: block !important;
  }

  .btn-group {
    position: relative;
  }

  .copy-widget {
    position: fixed;
    top: -99999px;
    z-index: 9999999;
    color: #fff;
  }

  .copy-widget.visible {
    position: fixed;
    top: 250px;
    width: 100%;
    left: 0;
  }

  /* Search view options */

  .search-view p {
    display: block;
    float: left;
    padding: 10px 10px 0 0;
  }

  .search-view ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;
    li {
      float: left;
      a {
        display: block;
        background: #fff;
        padding-top: 14px;
        border: 1px $link-color solid;
        width: 50px;
        height: 50px;
        text-align: center;
        color: $link-color;
        font-size: $paragraph-size;
        border-radius: 50%;
        margin-left: 10px;
      }
      &:last-child a,
      &.last a {
        border-right: 1px $link-color solid;
      }
    }
    .play {
      font-size: 22px;
      padding-top: 13px;
    }
  }

  .search-view li.active a,
  .search-view li a:hover,
  .search-view li a:focus {
    background: $link-color;
    color: #fff;
    text-decoration: none;
  }

  .input-group-addon,
  .input-group-btn {
    width: auto;
  }

  .angular-typehead.input-group.collection-main-search {
    width: calc(100% - 53px);
    position: relative;
    top: 5px;

    @media (max-width: 991px) {
      display: none;
    }
  }
}
