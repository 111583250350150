@import '../../../animate';
@import '../../../vars';

.location-header-block {
  margin-bottom: $margin * 1.5;

  a {
    border-bottom: 1px dotted $link-color;
  }

  @media (max-width: 767px) {
    margin-bottom: $margin * 0.8;
    margin: 0 $margin * 0.8;
  }

  &__description-container {
    margin-bottom: $margin * 1.5;
    @media (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
  }

  &__row {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__button,
  &__accordion-menu {
    flex: 1 1 33%;
    margin-right: 20px;
    text-align: center;
    @media (max-width: 767px) {
      margin: 0 0 $margin * 0.6 0;
    }
  }

  &__button:last-of-type {
    margin-right: 0;
    @media (max-width: 767px) {
      margin-bottom: $margin * 0.8;
    }
  }

  &__accordion-button {
    width: 100%;
    text-align: left;
    @media (max-width: 767px) {
      text-align: center;
    }

    > i {
      position: absolute;
      display: inline-block;
      font-size: 12px;
      top: 50%;
      margin-top: -6px;
      right: 19px;
      transition: all 0.3s ease;
      transform: rotate(0deg);
      @media (max-width: 767px) {
        margin-top: -12px;
      }
    }

    .rotated {
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }

  &__accordion-menu {
    position: relative;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  &__accordion-content {
    width: 100%;
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease;
    &.open {
      z-index: 999;
    }
    @media (max-width: 767px) {
      margin-top: -16px;
    }

    > ul > li {
      background-color: #f4f4f4;
      border-bottom: 1px solid #fff;
      text-align: left;
      &:hover {
        background: #f8f8f8;
      }
    }
    > ul > li > a {
      display: block;
      padding: 15px;
      color: #000;
      &:hover {
        color: $link-color;
        cursor: pointer;
      }
    }
  }

  .open {
    opacity: 1;
    transition: all 0.5s ease;
  }
}
