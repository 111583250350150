@import '../../../animate';
@import '../../../vars';

.admin-message {
  .alert {
    position: relative;
    background: $paragraph-subtitle-color;
    color: #fff;
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
    &.alert-success {
      background: #50c355;
    }
    &.alert-info {
      background: $paragraph-subtitle-color;
    }
    &.alert-error {
      background: #d44547;
    }
    &.alert-event {
      background: $link-color;
    }
    h3,
    h4,
    h5 {
      color: #fff;
    }
    a {
      color: #fff;
      border-bottom: 1px dotted white;
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
  .close {
    display: none;
  }
  @media only screen and (max-width: 767px) {
    .alert {
      padding: 16px;
      margin-bottom: 16px;
    }
  }

  ul ul li {
    list-style-type: disc;
    list-style-position: inside;
  }
  ul ol li {
    list-style-type: decimal;
    list-style-position: inside;
  }
}
