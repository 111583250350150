@import '../../../../animate';
@import '../../../../vars';

$highlighted: #4d4d4d;

.search-auto-complete {
  width: 100%;
  margin-bottom: 32px;
  @media screen and (min-width: 767px) {
    max-width: 550px;
    margin-bottom: 16px;
  }
  &__input {
    display: flex;
    input {
      margin: 0; // Safari reset
      border-right: 0;
      outline: none;
      background-size: 0;
      -webkit-border-radius: 0;
      -webkit-appearance: none;
    }
    button {
      margin: 0; // Safari reset
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &:before {
        @include icomoon;
        content: '\e901';
      }
    }
  }
  &__autocomplete {
    width: 100%;
    background: $lighter-gray;
    border: 1px solid $form-fields;
    border-top: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-bottom: 10px;

    &__input-text {
      display: block;
      position: relative;
      font-size: 16px;
      line-height: 21px;
      padding: 6px 29px;
      color: $paragraph-subtitle-color;

      &.active {
        background-color: rgba(0, 0, 0, 0.07);
      }

      > div {
        cursor: pointer;
      }

      @media screen and (min-width: 767px) {
        font-size: 17px;
        line-height: 22px;
        padding: 6px 30px;
      }
      &::before {
        @include icomoon;
        content: '\e902';
        font-size: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
      }
    }

    &__keyword {
      color: $highlighted;
    }

    &__title {
      display: block;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      color: $paragraph-subtitle-color;
      padding: 6px 14px;
      @media screen and (min-width: 767px) {
        font-size: 17px;
        line-height: 22px;
        padding: 6px 15px;
      }
    }

    &__loader {
      display: block;
      font-size: 16px;
      line-height: 21px;
      padding: 6px 29px;
      opacity: 0.7;
      @media screen and (min-width: 767px) {
        font-size: 17px;
        line-height: 22px;
        padding: 6px 30px;
      }
    }

    li {
      position: relative;
      font-size: 16px;
      line-height: 21px;
      padding: 4px 29px;
      cursor: pointer;

      @media screen and (min-width: 767px) {
        font-size: 17px;
        line-height: 22px;
        padding: 4px 30px;
      }
      &::before {
        @include icomoon;
        content: '\e902';
        font-size: 5px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
      }
      &.active {
        background-color: rgba(0, 0, 0, 0.07);
      }
    }
    &__name {
      color: $paragraph-subtitle-color;
    }
    &__subject {
      color: $light-gray;
    }
    &__count {
      color: $light-gray;
    }
  }
  &__no-suggestions {
    font-size: 16px;
    line-height: 21px;
    padding: 6px 29px;

    @media screen and (min-width: 767px) {
      font-size: 17px;
      line-height: 22px;
      padding: 6px 30px;
    }
  }
  &__loading {
    font-size: 16px;
    line-height: 21px;
    padding: 6px 29px;

    @media screen and (min-width: 767px) {
      font-size: 17px;
      line-height: 22px;
      padding: 6px 30px;
    }
    span {
      display: inline-block;
      background-image: url(./loader.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 20px;
      margin-bottom: -6px;
      margin-left: 8px;
    }
  }
  mark {
    background-color: transparent;
    color: $highlighted;
  }
}

.search-auto-complete-wrapper {
  .search-auto-complete {
    &__autocomplete {
      &__input-text {
        width: 100%;
        padding: 6px 24px;
        background: $white;

        @media screen and (min-width: $menu-break + 1) {
          padding: 15px 40px;
        }

        &:before {
          top: 0;
          margin-top: 22px;
        }

        &.open {
          padding: 0 0 5px 0;
          border-left: 1px solid $form-fields;
          border-right: 1px solid $form-fields;

          &.continue-typing {
            height: 80vh;

            @media screen and (min-width: $menu-break + 1) {
              height: auto;
            }
          }

          > div {
            width: 100%;
            background: $lighter-gray;
            border-top: 0;
            padding: 10px 5px 10px 30px;
          }
        }
      }
    }
  }
}
