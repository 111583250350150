@import '../../../animate';
@import '../../../vars';

@mixin legacy-full {
  .page-resources,
  .page-schools-resources,
  .page-collection-conservation,
  .page-collection-themes-search,
  .page-whatson .region-content,
  .page-schools .region-content,
  .page-collection-search .region-content,
  .page-collection-near-you .region-content,
  .node-type-collection-landing-page .region-content,
  .microsite-search {
    @content;
  }
}

@include legacy-full {
  .remove-filter {
    background: $link-color;
    padding: 7px 9px 4px 9px;
    float: left;
    color: #fff;
    transition: all 0.3s ease-out;
  }

  .remove-filter:hover,
  .remove-filter:focus {
    text-decoration: none;
    color: #fff;
    background: $link-color-hover;
  }

  .remove-filter .icon-cross {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  /*
  * Responsive filters
  *********************************************************************************/

  #people-list,
  .people-list {
    .search-result .list-description {
      height: 155px !important;
      min-height: 0px !important;
      p {
        a {
          width: 75%;
          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            width: 60%;
          }
          position: relative;
        }
      }
    }
    .search-tile .item-title {
      min-height: 84px;
    }
  }

  p.label-link {
    line-height: 14px;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
    label {
      position: relative;
      top: -3px;
      margin-right: 4px;
      margin-bottom: 1px;
      font-weight: normal;
    }
    .sep {
      position: relative;
      top: -3px;
    }

    .icon-location {
      font-size: 12px;
      position: relative;
      top: -3px;
    }
    .ellipsis {
      max-width: 90%;
      max-width: 100%;
      height: 15px;
    }
    &.view-of {
      > span:first-of-type {
        .ellipsis {
          max-width: 70%;
        }
      }
    }
    &.from {
      > span:first-of-type {
        .ellipsis {
          max-width: 80%;
        }
      }
    }
  }

  .search-tile .list-location {
    @media (min-width: 768px) {
      position: absolute;
      bottom: 15px;
      right: 30px;
      left: 30px;
    }
  }

  @media (min-width: 768px) {
    .search-result .list-description {
      min-height: 200px !important;
    }
  }

  @media (min-width: 1100px) {
    .search-result .list-description {
      min-height: 0px !important;
      height: 210px;
    }
    .node-type-location .search-result.search-list .list-description {
      min-height: 0px !important;
      height: 320px;
    }
  }

  /*
  * Responsive filters
  *******************************************************************************/
  .collection-near-you {
    .search-controls {
      margin: 20px 0px 20px 10px;
      padding: 0;
      .select-dropdown .btn-success {
        background: #fff;
      }
      .search-view ul li a {
        background: #fff;
      }
      .search-view li.active a,
      .search-view li a:hover,
      .search-view li a:focus {
        background: #1e2d5a;
      }
    }
    .select-facet {
      color: #f4f4f4;
    }
  }

  .search-results {
    overflow-anchor: none;
  }
}
