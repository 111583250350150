@import '../../../animate';
@import '../../../vars';

.search-results-cards {
  padding: $margin * 1.5 0;
  background: $lighter-gray;
  @media only screen and (max-width: 767px) {
    padding: 0 0 $margin * 0.8 0;
    background: $body-background;
  }
  &__title,
  &__descr {
    text-align: center;
  }
  &__the-card {
    background: #fff;
    margin-bottom: 5px;
    padding: 16px;
    @media only screen and (max-width: 767px) {
      margin-bottom: 10px;
      padding: 0 0 10px 0;
      border-bottom: 1px solid $lighter-gray;
      &:first-child {
        padding-top: 10px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .inner {
      display: flex;
      align-items: flex-start;
      .tag {
        margin-bottom: 5px;
      }
      h6 {
        color: $link-color;
        margin-bottom: 5px;
        &.ellipsis-line {
          display: inline-block;
          max-height: 50px;
          overflow: hidden;
          width: 100%;
          @media only screen and (max-width: 767px) {
            max-height: 72px;
          }
        }
      }
      .right-text {
        flex: 1;
      }
      .left-image {
        overflow: hidden;
        width: 130px;
        padding-right: 10px;
        @media only screen and (min-width: 768px) {
          height: 120px;
        }

        a {
          display: block;
        }
        @media only screen and (max-width: 767px) {
          width: 100px;
          a {
            width: 90px;
          }
        }
        img {
          width: 120px;
          height: 120px;
          object-fit: contain;
          object-position: top center;
        }
      }
    }
    .icon-RCT-download2 {
      padding-right: 5px;
    }
    .tag {
      font-size: 15px;
      line-height: 19px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    .description {
      color: $paragraph-subtitle-color;
      margin-bottom: 0px;
      @media only screen and (min-width: 768px) {
        font-size: 17px;
        line-height: 21px;
      }
      &.ellipsis-line {
        display: inline-block;
        max-height: 45px;
        overflow: hidden;
        width: 100%;
        @media only screen and (max-width: 767px) {
          max-height: 87px;
        }
      }
    }
  }
}

.search-results-cards__card-list {
  .card-collection-object-near-you-container .location-pop-up__text {
    @media only screen and (min-width: 768px) {
      width: 50%;
    }
  }
}
