@import '../../../animate';
@import '../../../vars';

.paragraph-image-text-overlay {
  $root: &;
  display: flex;
  background-color: $lighter-gray;
  overflow: hidden;

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    @media only screen and (min-width: $screen-max-container-max) {
      width: $container-max;
      padding: 0 $margin * 2;
    }
    @media only screen and (min-width: 768px) and (max-width: $screen-max-container-max) {
      width: 100%;
      padding: 0px 30px;
    }
    @media only screen and (min-width: 1120px) and (max-width: $screen-max-container-max) {
      padding: 0px 30px;
      padding: 0px calc(30px + 7%);
      width: 100%;
      padding: 0px 30px;
    }
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    @media screen and (min-width: $screen-sm) {
      margin: auto;
    }
  }

  &__media-wrapper {
    position: relative;
    order: 2;
    min-height: 400px;
    overflow: hidden;
    @media screen and (min-width: $screen-sm) {
      order: 1;
      min-height: 590px;
      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }
  }

  &__media {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    img {
      display: block;
    }
  }

  &__content-wrapper {
    margin: -45px 16px 20px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 -10px 15px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 25px 30px;
    order: 2;
    z-index: 1;

    @media screen and (min-width: $screen-sm) {
      background-color: rgba(255, 255, 255, 0.85);
      position: absolute;
      transform: none;
      bottom: 45px;
      right: 30px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 40px 30px;
      margin: 0;
      width: calc(50% - 40px);
    }
    @media screen and (min-width: $screen-md) {
      width: calc(50% - 150px);
      margin-right: 60px;
    }

    @media only screen and (min-width: $screen-md) and (max-width: $screen-max-container-max) {
      width: calc(50% - 140px);
      margin-right: 50px;
    }
    @media only screen and (min-width: 1300px) {
      width: calc(50% - 150px);
      margin-right: 60px;
    }
  }

  &__content-body {
    &,
    p {
      font-family: $titles-font;
      font-size: 20px;
      line-height: 1.15;
      color: $titles-color;
      text-align: center;
      margin-bottom: 16px;
      @media screen and (min-width: $screen-sm) {
        font-size: 24px;
        line-height: 1.13;
        margin-bottom: 30px;
      }
    }
  }

  &__caption-wrapper {
    width: 100%;
    position: absolute;
    transform: translateY(-100%);
    color: $paragraph-subtitle-color;
    background-color: $lighter-gray;
    padding: 12px 16px;
    font-size: $paragraph-small;
    order: 1;
    transition: transform 0.3s ease-out;
    will-change: transform;
    z-index: 1;
    text-align: center;

    @media screen and (min-width: $screen-sm) {
      position: relative;
      transform: none;
      order: 2;
      text-align: left;
      padding: 10px 20px;

      width: 100vw;
      position: relative;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      margin-right: -50vw;
    }

    &--tab {
      position: absolute;
      bottom: -24px;
      right: 20px;
      background-color: $lighter-gray;
      text-align: center;
      width: 48px;
      height: 24px;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;
      @media screen and (min-width: $screen-sm) {
        display: none;
        text-align: left;
      }
      i {
        position: absolute;
        color: $brand-red;
        font-size: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        transition: transform 0.3s ease-out;
      }
    }

    a {
      border-bottom: 1px dashed $brand-red;
    }

    &--copyright {
      &,
      a {
        border-bottom: none !important;
      }
    }

    &.open {
      transform: translateY(0%);
      .paragraph-image-text-overlay__caption-wrapper--tab {
        i {
          transform: translate(-50%, -50%) rotate(180deg);
        }
      }
    }

    &.open {
      > a {
        font-family: $paragraph-font;
      }
    }
  }

  &--left {
    #{$root}__content-wrapper {
      @media screen and (min-width: $screen-sm) {
        left: 30px;
      }
      @media screen and (min-width: $screen-md) {
        margin-left: 60px;
      }
      @media only screen and (min-width: $screen-md) and (max-width: $screen-max-container-max) {
        margin-left: 50px;
      }
      @media only screen and (min-width: 1300px) {
        margin-left: 60px;
      }
    }
  }
}
