.explore-search {
  &.minus-margin {
    margin-top: -16px;
    @media screen and (min-width: 768px) {
      margin-top: -30px;
    }
  }

  .lp-search {
    height: 57px;
  }
  @media screen and (min-width: 992px) {
    .lp-search {
      height: 53px;
      input {
        margin-top: -4px;
      }
    }
  }
  .angular-typehead.input-group.collection-main-search {
    @media (max-width: 991px) {
      display: block !important;
    }
  }
}
