@import '../../../../animate';
@import '../../../../vars';

.react-search-component {
  a {
    cursor: pointer;
  }
  .no-results {
    padding-left: 8px;
    @media only screen and (min-width: $menu-break) {
      padding-left: 88px;
    }
  }
  .search-bar {
    position: absolute;
    @include animate(0.4);
    background: #fff;
    width: 100%;
    height: 100%;
    padding: 20px 20px;
    border-radius: 200px;
    margin-left: -70px;
    left: calc(100% - 30px);
    z-index: 2;

    .loader {
      width: 50px;
      position: absolute;
      top: 35px;
      background: #fff;
      z-index: -1;
      opacity: 0;
      @include animateDelay(0.4, 0);
      img {
        width: 100%;
      }
    }
    > .search-button {
      font-size: 22px;
      display: block;
      border: 1px solid transparent;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      cursor: pointer;
      float: left;
      padding: 14px 13px;
      margin-right: 25px;
      @include animateDelay(0.4, 0);
      i {
        @include animateDelay(0.4, 0);
        position: relative;
        color: $paragraph-body-color;
        top: -2px;
      }
      &:hover {
        border: 1px solid $link-color;
        i {
          color: $link-color;
        }
      }
    }
    > input {
      @include animateDelay(0.4, 0);
      width: 80%;
      opacity: 0;
      font-size: 22px;
      border: 0;
      border-left: 1px solid #818181;
      padding: 0 15px;
      outline: 0 !important;
      border-radius: 0;
    }
    .close-search {
      position: absolute;
      right: 40px;
      font-size: 24px;
      height: 24px;
      width: 24px;
      top: 50%;
      margin-top: -11px;
      i {
        position: relative;
        top: -4px;
      }
    }
  }
  .results {
    position: absolute;
    overflow: scroll;
    overflow-x: hidden;
    width: 100%;
    background: #fff;
    bottom: 0;
    z-index: -1;
    @include animate(0.4);

    border-top: 2px solid #f7f7f7;
    height: 400px;
    @media only screen and (max-width: $menu-break) {
      padding: 0 10px;
      height: 0;
    }
    a {
      display: block;
    }
    &.closed {
      height: 0;
    }
    h5,
    p {
      margin: 0;
    }
    h5 {
      color: $paragraph-body-color;
      margin-top: $margin * 1.5;
      font-family: $paragraph-font-bold !important;
      font-size: 22px;
      @media only screen and (max-width: $menu-break) {
        margin-top: 15px;
      }
      strong {
        font-family: $paragraph-font-bold !important;
      }
    }
    p {
      margin-bottom: $margin/2;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .inner {
      opacity: 0;
      @include animate(0.4);
      padding: 40px 24px;
      > a {
        padding-left: 87px;
        &.result {
          position: relative;
          display: block;
          &:first-child {
            h5 {
              margin-top: 0;
            }
          }
          .icon-RCT-arrow {
            display: inline-block;
            width: 20px;
            height: 20px;
            position: absolute;
            left: -10px;
            opacity: 0;
            @include animate(0.2);
            top: 50%;
            margin-top: -13px;
          }

          &.highlighted {
            background: #fefefe;
            * {
              color: $link-color;
            }
            .icon-RCT-arrow {
              opacity: 1;
              @include translate(20px, 0);
            }
          }
        }
      }
    }
  }
  .icon {
    width: 20px;
    height: 22px;
    background-image: url(/assets/img/search-glass.svg);
    background-repeat: no-repeat;
    display: block;
  }

  .search-auto-complete-wrapper {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: -1;
    transition: all 0.4s ease;
    border-top: 2px solid $white-alabaster;
    height: auto;

    .results.is-autocomplete {
      transition: none;

      .inner {
        padding: 0;
      }
    }

    .search-auto-complete {
      &__autocomplete {
        &__input-text {
          &.open {
            transform: translate(0, calc(100% - 1px));
          }
        }
      }
    }
  }

  &:not(.searching) {
    .search-auto-complete-wrapper {
      .search-auto-complete {
        &__autocomplete {
          &__input-text {
            &.open {
              border-bottom: 1px solid $form-fields;
              height: 69.5vh;

              @media screen and (min-width: $menu-break + 1) {
                height: auto;
              }
            }
          }
        }
      }
    }
  }

  &.searching {
    .search-auto-complete-wrapper {
      .results.is-autocomplete {
        border-left: 1px solid $form-fields;
        border-right: 1px solid $form-fields;
        border-bottom: 1px solid $form-fields;
        border-top: 0;
        bottom: -99%;
        height: 59vh;

        .inner {
          padding-left: 0;

          .search-auto-complete {
            &__autocomplete {
              padding: 0;

              li:last-child {
                margin-bottom: 15px;
              }
            }
          }

          .no-results {
            padding-left: 20px;
            color: $paragraph-subtitle-color;
          }
        }

        @media screen and (min-width: $menu-break + 1) {
          height: auto;
        }
      }
    }
  }
}

.open {
  .react-search-component {
    .search-bar {
      @include translate(-100%, 0);
      margin-left: 30px;
      @media only screen and (max-width: $menu-break) {
        margin-left: 0px;
        @include translate(-100%, 0);
      }

      > .search-button {
        border: 1px solid $link-color;
        margin-right: 15px;
        i {
          color: $link-color;
        }
      }
      input {
        opacity: 1;
      }
    }
    &.loading {
      .loader {
        opacity: 1;
        z-index: 30;
      }
    }
    &.searching {
      .search-bar {
        > .search-button {
          border: 1px solid #fff;
          background: $link-color;
          i {
            color: #fff;
          }
        }
      }
      .results {
        @include translate(0, 100%);
        @include translate(0, calc(100% - 1px));

        height: 80vh;
        .inner {
          opacity: 1;
        }
      }
    }
  }

  //}
}

@media only screen and (min-width: $menu-break) and (max-width: 1100px) {
  .header {
    .search-bar {
      .loader {
        top: 27px;
      }
    }
  }
}

@media only screen and (max-width: $menu-break) {
  .react-search-component {
    .search-bar {
      padding: 16px;
      margin-left: 0px;
      left: 100%;
      input {
        width: 70% !important;
        font-size: 18px;
        padding: 4px 10px;
        width: 204px;
      }
      > .search-button {
        width: 48px;
        height: 48px;
        padding: 11px 8px;
        i {
          top: 0px;
          font-size: 22px;
          left: 5px;
        }
      }
      .close-search i {
        position: relative;
        top: 0px;
        left: 10px;
      }
      .loader {
        top: 19px;
        opacity: 0;
        width: 48px;
        height: 48px;
      }
      .close-search {
        position: absolute;
        right: 16px;
        font-size: 20px;
        height: 30px;
        width: 30px;
        top: 50%;
        margin-top: -10px;
      }
    }

    .results {
      display: none;
      .inner {
        padding: 20px 0px;
        > a {
          padding-left: 8px;
        }
      }
      .subtitle {
        font-size: 19px / $mobile-ratio;
        margin-bottom: 5px;
        margin-top: 5px;
      }
      h5 {
        font-size: 22px / $mobile-ratio;
        margin-top: 30px;
      }
    }

    &.searching {
      .results {
        display: block;
      }
    }
  }
}
