@import '../../../animate';
@import '../../../vars';

$padder-width-calc: (100vw - #{$container-max}) / 2 + #{$margin};
$padder-helpers: calc(#{$padder-width-calc});
$padder-helpers-margin: calc(0px - (#{$padder-width-calc}));

.header-bg {
  position: fixed;
  height: $header-height;
  background: #fff;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #f4f4f4;
  z-index: 10;
  @include animate(0.3);
  @media only screen and (min-width: $menu-break) and (max-width: 1100px) {
    height: 90px;
  }
  @media only screen and (max-width: $menu-break) {
    display: none;
  }
  @media only screen and (min-width: 1100px) {
    &.header-sm {
      @include translate(0px, -40px);
    }
  }
}

.header {
  overflow: hidden;
  &.open {
    overflow: visible;
  }
}

iframe#ticketing-sync {
  display: none;
}

.header {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 998;
  height: $header-height;
  border-bottom: 1px solid #f7f7f7;
  @include animate(0.3);
  &:not(.nohover):hover {
    @media only screen and (min-width: $menu-break) {
      overflow: visible;
    }
  }
  .header-visible-mobile {
    display: none;

    &.tickets-menu {
      background: $lighter-gray;
      margin-bottom: -10px;
      border-top: 1px solid $hr-border;
      span.label {
        color: $brand-red;
        padding: 20px 16px 0;
        display: block;
        text-transform: uppercase;
      }
      ul {
        padding-bottom: 20px;
        li {
          padding: 14px 16px 0;
          &.tickets-menu {
            &__logged-in {
              display: none;
            }
            &__basket {
              a {
                span {
                  color: $brand-red;
                }
              }
            }
          }
        }
      }

    }
  }
  .home-link {
    display: none;
  }
  .left-white-pad,
  .right-white-pad {
    position: absolute;
    height: calc(100% + 1px);
    background: #fff;
    width: $padder-helpers;
    left: $padder-helpers-margin;
    z-index: 10;
    border-bottom: 1px solid #f7f7f7;
  }
  .right-white-pad {
    right: $padder-helpers-margin;
    left: auto;
  }

  @media only screen and (min-width: $screen-max-container-max) {
    width: $container-max - $margin / 2;
    left: 50%;
    margin-left: -$container-max / 2 + $margin / 2;
  }

  &:after {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 1px;
    display: none;
    background: #fafafa;
    left: 0px;
    bottom: 0;
    @include animateDelay(0.8, 0);
  }
  .mobile-toggle {
    position: absolute;
    width: 42px;
    height: 38px;
    right: 16px;
    top: 25px;
  }
  > .fixed-inner {
    background: #fff;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  a {
    font-size: 17px;
    color: $header-a-color;
    line-height: 22px;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
    &:hover {
      color: $header-a-hover-color;
    }
  }
  .logo {
    float: left;
    display: block;
    position: absolute;
    top: $margin;
    left: 30px;
    height: 80px;

    @include animate(0.3);
    img {
      height: 100%;
    }
  }
  .mobile-open {
    display: none;
  }
  .menu {
    margin-right: 120px;
    position: absolute;
    right: 0;
    top: 50px;
    height: 60%;
    @include animate(0.3);
    &__basket--spacer {
      margin-right: 155px;
    }
    > li > a {
      text-transform: uppercase;
    }
    li {
      margin-right: $header-padding * 1.5;
      height: 100%;
      position: relative;
      .down-arrow {
        width: 12px;
        position: absolute;
        display: block;
        margin-top: 5px;
        @include animate(0.3);
        opacity: 0;
        @include translate(0px, -10px);
      }
      .sub-menu {
        pointer-events: none;
        max-width: 40vw;
        @media only screen and (min-width: $menu-break) {
          @include animate(0.3);
          height: auto !important;
        }
        @include translate($header-padding, 0px);
        opacity: 0;
        position: absolute;
        background: #fff;
        top: 66px;
        padding: $header-padding * 1.5 0 20px;
        left: - ($header-padding * 1.5);
        box-shadow: 0px 4px 4px rgba(0,0,0,0.5);
        ul {
          li {
            display: block;
            margin-bottom: $margin / 1.5;
            padding: 0 $header-padding * 1.5;
            a {
              font-size: 17px;
              line-height: 22px;
              @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 20px;
              }
              @media only screen and (min-width: $menu-break) {
                white-space: nowrap;
              }
            }
            ul {
              &.links-sm {
                li {
                  padding: 0;
                }
              }
            }
            &.tickets-menu {
              margin-bottom: -24px;
              padding-bottom: 20px;
              border-top: 1px solid $hr-border;
            }
          }
        }
        > ul {
          margin-bottom: $header-padding * 1.5;
        }
        .semi-bold {
          font-size: 17px;
          line-height: 22px;
          @media only screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
        span.label {
          font-size: 16px;
          display: block;
          margin-bottom: $header-padding / 2;
          text-transform: uppercase;
        }
        .links-sm {
          &:last-child {
            margin-bottom: 5px;
          }
        }
        .tickets-menu {
          background: $lighter-gray;
          padding: 0;
          margin: 0;
          span.label {
            color: $brand-red;
            padding: 20px $header-padding * 1.5 0;
          }
          ul {
            li {
              padding:0 $header-padding * 1.5;
            }
          }
          &__basket {
            a {
              span {
                color: $brand-red;
              }
            }
          }
          &__logged-in {
            display: none;
          }
        }
      }
      &:hover,
      &.focused {
        .sub-menu {
          pointer-events: auto;
        }
        > a {
          color: $header-a-hover-color;
        }
        @media only screen and (min-width: $menu-break) {
          .down-arrow {
            opacity: 1;
            @include translate(0px, 0px);
          }
          .sub-menu {
            opacity: 1;
            @include translate(0px, 0px);
          }
        }
      }
      &:last-child {
        .sub-menu {
          margin-left: -120px;
        }
      }
    }
  }

  .basket-quantity {
    &__container {
      display: none;
      position: absolute;
      right: 80px;
      top: 30px;
      z-index: 1;
      transition: all 0.3s ease;

      @media (min-width: $screen-tablet) {
        right: 100px;
      }
      @media (min-width: $screen-desktop) {
        top: 35px;
      }
      @media only screen and (min-width: 1100px) {
        top: 50px;
      }

      a {
        display: flex;
      }
    }

    &__trolley {
      width: 24px;
      height: 24px;
    }

    &__oval {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -7px;
      margin-top: -5px;
      width: 32px;
      min-width: 32px;
      height: 32px;
      min-height: 32px;
      background: $brand-red;
      border: 2px solid $white;
      border-radius: 50%;
      color: $white;
      font-size: 16px;

      @media (min-width: $screen-tablet) {
        width: 36px;
        min-width: 36px;
        height: 36px;
        min-height: 36px;
        font-size: 17px;
      }
    }
  }

  @media only screen and (min-width: 1100px) {
    &.header-sm {
      @include translate(0px, -40px);
      .logo {
        @include transform(scale(0.8) translate(-25px, 30px));
        top: 23px;
        height: 64px;
      }
      .menu {
        @include translate(0px, 20px);
        .sub-menu {
          top: 46px;
        }
      }
      .search-bar {
        margin-top: 20px;
      }
      .react-search-component .search-bar {
        height: 99px;
        padding-bottom: 0;
        .close-search {
          margin-top: 2px;
        }
      }
      .basket-quantity {
        &__container {
          top: 70px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: $menu-break) {
  .header {
    .logo {
      left: 30px !important;
    }
    .mobile-toggle {
      right: 30px !important;
    }
  }
}

@media only screen and (min-width: $menu-break) and (max-width: 1100px) {
  .header {
    height: 90px;
    .menu {
      margin-right: 90px;
      top: 36px;
      li {
        margin-right: 22px;
        font-size: 16px;
        .sub-menu {
          top: 50px;
        }
      }
    }
    .logo {
      height: 60px;
      left: 25px;
      top: 13px;
    }
  }
}

@media only screen and (min-width: $menu-break) {
  .header {
    .menu {
      margin-right: 90px;
      &__basket--spacer {
        margin-right: 155px;
      }
    }
  }
}

@media only screen and (max-width: $menu-break) {
  .header {
    padding: 0;
    height: $header-mobile-height;
    position: absolute;
    .header-visible-mobile {
      display: block;
    }
    .header-hidden-mobile {
      display: none !important;
    }

    .home-link {
      display: block;
    }
    .mobile-open {
      display: block;
    }
    .logo {
      top: 14px;
      left: 16px;
      height: 56px;
      width: 174px !important;
    }
    a {
      color: #333 !important;
      width: 100%;
      display: block;
    }
    .menu {
      top: $header-mobile-height;
      background: #fff;
      height: auto;
      width: 100%;
      right: 0;
      left: 0;
      border: none;
      z-index: -1;
      padding: 0;
      border-top: 1px solid $lighter-gray;

      overflow: hidden;
      padding-bottom: 10px;

      transform: translateY(-100%);
      @include animateDelay(0.4, 0);

      li {
        width: 100%;
      }
      > li {
        width: 100%;
        display: block;
        border: none;
        min-height: 40px;
        > a {
          padding: 14px 16px;
          display: block;
          width: 90%;
        }
        .tickets-menu {
          a, span {
            padding: 14px 16px;
          }
        }
        a.mobile-open {
          position: absolute;
          width: 36px;
          height: 36px;
          background: $link-color;
          right: 10px;
          top: 0px;
          border-radius: 50%;
          padding: 12px 10px;
          i {
            color: #fff;
            position: relative;
            top: -10px;
            left: -5px;
            width: 12px;
            &.icon-RCT-down {
              display: block;
              top: 0px;
              left: 2px;
              font-size: 12px;
              @include animate(0.3);
              &:before {
                color: #fff !important;
              }
            }
          }
        }
        .sub-menu {
          border-top: none;
          position: relative;
          background: #fff;
          width: calc(100% + 80px);
          max-width: 100%;
          opacity: 1;
          top: auto;
          transform: translate(0px, 0px);
          margin: 0;
          display: block;
          box-shadow: none;
          max-height: 0;
          transition: max-height 0.4s ease-out;
          overflow: hidden;
          background: $lighter-gray;
          margin: 0;
          padding: 0;
          left: 0;
          > ul {
            margin: $margin !important;
          }
        }
      }
    }
  }
  &.open {
    > a {
      font-family: $paragraph-font-bold;
    }
    .menu {
      transform: translateY(0);
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
    li {
      &.open {
        .sub-menu {
          max-height: 1000px;
          height: auto;
          transition: max-height 0.4s ease-in;
        }
      }
    }
  }
  .menu > li.open a.mobile-open {
    display: block;

    i.icon-RCT-down {
      @include transform(rotate(180deg));
    }
  }
}

// Microsite header (e.g Prince albert).

.is-microsite {
  &:not(.header-sm) {
    .react-search-component .search-bar .loader {
      top: 12px;

      @media screen and (min-width: $menu-break + 1) and (max-width: 1100px) {
        top: 19px;
      }

      @media screen and (min-width: 1101px) {
        top: -2px;
      }
    }
  }

  &.header-sm {
    .react-search-component .search-bar .loader {
      top: 40px;

      @media screen and (max-width: $menu-break) {
        top: 12px;
      }
    }
  }

  .react-search-component .search-bar .loader {
    background: $white;

    .loader-white {
      display: none;
    }

    .loader-default {
      display: block;
    }

    @media screen and (min-width: $menu-break + 1) {
      background: $brand-red;

      .loader-white {
        display: block;
      }

      .loader-default {
        display: none;
      }
    }
  }

  &.header,
  &.header-bg {
    border-bottom: none;

    &.splash-image-visible {
      opacity: 0;
    }
  }

  &.header-bg {
    background-color: $brand-red;

    @media screen and (min-width: 1100px) {
      height: 130px;
    }
  }

  &.header {
    @media screen and (max-width: $screen-md) {
      height: 71px;
    }

    .fixed-inner {
      background: $brand-red;
    }

    .logo {
      height: auto;
      top: 15px;

      @media screen and (max-width: 1099px) {
        display: none;
      }

      @media only screen and (min-width: $screen-max-container-max) {
        top: 15px;
      }

      img {
        height: 24px;

        @media screen and (min-width: $menu-break) {
          height: 46px;
          width: 143px;
        }
      }
    }

    .mobile-toggle {
      top: 16px;
      bottom: 0;

      @media screen and (min-width: $menu-break + 1) {
        display: none;
      }
    }

    .microsite-logo {
      position: absolute;
      bottom: 0;
      margin-bottom: 10px;
      left: 20px;
      top: 16px;
      width: 60%;

      img {
        width: auto;
        max-width: 240px;
        height: 44px;
      }

      @media screen and (min-device-width: $screen-sm) {
        top: 20px;
        left: 30px;
      }

      @media screen and (min-width: $menu-break) {
        top: 20px;
        margin-bottom: 0;

        img {
          max-width: 300px;
        }
      }

      @media screen and (min-width: 1100px) {
        top: auto;
        bottom: 5px;
      }
    }

    .search-bar {
      border-radius: 0;

      @media screen and (min-width: $menu-break) {
        background-color: $brand-red;
      }
    }

    .menu {
      top: 71px;

      @media screen and (min-width: $menu-break) {
        top: 35%;
        height: 40%;

        @media screen and (min-width: 1100px) {
          top: 65%;
        }

        li .sub-menu {
          top: 38px;
          background-color: $brand-red;

          .links-sm {
            li {
              a {
                color: $white;

                &:hover {
                  border-bottom: 1px dotted $white;
                }
              }
            }
          }
        }

        > li > a {
          color: $white;

          &:hover {
            color: rgba(255, 255, 255, 0.9);
          }
        }
      }

      li:last-child {
        .sub-menu {
          margin-left: 0;
        }
      }
    }

    @media screen and (min-width: $menu-break) {
      .left-white-pad,
      .right-white-pad {
        background-color: $brand-red;
        border-bottom: none;
        height: 100%;
      }
    }

    @media screen and (min-width: 1100px) {
      height: 130px;
    }

    .react-search-component .search-bar {
      @media screen and (min-width: 1100px) {
        height: 45px;
        top: 58%;
      }

      @media screen and (min-width: $menu-break) {
        & > .search-button {
          &:hover {
            border: 1px solid $white;
          }
        }

        .close-search .icon-RCT-close:before,
        .icon-RCT-search {
          color: $white;
        }
      }
    }

    &.open {
      .microsite-logo {
        display: none;
      }
      .react-search-component {
        .search-bar {
          @media screen and (min-width: $menu-break) {
            .search-button {
              border: 1px solid $white;
            }
          }
        }

        .results.is-autocomplete,
        .search-auto-complete__autocomplete__title {
          * {
            @media screen and (min-width: $menu-break) {
              font-size: 19px;
            }
          }
        }

        .results.is-autocomplete {
          .search-auto-complete__autocomplete {
            background: transparent;
            border: 0;

            li {
              margin-bottom: 0;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  &.header-sm {
    .fixed-inner {
      .logo {
        opacity: 0;
      }
    }

    @media screen and (min-width: 1100px) {
      .microsite-logo {
        bottom: 18px;
      }

      .menu {
        top: 40%;
      }
    }

    @media screen and (min-width: 1100px) {
      .react-search-component .search-bar {
        top: 20px;
        height: 110px;
        margin-top: auto;
      }
    }
  }
}
