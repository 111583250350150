.page-resources,
.page-schools-resources,
.page-collection-conservation,
.page-collection-themes-search #search-themes,
.page-whatson .region-content,
.page-schools .region-content,
.page-collection-search .region-content,
.page-collection-near-you .region-content,
.node-type-collection-landing-page .region-content,
.node-type-location .region-content,
.node-type-people .region-content {
  /* What's on controls */
  /* @todo move to whastsOn component. */

  .wo-controls {
    h3 {
      font-family: $paragraph-font-bold;
      .small {
        font-family: $paragraph-font;
      }
    }
    h3,
    .small {
      font-size: 19px;
      line-height: 24px;
    }
    @media only screen and (min-width: 992px) {
      .select-dropdown {
        height: 52px;
      }
    }
    @media only screen and (max-width: 767px) {
      h3,
      .small {
        font-size: 16px;
        line-height: 21px;
      }
    }

    .btn {
      position: relative;
      height: 52px;
      &:focus {
        outline: 0;
      }
      .caret {
        @include icomoon;
        font-size: 12px;
        border: none;
        top: 21px;
        right: 27px;
        &:before {
          content: '\e913';
        }
      }
    }
    .bootstrap-select.btn-group .dropdown-toggle .caret {
      top: 26px;
      right: 15px;
    }
    @media only screen and (max-width: 991px) {
      .btn {
        height: 50px;
      }
    }

    .bootstrap-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
      width: 100%;
    }

    .dropdown-menu {
      z-index: 1000;
      min-width: 160px;
      padding: 0;
      margin: 0;
      font-size: 14px;
      text-align: left;
      background-color: #fff;
      border: none;
      border-radius: 3px;
      box-shadow: none;
      background-clip: padding-box;
      right: 0px;
      z-index: 9999;
      .inner {
        margin: 0;
      }
      li {
        &.selected a {
          background: #f8f8f8;
        }
        a {
          display: block;
          padding: 15px;
          background: #f4f4f4;
          border-bottom: 1px solid #fff;
          font-size: 19px;
          line-height: 24px;
          @media only screen and (max-width: 991px) {
            font-size: 16px;
            line-height: 21px;
          }
          &:hover,
          &:focus {
            background: #f8f8f8;
            outline: 0;
          }
        }
      }
    }
  }
}
